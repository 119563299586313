import React, {useContext, useEffect, useRef, useState} from "react";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import {withRouter} from "react-router";
import {toast} from "react-toastify";
import {
  sd_india_flag,
  sd_script,
  sd_side_arrow,
} from "../../../../Assets/imageList";
import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
import {SD_Context} from "../../../../context/sd-context";
import ParokshaSevaService from "../../../../services/sd-parokshaseva.service";
import {BASE_BANK} from "../../../../Utils/sd-axios/sd-api-url";
const CommonRegex = /^[#.0-9a-zA-Z\s,-/]+$/;

const Sd_dashboard_srisaila_prabha_details = ({history}) => {
  //-------------------------------------------------------------useeffects-----------------------------------------------------------//
  const {
    setActive,
    setActivePath,
    cbSrisailaPrabha,
    setCbSrisailaPrabha,
    logout,
    userData,
  } = useContext(SD_Context);
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [loading, setloading] = useState(true);
  const [subType, setSubType] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("India");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [lang, setLang] = useState("");
  const [selectedLanguage, setSelectedlanguage] = React.useState("en-in");
  const [err, SetErr] = useState([]);
  const ref = useRef(null);
  const [autoFill, setAutoFill] = useState(false);
  //-------------------------------------------------------------useeffects-----------------------------------------------------------//

  //-------------------------------------------------------------consts-----------------------------------------------------------//
  const termsList = [
    `The subscription period for Saswatha Srisaila Prabha is 15 years only.`,
    `Magazine will be sent after one month from the date of remittance.`,
    `If there is any change in address, it should be intimated to the Executive Officer immediately. Please go through the Disclaimer, Privacy Policy and Copyright information in Terms & Conditions provided in website.
 `,
  ];
  const styles = {
    color: "#181818",
    fontSize: "15px",
    fontWeight: "500",
    marginBottom: "8px",
  };
  //-------------------------------------------------------------consts-----------------------------------------------------------//

  //-------------------------------------------------------------useeffects-----------------------------------------------------------//
  useEffect(() => {
    setActive("srisaila-prabha");
    setActivePath("Onlinebook-Publications");
    let path = new URLSearchParams(history?.location?.search).get("state");
    if (path === "clear") {
      setCbSrisailaPrabha(null);
    }

    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    if (cbSrisailaPrabha !== null) {
      cbSrisailaPrabha?.name && setName(cbSrisailaPrabha?.name);
      cbSrisailaPrabha?.country && setCountry(cbSrisailaPrabha?.country);
      cbSrisailaPrabha?.state && setState(cbSrisailaPrabha?.state);
      cbSrisailaPrabha?.city && setCity(cbSrisailaPrabha?.city);
      cbSrisailaPrabha?.mobile && setMobile(cbSrisailaPrabha?.mobile);
      cbSrisailaPrabha?.zip && setZip(cbSrisailaPrabha?.zip);
      cbSrisailaPrabha?.email && setEmail(cbSrisailaPrabha?.email);
      cbSrisailaPrabha?.address1 && setAddress1(cbSrisailaPrabha?.address1);
      cbSrisailaPrabha?.address2 && setAddress2(cbSrisailaPrabha?.address2);
      cbSrisailaPrabha?.subType && setSubType(cbSrisailaPrabha?.subType);
      cbSrisailaPrabha?.lang && setLang(cbSrisailaPrabha?.lang);

      setloading(false);
    } else {
      setloading(false);
    }
  }, []);
  //-------------------------------------------------------------useeffects-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//
  const handleSubmit = async () => {
    let a = true;
    setloading(true);
    if (a) {
      let data = [...err];
      if (subType === "") {
        data.push({type: "subType", msg: "Please select a plan"});
      }
      if (name === "") {
        data.push({type: "name", msg: "Please enter your full name"});
      } else {
        if (name.length < 4) {
          data.push({type: "name", msg: "Name must be minimum 3 characters"});
        }
      }
      if (mobile.length !== 10) {
        data.push({type: "phone", msg: "Invalid mobile number"});
      }
      if (address1 === "") {
        data.push({type: "address1", msg: "Please enter a valid address"});
      }
      // if (address2 === "") {
      //   data.push({type: "address2", msg: "Please enter a Address"});
      // }
      if (lang === "") {
        data.push({type: "language", msg: "Please select a language"});
      }
      if (city === "") {
        data.push({type: "city", msg: "Please enter a city"});
      }
      if (state === "") {
        data.push({type: "state", msg: "Please select your state"});
      }
      if (zip.length !== 6) {
        data.push({type: "zip", msg: "Invalid zipcode! Try again"});
      }
      if (country === "") {
        data.push({type: "country", msg: "Please select a country"});
      }
      if (email === "") {
        data.push({type: "email", msg: "Please enter a email address"});
      } else {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
          data.push({type: "email", msg: "Please enter a valid email id"});
        }
      }
      SetErr(data);
      // console.log(data);
      if (data.length !== 0) {
        setloading(false);
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });

        return;
      }
    }

    try {
      let obj = {
        full_name: name,
        address: address1,
        type: subType,
        contact: `+91${mobile}`,
        pin_code: zip,
        state: state,
        email: email,
        city: city,
        language: lang,
        country: country,
      };
      if (address2 !== "") {
        obj.address2 = address2;
      }
      let schema = [
        {
          srisailaPrabhaBooking: obj,
          itemType: "srisailaPrabha",
          itemId: 1, // any positive greater than 0
        },
      ];
      let res = await ParokshaSevaService.bookings(schema, BASE_BANK, true);
      if (res) {
        let data = {
          name,
          mobile,
          address1,
          address2,
          city,
          state,
          country,
          zip,
          email,
          subType,
          lang,
        };
        setCbSrisailaPrabha(data);
        setloading(true);

        history?.push(
          `/${selectedLanguage}/devotee-app/online-booking/srisaila-prabha/confirm`
        );
      }
    } catch (e) {
      toast.error(e.message);
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/srisaila-prabha`
        );
      }
      setloading(false);
      return;
    }
  };
  //-------------------------------------------------------------functions-----------------------------------------------------------//

  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {!loading ? (
        <div className="">
          <h2 className="sd-side-heading fw400">
            <span className="fw700" ref={ref}>
              Srisaila{" "}
            </span>
            Prabha
          </h2>
          <div className="clearfix">
            <p className="flex-c w-100 border-c pb-30  pt-20 br-4">
              <img
                src={sd_script}
                alt="#"
                height="80px"
                width="80px"
                className="mr-5 mb-5 opace"
              />
              We are sorry to say that Srisaila prabha service is temporarily
              unavailable due to Covid-19. We regret the inconvenience.
            </p>
          </div>

          <div className="clearfix">
            <form className="sd-form">
              <div className="clearfix">
                <label style={styles}>
                  Subscription plan<span className="sd-imp">*</span>{" "}
                </label>
                <div className="clearfix pr-30">
                  <div
                    className="col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0"
                    key={"annual-pub"}
                  >
                    <div
                      className={
                        err?.find((e) => e.type === "subType")
                          ? "sd-radio-select sd-red"
                          : subType === "1 year"
                          ? "sd-radio-select sd-voilet"
                          : "sd-radio-select"
                      }
                      onClick={() => {
                        if (
                          err.find(
                            (e) => e.type === "subType" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "subType" && e.type !== "common") {
                              return e;
                            }
                          });
                          SetErr(data);
                        }
                        setSubType("1 year");
                      }}
                    >
                      <input
                        type="radio"
                        name="optradio1"
                        id="Annadanam1"
                        checked={subType === "1 year" ? true : false}
                      />
                      <label
                        htmlFor="Annadanam1"
                        className="radio-inline"
                        onClick={() => {
                          if (
                            err.find(
                              (e) => e.type === "subType" || e.type === "common"
                            )
                          ) {
                            let data = err.filter((e) => {
                              if (e.type !== "subType" && e.type !== "common") {
                                return e;
                              }
                            });
                            SetErr(data);
                          }
                          setSubType("1 year");
                        }}
                      >
                        <p>
                          Annual Plan
                          <br></br>
                          <span>Rs. 116.00</span>
                        </p>
                      </label>
                    </div>
                  </div>

                  <div
                    className="col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0"
                    key={"saswatha-pub"}
                  >
                    <div
                      className={
                        err?.find((e) => e.type === "subType")
                          ? "sd-radio-select sd-red"
                          : subType === "15 years"
                          ? "sd-radio-select sd-voilet"
                          : "sd-radio-select"
                      }
                      onClick={() => {
                        if (
                          err.find(
                            (e) => e.type === "subType" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "subType" && e.type !== "common") {
                              return e;
                            }
                          });
                          SetErr(data);
                        }
                        setSubType("15 years");
                      }}
                    >
                      <input
                        type="radio"
                        name="optradio1"
                        id="Annadanam2"
                        checked={subType === "15 years" ? true : false}
                      />
                      <label
                        htmlFor="Annadanam2"
                        className="radio-inline"
                        onClick={() => {
                          if (
                            err.find(
                              (e) => e.type === "subType" || e.type === "common"
                            )
                          ) {
                            let data = err.filter((e) => {
                              if (e.type !== "subType" && e.type !== "common") {
                                return e;
                              }
                            });
                            SetErr(data);
                          }
                          setSubType("15 years");
                        }}
                      >
                        <p>
                          Saswata (15 Years)
                          <br></br>
                          <span>Rs. 1500.00</span>
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
                <p
                  style={{
                    position: "absolute",
                    marginTop: "10px",
                    color: "red",
                  }}
                >
                  {err.find((e) => e.type === "subType")
                    ? err.find((e) => e.type === "subType")?.msg
                    : ""}
                </p>

                <br />
                <h3
                  className="sd-side-heading fw400 mt-30 mr-30"
                  style={{paddingBottom: "10px"}}
                >
                  <span className="fw400">User Details</span>
                  <div
                    className="sd-confirm-edit-autofill "
                    onClick={() => {
                      if (!autoFill) {
                        setName(
                          `${
                            userData?.first_name ? userData?.first_name : ""
                          } ${userData?.last_name ? userData?.last_name : ""}`
                        );

                        setMobile(
                          userData?.phone
                            ? userData?.phone?.replace?.("+91", "")
                            : ""
                        );

                        setAddress1(
                          userData?.address1 ? userData?.address1 : ""
                        );
                        setAddress2(
                          userData?.address2 ? userData?.address2 : ""
                        );
                        setCountry(userData?.country ? userData?.country : "");
                        setState(userData?.state ? userData?.state : "");
                        setCity(userData?.city ? userData?.city : "");
                        setZip(userData?.pincode ? userData?.pincode : "");
                        setEmail(userData?.email ? userData?.email : "");

                        setAutoFill(true);
                      } else {
                        setName(``);
                        setMobile("");
                        setAddress1("");
                        setAddress2("");
                        setCountry("");
                        setState("");
                        setCity("");
                        setZip("");
                        setEmail("");

                        setAutoFill(false);
                      }
                      let data = err.filter((e) => {
                        if (
                          e.type !== "name" &&
                          e.type !== "city" &&
                          e.type !== "address1" &&
                          e.type !== "state" &&
                          e.type !== "country" &&
                          e.type !== "phone" &&
                          e.type !== "zip" &&
                          e.type !== "address2" &&
                          e.type !== "email"
                        ) {
                          return e;
                        }
                      });
                      SetErr(data);
                    }}
                  >
                    {!autoFill ? "Autofill" : "Clear"}
                  </div>
                </h3>
                <hr className="clearfix"></hr>
                <div className="clearfix">
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                    <label>
                      Full Name<span className="sd-imp">*</span>
                    </label>
                    <input
                      type="text"
                      maxLength="30"
                      className={
                        err.find((e) => e.type === "name")
                          ? "form-control sd-red"
                          : name !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      placeholder="Please enter your full name"
                      value={name}
                      onChange={(e) => {
                        if (
                          /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                          e.target.value === ""
                        ) {
                          if (
                            err.find(
                              (e) => e.type === "name" || e.type === "common"
                            )
                          ) {
                            let data = err.filter((e) => {
                              if (e.type !== "name" && e.type !== "common") {
                                return e;
                              }
                            });
                            SetErr(data);
                          }
                          setName(e.target.value);
                        }
                      }}
                    />

                    <p className="sd-EM mr-30">
                      {err.find((e) => e.type === "name")?.msg}
                    </p>
                  </div>
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                    <label>
                      Language<span className="sd-imp">*</span>
                    </label>
                    <select
                      className={
                        err.find((e) => e.type === "language")
                          ? "form-control sd-red"
                          : lang !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      value={lang}
                      onChange={(e) => {
                        if (
                          err.find(
                            (e) => e.type === "language" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "language" && e.type !== "common") {
                              return e;
                            }
                          });
                          SetErr(data);
                        }
                        setLang(e.target.value);
                      }}
                    >
                      <option style={{display: "none"}} value="" key="">
                        Select a language
                      </option>
                      <option key={"telugu"} value={"telugu"}>
                        Telugu
                      </option>
                      <option key={"kannada"} value={"kannada"}>
                        Kannada
                      </option>
                    </select>

                    <p className="sd-EM mr-30">
                      {err.find((e) => e.type === "language")?.msg}
                    </p>
                  </div>

                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                    <label>
                      Mobile Number<span className="sd-imp">*</span>
                    </label>

                    <div className={"sd-profile-mobile"}>
                      <span className="prefix">
                        <img src={sd_india_flag} alt="india" height="20px" />{" "}
                        +91
                      </span>
                      <input
                        className={
                          err?.find((e) => e.type === "phone")
                            ? "input-box sd-red"
                            : mobile !== ""
                            ? "sd-voilet input-box"
                            : "input-box"
                        }
                        style={{width: "100%"}}
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        value={mobile}
                        placeholder="Enter your 10 digit mobile number"
                        onChange={(e) => {
                          if (
                            err?.find(
                              (e) => e.type === "phone" || e.type === "common"
                            )
                          ) {
                            let array = err.filter((e) => {
                              if (e.type !== "phone" && e.type !== "common") {
                                return e;
                              }
                            });
                            SetErr(array);
                          }
                          if (e.target.value.length <= 10) {
                            setMobile(e.target.value);
                          }
                        }}
                      />
                    </div>

                    <p className="sd-EM mr-30">
                      {err.find((e) => e.type === "phone")
                        ? err.find((e) => e.type === "phone")?.msg
                        : ""}
                    </p>
                  </div>
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                    <label>
                      Email address<span className="sd-imp">*</span>
                    </label>
                    <input
                      maxLength="30"
                      type="text"
                      className={
                        err.find((e) => e.type === "email")
                          ? "form-control sd-red"
                          : email !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      style={{textTransform: "none"}}
                      placeholder="Please enter a valid email id"
                      value={email}
                      onChange={(e) => {
                        if (
                          err.find(
                            (e) => e.type === "email" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "email" && e.type !== "common") {
                              return e;
                            }
                          });
                          SetErr(data);
                        }
                        setEmail(e.target.value);
                      }}
                    />
                    <p className="sd-EM mr-30">
                      {err.find((e) => e.type === "email")
                        ? err.find((e) => e.type === "email")?.msg
                        : ""}
                    </p>
                  </div>
                </div>
                <hr className="clearfix"></hr>

                <div className="clearfix">
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                    <label>
                      Address Lane 1<span className="sd-imp">*</span>
                    </label>
                    <input
                      maxLength="50"
                      type="text"
                      placeholder="House/building number, street number"
                      className={
                        err.find((e) => e.type === "address1")
                          ? "form-control sd-red"
                          : address1 !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      value={address1}
                      onChange={(e) => {
                        if (
                          CommonRegex.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          if (
                            err.find(
                              (e) =>
                                e.type === "address1" || e.type === "common"
                            )
                          ) {
                            let data = err.filter((e) => {
                              if (
                                e.type !== "address1" &&
                                e.type !== "common"
                              ) {
                                return e;
                              }
                            });
                            SetErr(data);
                          }
                          setAddress1(e.target.value);
                        }
                      }}
                    />
                    <p className="sd-EM mr-30">
                      {err.find((e) => e.type === "address1")
                        ? err.find((e) => e.type === "address1")?.msg
                        : ""}
                    </p>
                  </div>

                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 pl-0 xssm-pr-0 ">
                    <label>Address Lane 2</label>
                    <input
                      type="text"
                      maxLength="50"
                      className={
                        err.find((e) => e.type === "address2")
                          ? "form-control sd-red"
                          : address2 !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      placeholder="building name, street name, etc"
                      value={address2}
                      onChange={(e) => {
                        if (
                          CommonRegex.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          if (
                            err.find(
                              (e) =>
                                e.type === "address2" || e.type === "common"
                            )
                          ) {
                            let data = err.filter((e) => {
                              if (
                                e.type !== "address2" &&
                                e.type !== "common"
                              ) {
                                return e;
                              }
                            });
                            SetErr(data);
                          }
                          setAddress2(e.target.value);
                        }
                      }}
                    />
                    <p className="sd-EM mr-30">
                      {err.find((e) => e.type === "address2")
                        ? err.find((e) => e.type === "address2")?.msg
                        : ""}
                    </p>
                  </div>
                </div>

                <div className="clearfix">
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                    <label>
                      City<span className="sd-imp">*</span>
                    </label>
                    <input
                      maxLength="30"
                      type="text"
                      className={
                        err.find((e) => e.type === "city")
                          ? "form-control sd-red"
                          : city !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      value={city}
                      placeHolder="Please enter your city"
                      onChange={(e) => {
                        if (
                          CommonRegex.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          if (
                            err.find(
                              (e) => e.type === "city" || e.type === "common"
                            )
                          ) {
                            let data = err.filter((e) => {
                              if (e.type !== "city" && e.type !== "common") {
                                return e;
                              }
                            });
                            SetErr(data);
                          }
                          setCity(e.target.value);
                        }
                      }}
                    />
                    <p className="sd-EM mr-30">
                      {err.find((e) => e.type === "city")
                        ? err.find((e) => e.type === "city")?.msg
                        : ""}
                    </p>
                  </div>

                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                    <label>
                      Zipcode<span className="sd-imp">*</span>
                    </label>
                    <input
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      className={
                        err.find((e) => e.type === "zip")
                          ? "form-control sd-red"
                          : zip !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      value={zip}
                      placeholder="Enter your zipcode"
                      onChange={(e) => {
                        if (
                          err.find(
                            (e) => e.type === "zip" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "zip" && e.type !== "common") {
                              return e;
                            }
                          });
                          SetErr(data);
                        }
                        if (e.target.value.length <= 6) {
                          setZip(e.target.value);
                        }
                      }}
                    />
                    <p className="sd-EM mr-30">
                      {err.find((e) => e.type === "zip")
                        ? err.find((e) => e.type === "zip")?.msg
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="clearfix">
                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                    <label>
                      State<span className="sd-imp">*</span>
                    </label>
                    <RegionDropdown
                      country={country}
                      classes={
                        err.find((e) => e.type === "state")
                          ? "sd-red w-100 sd-border-none"
                          : state !== ""
                          ? " sd-voilet w-100 sd-border-none"
                          : "w-100 sd-border-none"
                      }
                      value={state}
                      showDefaultOption={true}
                      blankOptionLabel="Select a State"
                      onChange={(val) => {
                        if (
                          err.find(
                            (e) => e.type === "state" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "state" && e.type !== "common") {
                              return e;
                            }
                          });
                          SetErr(data);
                        }
                        setState(val);
                      }}
                    />
                    <p className="sd-EM mr-30">
                      {err.find((e) => e.type === "state")
                        ? err.find((e) => e.type === "state")?.msg
                        : ""}
                    </p>
                  </div>

                  <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 pl-0 xssm-pr-0 ">
                    <label>
                      Country<span className="sd-imp">*</span>
                    </label>
                    <CountryDropdown
                      value={country}
                      classes={
                        err.find((e) => e.type === "country")
                          ? "sd-red w-100 "
                          : country !== ""
                          ? " sd-voilet w-100 sd-border-none"
                          : "w-100 sd-border-none"
                      }
                      showDefaultOption={true}
                      defaultOptionLabel="Select a Country"
                      onChange={(val) => {
                        if (
                          err.find(
                            (e) => e.type === "country" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "country" && e.type !== "common") {
                              return e;
                            }
                          });
                          SetErr(data);
                        }
                        setCountry(val);
                      }}
                    />
                    <p className="sd-EM mr-30">
                      {err.find((e) => e.type === "country")
                        ? err.find((e) => e.type === "country")?.msg
                        : ""}
                    </p>
                  </div>
                </div>

                <hr className="clearfix"></hr>

                <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pl-0 pr-0 pb-0">
                  <ol className="sd-terms-list">
                    {termsList.map((item, i) => {
                      return (
                        <li className="active" key={i + "terms-pub"}>
                          <a style={{paddingRight: "3px"}}>{item}</a>
                        </li>
                      );
                    })}
                  </ol>
                </div>

                <hr className="clearfix"></hr>
                <div className="clearfix mt-30 sd-form">
                  <a
                    className="sd-btn-orange"
                    onClick={() => {
                      if (!loading) {
                        handleSubmit();
                      }
                    }}
                  >
                    Subscribe{" "}
                    <img className="sd-arrow-btn ml-5" src={sd_side_arrow} />
                  </a>
                </div>
              </div>
            </form>{" "}
          </div>
        </div>
      ) : (
        <Sd_loading />
      )}
    </div>
  );
};
export default withRouter(Sd_dashboard_srisaila_prabha_details);
