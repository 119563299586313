import React, {useState, useEffect} from "react";
import {
  SD_Right_Arrow_white,
  SD_title_divider,
  sd_faq_plus,
  sd_faq_minus,
} from "../../Assets/imageList";
import "../../Utils/sd-styles/sd-faqs.scss";
import {data} from "../../Utils/sd-faq-data/sd-paroksha-seva-faq";
import {withRouter} from "react-router";
import Sd_loading from "./sd-loading";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const Sd_faqs_semi_view = ({from, history}) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const [selectedLanguage, setSelectedlanguage] = useState("en-in");
  const [active, setActive] = useState("none");
  const [subData, setSubData] = useState([]);
  const [loading, setloading] = useState(true);
  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useefects-----------------------------------------------------------//
  useEffect(() => {
    setloading(true);
    setSubData(data[from]);
    setloading(false);
  }, []);
  useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);
  //-------------------------------------------------------------useeffects-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//
  const displayFunction = () => {
    let array = [];
    if (!subData) {
      return;
    }
    for (let i = 0; i < 3; i++) {
      array.push(
        <div
          className={`card selective-card ${i === 0 && "sd-bt"}`}
          key={i}
          onClick={() => {
            if (active === `none`) {
              setActive(`heading${i}`);
            } else if (active === `heading${i}`) {
              setActive("none");
            } else {
              setActive(`heading${i}`);
            }
          }}
        >
          <div className="card-header heading" id={`heading${i}`}>
            <div className="top-head">
              <div className="top-head-top">
                <h3>{i + 1}</h3>
                <h2>{subData[i].question}</h2>
              </div>
              <h2 className="mb-0 custom-h2">
                <button className="btn btn-link cutombuttom" type="button">
                  {active === `heading${i}` ? (
                    <img
                      src={sd_faq_minus}
                      alt=""
                      onClick={() => {
                        setActive("none");
                      }}
                    />
                  ) : (
                    <img
                      src={sd_faq_plus}
                      onClick={() => {
                        setActive(`heading${i}`);
                      }}
                    />
                  )}
                </button>
              </h2>
            </div>
          </div>
          <div id={`collapse${i}`}>
            <div
              className={
                active === `heading${i}`
                  ? "sd-display card-body"
                  : "sd-none card-body"
              }
            >
              <span style={{fontWeight: "600"}}>Ans. </span>
              {subData[i].answer}
            </div>
          </div>
        </div>
      );
    }
    return array;
  };
  //-------------------------------------------------------------functions-----------------------------------------------------------//
  return (
    <>
      {loading ? (
        <Sd_loading />
      ) : (
        <>
          <div className="sd-faqs-conatainer">
            <div className="sd-border">
              {" "}
              <hr />
            </div>
            <div className="sd-faqs-top">
              <h1>
                <span>FAQ</span>s
              </h1>
              <img src={SD_title_divider} alt="divider"></img>
            </div>
            <div className="sd-faqs-bottom">
              <div className="accordion" id="accordionExample">
                {displayFunction()}

                <div className="toggle"></div>
              </div>
            </div>

            {!subData ? (
              ""
            ) : (
              <div className="sd-faqs-button">
                <button
                  className="sd-view-button"
                  onClick={() => {
                    history.push(`/${selectedLanguage}/faqs/${from}`);
                  }}
                >
                  View All <img src={SD_Right_Arrow_white} alt=">" />
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default withRouter(Sd_faqs_semi_view);
