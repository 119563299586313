import Axios from "../Utils/sd-axios/Axios";

const Sd_Darshanam_service = {
  getAll: async () => {
    try {
      const resp = await Axios.get("/darshanam/all");
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        // //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  getSlotsAvailability: async (id, date) => {
    try {
      const resp = await Axios.get(
        `/darshanam/slots/available/${id}?bookingDate=${date}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        // //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
};

export default Sd_Darshanam_service;
