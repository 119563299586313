import React, {useRef} from "react";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {withRouter} from "react-router";
import {
  SD_title_divider,
  sd_minus_icon,
  sd_plus_icon,
  SD_gallery_01,
  SD_gallery_02,
  SD_gallery_03,
  SD_gallery_04,
  SD_gallery_05,
  SD_gallery_06,
  SD_gallery_07,
  SD_gallery_08,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-about.scss";
import Sd_loading from "../../sd-common-components/sd-loading";

const Sd_about_general_information_component = ({match, visible, history}) => {
  //-------------------------------------------------------------states--------------------------------------------------------//

  const [selectedLanguage, setSelectedlanguage] = useState("en-in");
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [type, setType] = useState(match.params.type);
  const [drop1, setDrop1] = useState(false);
  const [drop2, setDrop2] = useState(false);
  const [drop3, setDrop3] = useState(false);
  const [drop4, setDrop4] = useState(false);
  const [drop5, setDrop5] = useState(false);
  const [drop6, setDrop6] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const titleRef = useRef();
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    if (match?.params?.type) {
      setType(match?.params?.type);
    } else {
      setType("sakshi-ganapathi");
    }
    titleRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [match?.params?.type]);

  useEffect(() => {
    setImageLoading(true);
    if (type === "sakshi-ganapathi") {
      setImageLoading(false);
    }
    titleRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [type]);

  useEffect(() => {
    history?.push(`/${selectedLanguage}/about/general-information/${type}`);
  }, [type]);

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  const data = {
    "visiting-places": {
      heading: `Visiting Places`,
      description1: <></>,
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-TheTemple/sd-annapurna-devi.png",
      seo_tag: {
        title:
          "Places To Visit In Srisailam | Srisailam Visiting Places | Places Near Srisailam",
        desc: "Places to visit in Srisailam are innumerable as Srisailam itself is a unique world of wonders. Places near Srisailam are Akkamahadevi  caves, Mallela Theertham.",
        keywords:
          "Places To Visit In Srisailam, Srisailam Visiting Places, Places Near Srisailam",
        h1_tag: "Places To Visit In Srisailam",
      },
    },
    dieties: {
      heading: `Dieties`,
      description1: <></>,
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-TheTemple/sd-annapurna-devi.png",

      seo_tag: {
        title: "",
        desc: "",
        keywords: "",
        h1_tag: "",
      },
    },

    "sakshi-ganapathi": {
      heading: `Sakshi Ganapathi`,
      description1: (
        <>
          Sakshi Ganapathi The sacred Srisailam is ancient and elaborate in its
          historic significance. This holy precinct is home to many sacred
          temples, Ashrams, Mutts, water bodies and picturesque beauty. This is
          why it is believed that Siddhis and the greatest devotees of Lord
          Shiva travel from the Himalayas down to Srisailam to offer their
          prayers to Mallikarjuna Swamy. The compassion of God is in every bit
          of Srisailam in the form of holy water bodies, holy precincts and
          sacred trees. This is because the Lord has come down to reside in
          Srisailam along with all his beloved water bodies, trees and other
          gods to bless his devotees.
          <br />
          <br /> Sakshi Ganapathi temple is located 2 km east of the main
          temple. Since Ganapathi presents proof that each devotee has visited
          Srisailam to Lord Shiva in Kailasa, he is called Sakshi Ganapathi.
          <br />
          <br /> He is sculpted beautifully out of a black stone. His trunk is
          folded to his left and seems to write down the names of the devotees
          who visit Srisailam. The devotees who worship Srisailam visit Sakshi
          Ganapathi and tell him their Gotra names.
          <br />
          <br /> The Aaagamas detail the significance of Sakshi Ganapathi. He
          represents time. Time is the observer of all and holds proof. Sakshi
          means that which holds proof of each second. Sakshi Ganapathi observes
          all, holds proof of their acts and delivers good and bad that they
          deserve. We can live in peace if we know someone holds proof of our
          good acts. Even if we forget, he never does. Such god who holds the
          power of many destinies is Sakshi Ganapathi. While Ganesh considered
          his parents his whole world, Kumaraswamy established that the whole
          world is filled with Shiva-Shakti. World's Mother and Father, Lord
          Shiva and Goddess Parvathi with all the powers vested in them start
          their journey towards, Earth's Kailasa, the world's centre, home to
          Vedas, Upasanakendra, their new called home, Srisaila Kshetram.
          Ganapathi also joins them to reside in Srisailam. <br />
          <br />
          The idol that can be seen now is placed here 3 and a half decades ago.
          There used to be an ancient idol here which has been replaced by a new
          one. The 16 columns of the front facing hall along with four columns
          of the inner hall have been brought together by walls to form a
          sanctum in which the idol has been placed. The cemented walls of the
          front facing wall have been inscribed with Naradatumbarulu.
          <br />
          <br /> On the three sides on the outside of the sanctum are depictions
          of NatyaGanapathi and Shivarchana made using cement. Skanda Puranas
          and other Puranas have not mentioned this Ganapathi. <br />
          Devasthanam has organised Paroksha seva facility for devotees to take
          part in Ganapathi homam that takes place here. Also, daily prayers and
          rituals are offered here. So all devotees are requested to abide by
          the devasthanam regulations and worship the ancient and significant
          Sakshi Ganapathi at Srisailam Sarvam
          <br />
          <br />
          Bhramaramba Sameta Mallikarjuna Charanaravindarpanamastu.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/sakshi-ganapathi.jpeg",
      seo_tag: {
        title:
          "Sakshi Ganapathi | Sakshi Ganapati Temple | Srisailam Sakshi Ganapathi",
        desc: "Sakshi Ganapathi temple in Srisailam has a glaring idol of Lord Ganapathi holding a pen in one hand, writing Panchakshari Mantra-Om Namah Sivaya on a palm leaf.",
        keywords:
          "Sakshi Ganapathi, Sakshi Ganapati Temple, Srisailam Sakshi Ganapathi",
        h1_tag: "Sakshi Ganapathi",
      },
    },
    "bailu-veerabhadra-swamy": {
      heading: `Bailu Veerabhadra Swamy`,
      description1: (
        <>
          Lord Shiva and Goddess Parvathi with all the powers vested in them
          decode to reside in, Earth's Kailasa, the world's centre, home to
          Vedas, home to Bindu-Natha form, Upasanakendra, Sri Chakram, their new
          called home, Srisaila Kshetram. The only Lord Shiva's Dhaamam that's
          going to remain before and after the Universe is Srisaila Kshetram.
          Srisailam is that can't be sized by history but is for the devotees
          alone. The god shows his compassion in Srisailam in the form of holy
          places and holy precincts. Bayalu Veerabhadra Swamy temple belongs to
          this list of holy places.
          <br />
          <br />
          Rudram Rudraavataram Huutavahanayanam Oordvaratham Sudamshtram
          Vyomangam Bheemaroopam Kinikinirabhasajwaalamoolavrutaangam
          Bhadrakaalipriyaaya Shriveerabhadhram Namami Asyadha Sharanam Naasthi
          Tvameva Sharanam Mama Paahimaam Veerabhadhra Rakshamaam Veerabhadhra{" "}
          <br />
          <br />
          Veera=One with Fierceness, Bhadhra=One who is a form of goodness,
          Veerabhadhra. Which means his fierceness is for our good. Lord Shiva
          has three forms namely, Ugra-Bhoga-Yoga. His Ugra form is
          Veerabhadhra. Nandi-Parvata were born as a blessing of Lord Shiva.
          Veerabhadra and Bhadhrakali were born from Lord Shiva's matted locks.
          These two are an integral part of Kailasa. When the world's Mother and
          Father, Lord Shiva and Goddess Parvathi with all the powers vested in
          them start their journey towards, Earth's Kailasa, the world's centre,
          home to Vedas, Upasanakendra, their new called home, Srisaila
          Kshetram, Veerabhadhra accompanied them to reside here to bless all
          devotees. There are many shrines dedicated to Veerabhadhra all over
          India, but Srisailam is the only Kshetram where is he resides wholly
          with all his powers. Among the many avatars in which Lord Shiva
          resides in Srisailam, the avatar he chose to protect his devotees is
          Veerabhadhram. Among the avatars are Sudarshanaveerabhadhrudu,
          Jwalaveerabhadhrudu, Aaraamaveerabhadhrudu, Thotaveerabhadhrudu, etc.
          <br />
          <br />
          In Srisaila Mahakshetram, Bayalu Veerabhadra Swamy, Aaramaveerabhadra
          Swamy, Sudarshanaveerabhadhrudu, Jwalaveerabhadhrudu and
          Jataveerabhadra Swamy serve as the 5 guardians of Srisailam gateways.
          <br />
          <br />
          Sudarshana Veerabhadra Swamy resides in the hall facing the
          Mallikarjuna Swamy temple. Jwala Veerabhadhra Temple is situated
          facing North, onto the left side of the five temples established by
          the five Pandavas. Jataveerabhadra Swamy resides in the Veerabhadra
          Matam, southern to Bheemashankara Matam. Devotees believe that good
          luck and success is granted by the Lord if one visits all the five
          Veerabhadra Swamy temples in Srisailam.
          <br />
          <br />
          Veerabhadra Swamy is the guardian of Srisaila Kshetram who is also
          known as Bayalu Veerabhadra Swamy. Usually, Lord Vishnu at Shiva
          temple and Lord Shiva at Vishnu Temple host themselves as guardians.
          But the significance of Srisailam is that Veerabhadra Swamy hosts as a
          guardian of Srisaila Kshetram. Facing the east, in a hall towards the
          southern road to Ganghadhara Mandapam that's at the front of the main
          temple, is the Bayalu Veerabhadra Swamy temple. There is no roof to
          this temple, hence the god guards Srisailam without the fear of the
          scorching sun and tormenting rains. Few devotees, for this reason,
          address him as Kavali Veerabhadra Swamy.
          <br />
          <br />
          The idol of the Lord depicts regional representation with 10 arms
          adorning a crown. The right arms from bottom to top hold, Sword,
          Restraint, Battle Axe, Trident and Arrow. Similarly, his left arms
          hold, Dalu, Knife, Studded club, Snake and Bow. He is seen wearing a
          crown with Shiva Lingam carved into it, a necklace and a garland of
          skulls. To his right features Daksha and to his left is Bhadra Kali.
          The devotees believe that worshipping this god rids them of troubles,
          illnesses and gifts them good health.
          <br />
          <br />
          Devasthanam organises Abhishekam to Veerabhadra Swamy via
          Rudranamakam, Nityanaivedyam and other sacred rituals to facilitate
          blessings to devotees. So all devotees are requested to abide by the
          devasthanam regulations, and worship Veerabhadra Swamy, earn his
          complete blessings and protection.
          <br />
          <br />
          Sarvam Bhramaramba Sameta Mallikarjuna Charanaravindarpanamastu.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/bailu-veerabhadra-swamy.jpg",
      seo_tag: {
        title:
          "Bayalu Veerabhadra Swamy Temple | Bayalu Veerabhadra Swamy Srisailam",
        desc: "Bayalu Veerabhadra Swamy temple has no roof as he is considered as the protector of Srisaila Kshetram. People believe that Bayalu Veerabhadra Swamy protects Srisailam.",
        keywords:
          "Bayalu Veerabhadra Swamy Temple, Bayalu Veerabhadra Swamy Srisailam",
        h1_tag: "Bailu Veerabhadra Swamy ",
      },
    },
    "arama-veerabhadra-swamy": {
      heading: `Arama Veerabhadra Swamy`,
      description1: (
        <>
          Lord Shiva and Goddess Parvathi with all the powers vested in them
          decode to reside in, Earth's Kailasa, the world's centre, home to
          Vedas, home to Bindu-Natha form, Upasanakendra, Sri Chakram, their new
          called home, Srisaila Kshetram. The only Lord Shiva's Dhaamam that's
          going to remain before and after the Universe is Srisaila Kshetram.
          Srisailam is that can't be sized by history but is for the devotees
          alone. The god shows his compassion in Srisailam in the form of holy
          places and holy precincts. Aarama Veerabhadra Swamy temple belongs to
          this list of holy places.
          <br />
          <br /> Rudram Rudraavataram Huutavahanayanam Oordvaratham Sudamshtram
          Vyomangam Bheemaroopam Kinikinirabhasajwaalamoolavrutaangam
          Bhadrakaalipriyaaya Shriveerabhadhram Namami Asyadha Sharanam Naasthi
          Tvameva Sharanam Mama Paahimaam Veerabhadhra Rakshamaam Veerabhadhra
          <br />
          <br /> Veera=One with Fierceness, Bhadhra=One who is a form of
          goodness, Veerabhadhra. Which means his fierceness is for our good.
          Lord Shiva has three forms namely, Ugra-Bhoga-Yoga. His Ugra form is
          Veerabhadhra. Nandi-Parvata were born as a blessing of Lord Shiva.
          Veerabhadra and Bhadhrakali were born from Lord Shiva's matted locks.
          These two are an integral part of Kailasa. When the world's Mother and
          Father, Lord Shiva and Goddess Parvathi with all the powers vested in
          them start their journey towards, Earth's Kailasa, the world's centre,
          home to Vedas, Upasanakendra, their new called home, Srisaila
          Kshetram, Veerabhadhra accompanied them to reside here to bless all
          devotees. There are many shrines dedicated to Veerabhadra all over
          India, but Srisailam is the only Kshetram where is he resides wholly
          with all his powers. Among the many avatars in which Lord Shiva
          resides in Srisailam, the avatar he chose to protect his devotees is
          Veerabhadhram. Among the avatars are Sudarshanaveerabhadhrudu,
          Jwalaveerabhadhrudu, Aaraamaveerabhadhrudu, Thotaveerabhadhrudu, etc.
          <br />
          <br /> In Srisaila Mahakshetram, Bayalu Veerabhadra Swamy,
          Aaramaveerabhadra Swamy, Sudarshanaveerabhadhrudu, Jwalaveerabhadhrudu
          and Jataveerabhadra Swamy serve as the 5 guardians of Srisailam
          gateways.
          <br />
          <br /> Sudarshana Veerabhadra Swamy resides in the hall facing the
          Mallikarjuna Swamy temple. Jwala Veerabhadhra Temple is situated
          facing North, onto the left side of the five temples established by
          the five Pandavas. Jataveerabhadra Swamy resides in the Veerabhadra
          Matam, southern to Bheemashakara Matam. Devotees believe that good
          luck and success is granted by the Lord if one visits all the five
          Veerabhadra Swamy temples in Srisailam. <br />
          <br /> On the front side of the main temple of Srisailam temple, there
          are many Salu Mandapas constructed by Krishnadevaraya. The shrine of
          Arama Veerabhadra Swamy is located to the left of this Mandapam facing
          north, near Kalyana Katta. While describing the pilgrimage of Agastya,
          in Kasi Khanda, Srinadha mentions him as receiving worship bar
          offerings of devotees of as Garden Veerabhadra Swamy (Udyana
          Veerabhadra Swamy). That Udyana Veerabhada is now called Arama
          Veerabhadra Swamy. The shrine is completely built using stone, in
          Chalukya style. The shrine has steps leading to it with the temple
          doors decorated. The hall of the shrine is constructed using 16
          columns. The shrine has entryways in three directions, east, north and
          west. The idol of the Lord depicts regional representation with 2 arms
          adorning a crown. The right-hand holds a Restraint and the left-hand
          holds Dalu. He is seen wearing a crown, a necklace and anklets. To his
          right features Daksha and to his left is Bhadra Kali. The devotees
          believe that worshipping this god rids them of troubles, illnesses and
          gifts them good health. Facing the east in the shrines hall is another
          4 armed Veerabhadra holding Dalu, Bow, Knife and an arrow. This
          four-armed idol represents the idols that existed during the
          Vijayanagara kingdom. Historians say that this idol belongs to the
          Vijayanagara era.
          <br />
          <br /> So all devotees are requested to abide by the devasthanam
          regulations, and worship Veerabhadra Swamy, earn his complete
          blessings and protection.
          <br />
          <br /> Sarvam Bhramaramba Sameta Mallikarjuna
          Charanaravindarpanamastu.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/arama-veerabhadra-swamyd.jpeg",
      seo_tag: {
        title:
          "Arama Veerabhadra Swamy | Srisailam Devasthanam | Srisailam Online",
        desc: "Among Pancha Veerbhadras, Arama Veerabhadra Swamy is another form of Lord Shiva. As Arama Veerabhadra Swamy, Sri Mallikarjuna Swamy guards Srisailam.",
        keywords:
          "Arama Veerabhadra Swamy, Srisailam Devasthanam, Srisailam Online",
        h1_tag: "Arama Veerabhadra Swamy",
      },
    },
    "jata-veerabhadra-swamy": {
      heading: `Jata Veerabhadra Swamy`,
      description1: (
        <>
          Lord Shiva and Goddess Parvathi with all the powers vested in them
          decide to reside in, Earth's Kailasa, the world's centre, home to
          Vedas, home to Bindu-Natha form, Upasanakendra, Sri Chakram, their new
          called home, Srisaila Kshetram. The only Lord Shiva's Dhaamam that's
          going to remain before and after the Universe is Srisaila Kshetram.
          Srisailam is that can't be sized by history but is for the devotees
          alone. The god shows his compassion in Srisailam in the form of holy
          places and holy precincts. Jata Veerabhadra Swamy temple belongs to
          this list of holy places.
          <br />
          <br /> Rudram Rudraavataram Huutavahanayanam Oordvaratham Sudamshtram
          Vyomangam Bheemaroopam Kinikinirabhasajwaalamoolavrutaangam
          Bhadrakaalipriyaaya Shriveerabhadhram Namami Asyadha Sharanam Naasthi
          Tvameva Sharanam Mama Paahimaam Veerabhadhra Rakshamaam Veerabhadhra
          <br />
          <br /> Veera=One with Fierceness, Bhadhra=One who is a form of
          goodness, Veerabhadhra. Which means his fierceness is for our good.
          Lord Shiva has three forms namely, Ugra-Bhoga-Yoga. His Ugra form is
          Veerabhadhra. Nandi-Parvata were born as a blessing of Lord Shiva.
          Veerabhadra and Bhadhrakali were born from Lord Shiva's matted locks.
          These two are an integral part of Kailasa. When the world's Mother and
          Father, Lord Shiva and Goddess Parvathi with all the powers vested in
          them start their journey towards, Earth's Kailasa, the world's centre,
          home to Vedas, Upasanakendra, their new called home, Srisaila
          Kshetram, Veerabhadhra accompanied them to reside here to bless all
          devotees. There are many shrines dedicated to Veerabhadra all over
          India, but Srisailam is the only Kshetram where is he resides wholly
          with all his powers. Among the many avatars in which Lord Shiva
          resides in Srisailam, the avatar he chose to protect his devotees is
          Veerabhadhram. Among the avatars are Sudarshanaveerabhadhrudu,
          Jwalaveerabhadhrudu, Aaraamaveerabhadhrudu, Thotaveerabhadhrudu, etc.
          <br />
          <br /> In Srisaila Mahakshetram, Bayalu Veerabhadra Swamy,
          Aaramaveerabhadra Swamy, Sudarshanaveerabhadhrudu, Jwalaveerabhadhrudu
          and Jataveerabhadra Swamy serve as the 5 guardians of Srisailam
          gateways.
          <br />
          <br /> Sudarshana Veerabhadra Swamy resides in the hall facing the
          Mallikarjuna Swamy temple. Jwala Veerabhadhra Temple is situated
          facing North, onto the left side of the five temples established by
          the five Pandavas. Jataveerabhadra Swamy resides in the Veerabhadra
          Matam, southern to Bheemashakara Matam. Devotees believe that good
          luck and success is granted by the Lord if one visits all the five
          Veerabhadra Swamy temples in Srisailam.
          <br />
          <br /> Veerabhadra Matam is located to the south, 150 meters away from
          Bheemashankara Matam. Facing west is a Shiva temple. This northern
          hall has a doorway leading to the temple's hall. Just in front of this
          doorway, on a wall is the carving of Jata Veerabhadra Swamy who is 6
          feet tall. Historians believe that this carving is very ancient.
          Currently, this temple is under renovation by Srisaila devasthanam.
          <br />
          <br /> So all devotees are requested to abide by the devasthanam
          regulations, visit the other 4 Veerabhadra temples before visiting
          Jata Veerabhadra Swamy to earn his complete blessings and protection.
          <br />
          <br />
          Sarvam Bhramaramba Sameta Mallikarjuna Charanaravindarpanamastu.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/jata-veerabhadra-swamy.jpg",
      seo_tag: {
        title:
          "Jata Veerabhadra Swamy | Jata Veerabhadra Swamy Temple | Srisailam",
        desc: "Jata Veerabhadra Swamy temple is very near to Srisailam main temple. People believe that Jata Veerabhadra Swamy has magnificent powers and gives boons to devotees.",
        keywords:
          "Jata Veerabhadra Swamy, Jata Veerabhadra Swamy Temple, Srisailam",
        h1_tag: "Jata Veerabhadra Swamy",
      },
    },
    "ankalamma-talli": {
      heading: `Ankalamma Talli`,
      description1: (
        <>
          Lord Shiva and Goddess Parvathi with all the powers vested in them
          decode to reside in, Earth's Kailasa, the world's centre, home to
          Vedas, home to Bindu-Natha form, Upasanakendra, Sri Chakram, their new
          called home, Srisaila Kshetram. The only Lord Shiva's Dhaamam that's
          going to remain before and after the Universe is Srisaila Kshetram.
          Srisailam is that can't be sized by history but is for the devotees
          alone. The god shows his compassion in Srisailam in the form of holy
          places and holy precincts. Ankalamma temple belongs to this list of
          holy places. The Grama Devata Ankalamma temple in Srisailam is located
          facing North on the right end of the road that's in front of the main
          temple.
          <br />
          <br />
          The Grama Devata tradition is an ancient one. Devibhagavatam suggests
          that the union of the forces of nature takes the form of a Grama
          Devata. The goddess Aadiparashakti showers her compassion into the
          world in the form of beautiful nature. So all the Grama Devatas are
          the forms of Aadiparashakti. Ancient Puranas also suggest that
          Aadiparashakti takes the form of Grama Devatas.
          <br />
          <br />
          The goddess is worshipped in many ways, including offering Yagnas,
          reciting mantras, offering daily prayers and through folk songs and
          dances. Grama Devatas are worshipped commonly through folk songs and
          dances. This proves that the opportunity to worship her is not just
          limited to one section of society but to all who want to worship her
          in many ways. It is a tradition to worship Grama Devata as she is the
          protector of villages, village people, animals and birds that reside
          in villages. Many people worship her as the Goddess of their kind and
          as the Goddess of their home. Ankalamma is derived from Ankanalamma.
          Ankanamu means region and hence the goddess is also addressed as the
          goddess of the Earth.
          <br />
          <br />
          The temple of Ankalamma is an ancient one. There is no evidence of its
          date of origin. The doors and the columns are devoid of any carvings
          or decorations which suggest that the temple was perhaps built very
          long ago. Also, a banyan tree at the top of the temple extends over to
          the surroundings of the temple area. The devasthanam has renovated the
          temple excellently to its former beauty. The renovated temple now has
          a mandapam. The goddess is seated with her right leg folded and left
          leg hanging towards the ground. The four-armed goddess in her
          right-hand from bottom to top holds a knife, a pellet drum entwined
          with a snake and similarly, the left-hand holds, Panapatra and a
          trident. The goddess is seen wearing a crown and ornaments like a
          necklace.
          <br />
          <br />
          The locals give much importance to this goddess and offer special
          prayers during marriages. During Sankranti, Ugadi, Dasara and Maha
          Shivaratri, when the procession of Mallikarjuna Swamy and Bhramaramba
          Devi reaches the temple of Ankalamma Devi, the devotees offer special
          prayers to her.
          <br />
          <br />
          Srisaila Devasthanam offers Abhishekam every Friday and daily Archana
          to the goddess. So all devotees are requested to abide by the
          devasthanam regulations, worship Ankalamma Devi and earn her complete
          blessings.
          <br />
          <br />
          Sarvam Bhramaramba Sameta Mallikarjuna Charanaravindarpanamastu.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/ankalamma-talli.jpg",
      seo_tag: {
        title:
          "Ankalamma Talli Temple | Srisailam Devasthanam | Srisailam Temple",
        desc: "Ankalamma Talli is Grama Devatha of Srisailam. Ankalamma Thalli Temple is in front of East Gopuram of Srisaila Devasthanam. Many devotees visit this temple.",
        keywords:
          "Ankalamma Talli Temple, Srisailam Devasthanam, Srisailam Temple",
        h1_tag: "Ankalamma Talli Temple",
      },
    },
    "pathaleswara-swamy": {
      heading: `Pathaleswara Swamy`,
      description1: (
        <>
          Lord Shiva is in the form of Lingam everywhere at Srisailam.
          Palakurthi Somanatha (1160 A.D to 1240 A.D) turned back without making
          Darshanam of Sri Mallikarjuna Swamy, as he felt that he was stepping
          on lingam by stepping onto Srisailam hills. He mentioned that
          Srisailam itself resembles a large Lingam in his writings. Thus we can
          understand that Srisailam is absolute form of Lord Shiva. Likewise God
          Shiva resided near to Pathalaganga in the form of Lingam as
          Pathaleswara Swamy.
          <br />
          <br />
          Vittalamba, royal consort of Hari Hara-II constructed the steps of
          Pathalaganga at around 1393 A.D to 1396 A.D. On that time, she found
          the sanctity of Pathaleswara Swamy and constructed a temple. Now we
          can see the temple constructed in 14th century A.D., that resembles
          the medieval period architecture. Devotees who get bathe at
          Pathalaganga, make Darshanam of Sri Pathaleswara Swamy. The main
          sanctum consists of Sri Pathaleswara Swamy Lingam which is slightly
          inclined in shape. Srisaila Devasthanam is organising Sri Pathaleswara
          Swamy temple by offering minimal daily sevas. Sri Pathaleswara Swamy
          temple is just 2kms away from Sri Bhramaramba sametha Mallikarjuna
          Swamy Temple and nearby Pathalaganga.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/pathaleswara-swamy.jpg",
      seo_tag: {
        title:
          "Pathaleswara Swamy Temple | Srisailam Devasthanam | Srisailam Online",
        desc: "A holy spot on the way to Pathala Ganga is Pathaleswara Swamy Temple. Srisaila Devasthanam undertakes all the temples and their maintenance which are in Srisailam.",
        keywords:
          "Pathaleswara Swamy Temple, Srisailam Devasthanam,Srisailam Online",
        h1_tag: "Pathaleswara Swamy Temple",
      },
    },
    "kala-bhairava-swamy": {
      heading: `Kala Bhairava Swamy`,
      description1: (
        <>
          Bheerunaamabhayapradho Bhavabhayaakradnasya Hethusthato Hrudayaagni
          Pradhitasya Bheeravaruchamisho-stakasyatnakaha Bheeram Vaarvathi Yaha
          Svaaaginivahastasyaprabhurkhreravo
          <br />
          <br />
          Lord Shiva is called bhairava as he is the creator, ruler and
          destroyer of this world. Vishvasya Bharanaath Ramanaath Vamanaath
          Shrushti Sthithi Samhaarakari Parashivaha-says Yogini Hrudayam.
          Troubles, hurdles, distress. The one who spawns fear for such
          disasters is bhairava. Bibhithe Klesho Yasmaadhithi bhairavaha.
          bhairava is super powerful. All Shaktis and Siddhis are his followers.
          Usually, in Shiva temples, the Kala bhairava temple is located in the
          North-Eastern direction.
          <br />
          <br />
          The four-armed bhairava is pitch black with three eyes always
          represented with sparks of flames. He holds in his right hand a
          trident and a skull while his left-hand holds restraint and a drum.
          His vehicle is a dog. It is believed that he is the guardian of the
          shiva temple, the ornaments and possessions of Lord Shiva.
          <br />
          <br />
          The origin of bhairava is detailed in Skanda Purana and Kashi Khandam.
          Once all the gods meet with Lord Brahma on Meruparvat. they were asked
          as to who was the supreme of all when Lord Brahma and Kratuvu god
          answered it was them. When they couldn't reach a conclusion they've
          asked the Veda's to decide the supreme god and this is what Vedas
          said: "The one who creates this world and the living, the one who
          prospers the world, the Shankara is the supreme lord". Angered by it,
          Lord Brahma questioned how a god who lives in a graveyard, who wears
          serpent ornaments, who wears ashes can be a supreme god. To which
          Vedas answered, "Shiva is a sacred soul and along with the power of
          Shakti, he is the supreme god of all." Then in a glowing light, a
          boy's form originated between them. Lord Brahma consumed with ego with
          his 5th head asks the glowing form, "who is the greatest between the
          two of us?" The form now completely takes the form of a little boy.
          Lord Brahma says, "since you've born from my fifth head, you will be
          called Rudra". The little boy immediately takes the form of Bhairava.
          Lord Brahma says, "since you've got all the power to scare the time
          itself, you shall be called Kala Bhairava." Bhairava after receiving
          the boon, with the little fingernail of his left hand beheads the
          fifth head and says, "I've removed the head that was consumed by ego
          and insulted Lord Shiva." After this, the only god with five heads was
          one and only Lord Shiva.
          <br />
          <br />
          Lord Shiva tells Bhairava to hold the skull of Lord Brahma and roam
          the world for alms to get rid of Brahmahatya(The sin of killing
          Brahma). He also presented him with a boon that offers Shiva's
          blessings to all devotees who worship Bhairava. Puranas suggest that
          Bhairava takes the form of Kalpantabhairava, Krodhanabhairava,
          Kapalabhairava, Samharabhairava, Ugrabhairava, Usmattabhairava and
          Gundabhairava. Shivapuranam suggests that Lord Shiva killed Andakasura
          in the form of Bhairava. Even Shaneeshwara got rid of an insult after
          he performed an Archana to Kala Bhairava. Kala Bhairava form is the
          form of time. Hence called Amarthaka. Since he forgives devotees sins,
          he is called Paapabakshya. Bhairava is seen in various forms inside
          the temple. In Bhairavasela near the temple, he blesses the devotees
          in a peculiar form.
          <br />
          <br />
          So all devotees are requested to abide by the devasthanam regulations,
          visit bhairavasela, worship Kala Bhairava and earn complete blessings
          of the Mallikarjuna Swamy & Bhramarambika Devi.
          <br />
          <br />
          Sarvam Bhramaramba Sameta Mallikarjuna Charanaravindarpanamastu.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/kala-bhairava-swamy.jpg",
      seo_tag: {
        title:
          "Kala Bhairava Swamy | Kala Bhairava Swamy Temple | Srisailam Online",
        desc: "Kala Bhairava Swamy is a ferocious form of Lord Shiva. Kala Bhairava Swamy temple has Lord Shiva’s Idol in a fierce dancing posture.",
        keywords:
          "Kala Bhairava Swamy, Kala Bhairava Swamy Temple, Srisailam Online",
        h1_tag: "Kala Bhairava Swamy",
      },
    },
    "nagalooty-veerabhadra-swamy": {
      heading: `Nagalooty Veerabhadra Swamy`,
      description1: (
        <>
          Rudram Rudraavataram Huutavahanayanam Oordvaratham Sudamshtram
          Vyomangam Bheemaroopam Kinikinirabhasajwaalamoolavrutaangam
          Bhadrakaalipriyaaya Shriveerabhadhram Namami Asyadha Sharanam Naasthi
          Tvameva Sharanam Mama Paahimaam Veerabhadhra Rakshamaam Veerabhadhra
          Veera=One with Fierceness, Bhadhra=One who is a form of goodness,
          Veerabhadhra. Which means his fierceness is for our good. Lord Shiva
          has three forms namely, Ugra-Bhoga-Yoga. His Ugra form is
          Veerabhadhra. Nandi-Parvata were born as a blessing of Lord Shiva.
          Veerabhadra and Bhadhrakali were born from Lord Shiva's matted locks.
          These two are an integral part of Kailasa. When the world's Mother and
          Father, Lord Shiva and Goddess Parvathi with all the powers vested in
          them start their journey towards, Earth's Kailasa, the world's centre,
          home to Vedas, Upasanakendra, their new called home, Srisaila
          Kshetram, Veerabhadhra accompanied them to reside here to bless all
          devotees. There are many shrines dedicated to Veerabhadra all over
          India, but Srisailam is the only Kshetram where is he resides wholly
          with all his powers. Among the many avatars in which Lord Shiva
          resides in Srisailam, the avatar he chose to protect his devotees is
          Veerabhadhram. Among the avatars are Sudarshanaveerabhadhrudu,
          Jwalaveerabhadhrudu, Aaraamaveerabhadhrudu, Thotaveerabhadhrudu, etc.
          When devotees enter the main gateway of Srisailam from the entrance of
          Srisailam hills, Veerabhadra is the first one to greet them and offer
          his protection. His forms count for a total of 36. Lord Shiva showered
          his compassion upon his devotees and took the form of Veerabhadhra and
          glorified Srisialam further. Before the Ugadi celebration in
          Srisailam, Veerashaiva devotees reach through angnigundam and offer
          their celebrations and prayers here in their Veera Shaiva style. There
          are five Veera Bhadra temples in Srisailam. One among them is the
          Nagalooty Veerabhadra temple. Nagalooty is a forest region situated
          among the dense forest on the hills of Srisailam. Devotees from the
          south and the west during the olden days used to make their way
          towards Atmakur, following a jungle path from Atmakur, the devotees
          would reach Bheemuni Kolanu via Nagalooty before arriving at Srisailam
          via Kailasadwaram. The stairway that leads to Srisailam built by the
          Reddi kings starts from Nagalooty. The walking distance from Nagalooty
          to Srisailam is just 28 km. It is not clear how old this temple is or
          for how many years the god is receiving prayers here. The north-facing
          temple is surrounded by a 12 ft tall and 3 ft wide wall that resembles
          a castle wall. Notably, the construction style of this wall resembles
          that of the Srisailam temple. But the outer rocks on the Srisailam
          wall are carved to represent beautiful sculptures, while this wall is
          not carved. The ruins on the southern side of this temple show that
          there used to be a gopuram hall here. There used to be small halls on
          every corner adjacent to the surrounding wall. Other than the
          southeastern hall, all other halls are in ruins. This temple has a
          sanctum, prayer hall and the main front-facing hall. The roof above
          the sanctum is beautifully decorated but the front part of it has been
          demolished. Dwarapalakas(guardians) have been carved into the stone
          here. Facing north in the sanctum is the 7 ft tall Veerabhadra idol
          holding a sword in his right hand and left hand held down freely,
          holding a Dalu. This is a fierce form of god. The original idol
          crumbled with time, so the locals replaced it with another idol. The
          new idol is 6ft tall and four-armed. He is seen holding a Sword, Dalu,
          Villu and Ambu. The older idol is fierce whereas this new idol is
          pleasant looking. Both the idols have Daksha featuring to the right.
          Although the inner prayer hall is small, the front-facing hall is
          huge. There is a doorway on the northern side of this hall. On the
          western side of the hall, facing east is a small temple that is home
          to a shiva lingam. There is a Bhadrakali temple right next to this
          Veera Bhadra temple. The idol has been placed recently which features
          the two armed goddesses wearing a skull garland. Although the ancient
          Veerabhadra idol has been found, the ancient Bhadrakali idol is
          nowhere to be seen. To the northern side of the front-facing hall is
          another hall with 18 columns. Between the columns is the ancient idol
          of Nandi. The ruins suggest that there were once two columns in front
          of this hall. To the northeast is well. The temple which was once
          standing tall in its full glory is now in ruins, pleading for its
          return to its age-old beauty. 30 ft away from the Veerabhadra temple
          is a hall that once featured ancient shiva lingam that was called
          Vishweshwara lingam and Nandi which was called Kalyana Basaveshwara.
          These idols are in ruins too which were replaced by new idols. 100
          meters away from the Veerabhadra temple is a 10 armed Uchchhishta
          Ganapati temple. Facing west, this temple has a sanctum and a
          front-facing hall. At the top of the doorway are the carvings of
          Gajalakshmi and Nityaganapati. On the crown of the god is a one-headed
          Nagendra. The 10 armed god holds a lotus, elephant tusk, ankusham, the
          disc in his right hands, while his left hands hold, conch, ankusham,
          restraint and Ambu. To the right of the idol is a rat and to the left
          is a swan. In the middle of the two are lotus and footwear. There is
          another small shiva temple beside the Ganapathi temple facing the
          south. Veerabhadra resides here. From its southern side are the steps
          that lead to Srisailam. Along this path is an idol of a goddess named
          Kuruvalamma. Among all the devotees that used to visit Srisailam,
          those belonging to the Kuruva sect used to worship this goddess the
          most. It is surprising that many devotees choose to walk this path to
          reach Srisailam even to this day, where there is abundant public
          transport that connects Srisailam from many places including
          Karnataka. During celebrations, devasthanam appoints teams that reach
          Nagalooty to offer special prayers and offer temporary facilities to
          devotees like electricity, water and annadanam. Such a significant
          place reflects the amazing spiritual history of Srisailam. Hence,
          devotees must understand the sanctity of this area when they decide to
          visit for their prayers. So all devotees are requested to abide by the
          Devasthanam regulations, maintain the sanctity of the region and offer
          their prayers at the shrine. Sarvam Bhramaramba Sameta Mallikarjuna
          Charanaravindarpanamastu.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/nagalooty-veerabhadra-swamy.jpeg",
      seo_tag: {
        title:
          "Nagalooty Veerabhadra Swamy | Veerabhadra Swamy Temple in Srisailam | Nagalooty Srisailam",
        desc: "Nagalooty is an ancient route of Srisailam for devotees who come by walk. Nagalooty Veerabhadra Swamy Temple complex was constructed around the 13th Century.",
        keywords:
          "Nagalooty Veerabhadra Swamy, Veerabhadra Swamy Temple in Srisailam, Nagalooty Srisailam",
        h1_tag: "Nagalooty Veerabhadra Swamy – Srisailam",
      },
    },
    "istakameshwari-temple": {
      heading: `Istakameshwari Temple`,
      description1: (
        <>
          The sacred Srisailam is ancient and elaborate in its historic
          significance. This holy precinct is home to many sacred temples,
          Ashrams, Mutts, water bodies and picturesque beauty. This is why it is
          believed that Siddhis and the greatest devotees of Lord Shiva travel
          from the Himalayas down to Srisailam to offer their prayers to
          Mallikarjuna Swamy. The compassion of God is in every bit of Srisailam
          in the form of holy water bodies, holy precincts and sacred trees.
          This is because the Lord has come down to reside in Srisailam along
          with all his beloved water bodies, trees and other gods to bless his
          devotees. One such holy place is where Ishtakameshwari Devi resides.
          <br />
          <br />
          The region where the Ishtakameshwari Devi resides is home to the true
          beauty of nature. The devotees can witness rabbits jump, the deers
          dance and curious monkeys while travelling through the region. The
          forest is filled with blooming trees, medicinal plants and nature at
          its best. The journey is as terrifying as the beauty around here.
          Devotees need to cross gigantic rocks. With the divine blessings, they
          can travel this path to seek the spiritual happiness of worshipping
          the goddess.
          <br />
          <br />
          Among all the spiritual cultures in India, Shakteya culture is among
          the significant ones. World's Mother and Father, Lord Shiva and
          Goddess Parvathi with all the powers vested in them decide to reside
          in, Earth's Kailasa, the world's centre, home to Vedas, home to
          Bindu-Natha form, Upasanakendra, Sri Chakram, their new called home,
          Srisaila Kshetram. This is why Srisailam is home to many powers.
          Arthanareshwari Bhramara Parameshwari Mukhyadashakoti Mahashakti
          Sthanaanaam- Bhramarambika Devi with all her forms and powers has come
          down to Srisailam. One such powerful form is Ishtakameshwari Devi.
          <br />
          <br />
          Srisaila Khandam mentions about Ishtakameshwari-Ishtakameshwarudu very
          briefly. It is our tradition that considers women as a representation
          of power. This is why Vedas say Matrudevobhava, which holdss mother on
          a pinnacle. The goddess has been well-described in many Suktas,
          Upanishads, Devibagavatam, Kalikapuranam, Devisaptashati,
          Soundaryalahari, etc. To recite about her is an eminent part of the
          mantra sastras. Vyaktimambamayam Sarvam, Avyaktam Tu Maheshwaraha,
          this way we should recite mantras that suggest that mother is the
          universe. Ishtakameshwari form teaches how to worship the divine power
          rightfully. In the mantra sastras and aagamas, the goddess is recalled
          many times. 21km away from Srisailam, near Srisailasikharam, amidst
          the dense forest resides the goddess. Via Tripurantaka, the eastern
          gate to Srisailam, many devotees walk via a forest path to seek the
          blessings of the goddess. One may reach the premises by travelling
          11kms on Srisailam-Dornala road, then turn left towards Nekkantipalu
          for 10 km. Regular vehicles can't travel via this route. Only vehicles
          like Mahindra Matador and other four-wheel drives with experienced
          drivers can reach the goddess via this route. This route is very
          difficult but is filled with the compassion of the goddess. There is
          another route via Sunnipenta.
          <br />
          <br />
          There is no evidence of the origins of this temple. Historians are
          trying to solve the mystery by observing its construction style. It is
          notable that the gopuram resembles that of Lord Shiva temple built in
          Kodimagandla in Kurnool. The idol is carved using a beautiful stone.
          Such a beautiful idol can not be seen other than in Srisailam. She is
          seated on a dais and seems to be meditating. The dais is beautiful
          too. The four-armed goddess is holding lotus flowers with her upper
          hands and Rudraksha mala with her right lower hand and a shiva lingam
          in her left hand. She seems to have been meditating in the name of
          Lord Shiva. She is seen wearing a crown and ornaments. Her ornaments
          seem to be made out of Rudrakshas. So it looks like there is a
          difference in the depiction of Kameshwari and Ishtakameshwari Devi.
          <br />
          <br />
          The Puranas consider this form as a form of Parvati. So one must know
          that the goddess holds the power to bestow knowledge and peace to all
          who remember her as a goddess who meditates in the name of Lord Shiva
          and wears the lord's favourite Rudraksha. The locals believe this
          avatar of her is Ishtakameshwari or Muktakameshwari. There seems to be
          evidence of another ancient temple adjacent to hers. Upasanakulu
          believe that this belongs to Ishtakameshwara or Lord Shiva whose
          mention can be seen in Srisaila Khandam. In front of her temple is the
          idol of Siddhi in ruins, the Idols of Mahishasuramardhini and a
          Kapalika. This suggests that the region was once inhabited by Siddhis
          and Kapalikas. Srisailam was home to many Kapalikas.
          <br />
          <br />
          In front of her temple is a water stream that flows towards the north.
          According to Vaastu, north-flowing water streams hold utmost
          importance. In Srisailam, the Krishna River in the name of Patalaganga
          flows towards the North. Historians believe this temple dates back to
          the 8th century. Many spiritual gurus visit this place to offer
          Tapasyas and homams. Devasthanam has plans to revive the former glory
          of the temple path and the temple. When one touches the forehead of
          the goddess it feels as soft as a petal. It is a fact that the temple
          witnesses many spiritual gurus offering their worships here.
          <br />
          <br />
          So all devotees are requested to abide by the devasthanam regulations
          and worship the land of Siddhis and spiritual gurus, Ishtakameshwari
          Devi temple at Srisailam
          <br />
          <br />
          Sarvam Bhramaramba Sameta Mallikarjuna Charanaravindarpanamastu.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/istakameshwari-temple.jpg",
      seo_tag: {
        title:
          "Istakameshwari Temple | Temples Near Srisailam | Places to Visit Near Srisailam",
        desc: "The wildlife safari in Srisailam is a journey to Istakameshwari Temple located in the deep forests of Nallamala Hills. It is an outstanding experience in Srisailam.",
        keywords:
          "Istakameshwari Temple, Temples Near Srisailam, Places to Visit Near Srisailam",
        h1_tag: "Istakameshwari Temple – Srisaila Devasthanam",
      },
    },
    "mystical-spots": {
      heading: `Mystical spots`,
      description1: <></>,
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-TheTemple/sd-annapurna-devi.png",
      seo_tag: {
        title: "",
        desc: "",
        keywords: "",
        h1_tag: "",
      },
    },
    hatakeswaram: {
      heading: `Hatakeswaram`,
      description1: (
        <>
          Hatakeswaram is an interesting visiting spot in Srisailam. 5 km away
          from the western side of Srisailam hills is HatakeswaraKshetram.
          Skanda Purana details this place beautifully. People of the region
          tell an interesting tale about Hatakeshwara. This tale signifies the
          importance of devotion and not an actual incident. Once upon a time,
          there lived a pottery artist named Keshappa in Srisailam. He used to
          arrange feasts and fruits for the travellers of that region. He used
          to consider feeding people as Shivarchana.
          <br />
          <br />
          Since the area was majorly a dense forest, he would consider everyone
          who wishes to travel among the forest as a form of Shiva. Many feed
          people to earn a reputation but Keshappa fed people only out of
          devotion. As a result, he earned a great name in the surrounding areas
          which led to jealousy among a few people. One day when Keshappa was
          away, a few consumed with jealousy broke all his pottery and pottery
          tools. Upon returning, Keshappa felt downhearted seeing his years of
          work in pieces. Just then a few travellers arrived. Disheartened that
          he was now left with pieces of pottery and no money to feed the
          travellers, Kesahppa went inside his home weeping. A Shivalingam
          emerged from the pieces of broken pottery and Lord Shiva appeared in
          the form of Jyotirlingam. Lord Shiva asked Keshappa to look at what's
          in his kitchen. Surprisingly, there were many cooked food items in the
          Kitchen. Elated, Keshappa joined his hands to thank the lord and fed
          his traveller guests. Since Shiva emerged from Atika(pot), the god is
          called Atikeshwara.
          <br />
          <br />
          The name Hatakeswara according to Skanda Purana means golden(Hataka)
          god. To regular devotees, the lingam may seem like just any other
          lingam but for spiritual gurus and yogis, this lingam emanates a
          golden glow that of a fire. The shrine of Hatakeswara also has a
          Hatakeswara water stream. One must take a bath in the stream and then
          worship the Lord, just how Agastya Maharshi did during his time. All
          trees surrounding this area are Santana trees that are said to bless
          devotees with a child if one eats its fruits after worshipping the
          god. The Santana tree that's in front of the shrine has an idol of
          Bhairava that's 3.5 feet. The four-armed god holds a beheaded head,
          drum, trident and a knife. He wears a skull garland and earrings. The
          shrine is facing the west. This ancient temple built using rocks dates
          back to eras and puzzles historians with its origin. This shrine
          resembles the shrines established by the five Pandavas in Srisailam.
          The hall of the temple has 16 columns and the doorway has Gajalakshmi
          carved into it. Skanda Puranam suggests that Shiva took the form of
          the golden linga after winning a war against the Tripurasuras.
          <br />
          <br />
          3kms away from the main road of the main temple, towards the right at
          200 meters is Hatakeswaram. For the devotees who used to travel from
          Karnataka and Rayalaseema, the entry point to Srisailam used to show
          itself only after they've crossed, Pedda Cheruvu, Bheemunikolanu,
          Kailasa Dvaram via Hatakeswaram. Agastya, Lopamudra, Vyasa Maharshi,
          Garga Maharshi, Durvasoma Maharshi, Shankaracharyulu, leader of
          Mantralaya-Raghavendra Swamy, Hema Reddy Mallamma, Basaveshwarudu,
          Siddharamappa, Chakrapani Ranganatha, The leader of Kanchikamakoti
          Peetam-Chandrashekhara and many such rishis, siddhis and gurus visited
          Srisailam. So all devotees are requested to abide by the devasthanam
          regulations, worship the golden lingam at Hatakeswara shrine and earn
          the complete blessings of Lord Shiva.
          <br />
          <br />
          Sarvam Bhramaramba Sameta Mallikarjuna Charanaravindarpanamastu.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/hatakeswaram.jpeg",
      seo_tag: {
        title:
          "Hatakeswaram Temple | Hatakeswaram Temple Srisailam | Srisailam",
        desc: "Hatakeswaram Temple is opposite of Phaladhara Panchadhara at Srisailam. A tree called as Santhana Phala Vruksham is present in front of Hatakeswara Swamy Temple.",
        keywords:
          "Hatakeswaram Temple, Hatakeswaram Temple Srisailam, Srisailam",
        h1_tag: "Hatakeswaram Temple",
      },
    },
    sikhareswaram: {
      heading: `Sikhareswaram`,
      description1: (
        <>
          The sacred Srisailam is ancient and elaborate in its historic
          significance. This holy precinct is home to many sacred temples,
          Ashrams, Mutts, water bodies and picturesque beauty. This is why it is
          believed that Siddhis and the greatest devotees of Lord Shiva travel
          from the Himalayas down to Srisailam to offer their prayers to
          Mallikarjuna Swamy. The compassion of God is in every bit of Srisailam
          in the form of holy water bodies, holy precincts and sacred trees.
          This is because the Lord has come down to reside in Srisailam along
          with all his beloved water bodies, trees and other gods to bless his
          devotees. One of such holy theerthas is Saileswaram known as
          Saileswaram, Sarveswaram, Seleswaram, etc.
          <br />
          <br />
          Kedarasdodakam Pitva Vaaranasyaam Mrutasthada Srisaila Sikharam
          Drushtva Punarjanma Na Vidyate.(Srisaila Khandam) Tatreiva Shikaram
          Ramyam Parvatam Parashobhitam Shrugjadharmamaatreni Punarjanma Na
          Vidyate. (Padma Puranam) Srisaila Sikharam Drushtva Vaaranasyaam
          Mrutodruvam Kedarasyodakam Pitva Punarjanma Na Vidyate. Srisaila
          Sikhare Drushtve Manujeihi Paapakarmabihi Chitva Sarvani Paapani
          Yeshaam Muktham Dadati Cha. (Shiva Puranam) These lines detail the
          significance of visiting Srisaila Sikharam. Srisaila Sikharam(peak) is
          at the height of 2850 ft from sea level. Among the hills of Srisailam,
          this one is the tallest. Many centuries ago, devotees used to visit
          Srisailam by following the hilly, jungle path. The dense forest used
          to make their journey even harder and the tired devotees used to halt
          for rest and then reach the peak to complete their visit. Puranas
          state that when one takes a look at the peak from any distance, they
          are rid of all sins and the life-death cycle. The peak is located at a
          distance of 8kms from Mallikarjuna Swamy temple. The peak is a
          hilltop. The god that resides on Sikareswaram is Veerashankara who is
          most commonly known as Sikhareswara. The origin of the temple is
          unknown. But a 1920 A.D inscription that is seen at the front of the
          temple on the hill suggest that the temple belongs to an ancient era,
          before 1920.
          <br />
          <br />
          Sikhareswara temple is east facing and has a sanctum, prayer hall and
          a front-facing hall. The temple resembles a hilly cave where the god
          receives many prayers. The prayer hall is smaller than the sanctum and
          the front-facing hall has 16 columns. At the middle of the
          front-facing hall, in between four columns is Nandi. On the inside of
          the front-facing wall, a bordered walkway is seen on three sides.
          <br />
          <br />
          The steps that lead from the main entrance into the temple are
          constructed recently. Those who travel in private vehicles can reach
          the front side of the temple. On top of the temple is an area where a
          mortar and pestle are placed. This area can be accessed using 42 steps
          from the ground floor of the temple. Devotees crush seseme seeds here
          using mortar and pestle; then place their fingers of their right on
          either side of Nandi's horns and try to locate the Srisailam main
          temple. It is said that the mortar used to have a hole at the bottom
          from which the seseme paste would fall on to the god that resides
          below. The ancient rock has been replaced by a new one with no hole,
          but the custom still exists.
          <br />
          <br />
          Here's a folklore about Sikhareswaram. After destroying Ravana, Sri
          Rama established a Lingam in Rameshwaram, after which he was consumed
          with the burden of Braahmahatyadosham. Following the advice of Narada,
          Sri Rama comes down to Srisailam to relieve his guilt. Lord Shiva
          along with Kumaraswamy were frequenting the area of
          Tripurantakakshetra. Lord Shiva in the form of Mallikarjuna, when
          hearing upon Sri Rama’s arrival decided to stay in a cave alongside a
          water body among a hill of Srisailam. The peace at this place has
          consumed Mallikarjuna Swamy. Sri Rama reached the highest hill where
          the lord resides. By the time Sri Rama took the blessings from the
          lord, the lord turned into a Shiva Lingam. The lingam merged into the
          hills of Srisaialam. This lingam is now known as Mallikarjunaswamy
          Lingam. The hill that Sri Rama climbed is Sikhareshwaram. This is a
          folklore, but it is a fact that Sri Rama accompanied by Sita and
          Laxman not only visited Srisailam, and established Sahasralingam but
          also showed how to offer Pradakshina at Srisailam by offering it
          himself. This Pradakshina is known as Sriramapradakshina.
          <br />
          <br />
          Veerabhadra can be seen at the front of the Sikhareswara temple.
          Similarly, Lakshmi & Ganapati can be seen in front of the front facing
          hall. At the backside of the temple, near the old stairway is an
          ancient veerabhadra idol in ruins. At the front of the temple is a 300
          sq.ft gundam. It is believed that a bath in the water in the gundam
          presents one with riches. This is why its called Vibhuthi Gundam.
          Manjari, a renowned devotee also claimed that one glance at the peak
          rids of all sins. So all devotees are requested to abide by the
          devasthanam regulations and worship the Sikhareswara on the peak if
          Sikhareswaram at Srisailam.
          <br />
          <br />
          Sarvam Bhramaramba Sameta Mallikarjuna Charanaravindarpanamastu.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/sikhareswaram.jpeg",
      seo_tag: {
        title:
          "Sikhara Darshanam Srisailam | Sikhareswaram | Srisaila Devasthanam",
        desc: "People believe that Sikhara Darshanam in Srisailam rids reincarnation and gifts Moksham. Devotees view the Sikharam of Srisailam Temple from Sikhareswaram.",
        keywords:
          "Sikhara Darshanam Srisailam, Sikhareswaram, Srisaila Devasthanam",
        h1_tag: "Sikhara Darshanam Srisailam",
      },
    },
    "hemareddymallamma-(mallamma-kanneeru)": {
      heading: `Hemareddy Mallamma`,
      description1: (
        <>
          "Kotijanmakruteihi Punyeihi Shive Bhakthihi Prajayethe" All the good
          karma of many lives is found at the feet of Lord Shiva for the
          devotees who show faith. Lord Shiva becomes dearer to those who show
          faith and can grant happiness to all the living.
          "Samsaravishadashtanam Jantunaam Avevikaanaam
          Chandrashekarapaadabhajasevanam Paramoushudam" The Holy Srisailam was
          visited by many gurus and rishis, from Agastya to Adi Shankaracharya.
          Mallamma belongs to such great devotees of Lord Shiva. Her tale is a
          spiritual one.
          <br />
          <br />
          Apart from many men who have been Lord Shiva's devotees, numerous
          women have shown their dedication towards worshipping Lord Shiva and
          gained a spiritual start of their journey that would write its own
          history. Among such great women is Hemareddy Mallamma.
          <br />
          <br />
          Just a few miles away from Srisailam is the remote village of
          Ramapuram. Mallamma was born as Lord Shiva's blessing to the couple,
          Nagireddy & Gouramma. The couple, Nagireddy & Gouramma were troubled
          by childless years when they finally decided to worship Lord Shiva on
          Shivaratri in Srisailam. They took a bath in Patalaganga and asked the
          Lord to grant them a wish of a child. As a result, they were blessed
          with a girl child. Since she was a blessing of Lord Shiva, they named
          her Mallamma.
          <br />
          <br />
          Mallamma grew up to be a child who always worshipped Mallikarjuna
          Swamy. After a few years, her father decided to look for a suitable
          groom. In Siddhapuram, he found a reputed family of Hemareddy's and
          married his daughter off to their son, Bharamareddy.
          <br />
          <br />
          Mallamma often performed Archana to Lord Shiva. Her parents always
          considered her a cause for all the luck and prosperity. She proved her
          parents right by bringing prosperity to her mother-in-law's family the
          moment she was married into their family. Her sisters-in-law were not
          happy and were very jealous of her. They all started creating rumours
          about her and complained to Bharamreddy. Bharamareddy was quick to
          misjudge her based on his family's statements and even made plans to
          kill her. This did not deter her from worshipping Lord Shiva. She left
          home and started to live amidst the forest by raising cattle. She
          forgot all her troubles by dedicating her life to worshipping Lord
          Shiva. Her devotion progresses as the years went by. All those who
          turned against her came back to her asking for forgiveness. She
          forgives them but decided to live in the jungle. She went on to
          progress the knowledge about Lord Shiva, visited many pilgrimages of
          Lord Shiva and attained Siddhi at the feet of Lord Shiva. In the
          history of all great Shiva devotees, she stood tall with her unique
          identity.
          <br />
          <br />
          In the exact spot where she raised her cattle, devasthanam has built a
          beautiful temple for her and often perform special pujas and Archana.
          This place is now called Mallamma Kanneeru. Her holy tale shows that
          every woman can earn the complete blessings of Lord Shiva if she puts
          her mind to it. She has become a role model for many women who want to
          dedicate their lives to devotion.
          <br />
          <br />
          The fact that her tale is told in many books, poems and stage plays
          shows the impact she made upon us all. In the sanctum of Mallikarjua
          Swamy temple, devasthanam erected a beautiful sculpture of Mallamma.
          This proves that Lord Shiva doesn't belong to all but is the one who
          keeps his most beloved devotees by his side.
          <br />
          <br />
          So all devotees are requested to abide by the devasthanam regulations,
          worship like Mallamma and earn complete blessings of the Mallikarjuna
          Swamy & Bhramarambika Devi.
          <br />
          <br />
          Sarvam Bhramaramba Sameta Mallikarjuna Charanaravindarpanamastu.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/hemareddymallamma-mandiram%28mallamma-kanneeru%29.jpg",
      seo_tag: {
        title:
          "Mallamma Kanneeru | Hemareddy Mallamma Temple | Mallikarjuna Swamy Temple",
        desc: "Hemareddy Mallamma Temple also known as Mallamma Kanneeru speaks about the magnanimity of Mallikarjuna Swamy towards his devotees who worshipped him with pure soul.",
        keywords:
          "Mallamma Kanneeru, Hemareddy Mallamma Temple, Mallikarjuna Swamy Temple",
        h1_tag: "Mallamma Kanneeru - Srisailam",
      },
    },
    saleswaram: {
      heading: `Saleswaram`,
      description1: <></>,
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/saleswaram.jpeg",
      seo_tag: {
        title: "Saleswaram | Saleshwaram Lingamayya Swamy Temple | Srisailam",
        desc: "Saleswaram Lingamayya temple is a holy spot in the Nallamala forest. Saleswaram makes visitors spellbound with its natural picturesque locales and 200metres high cascade",
        keywords: "Saleswaram, Saleshwaram Lingamayya Swamy Temple, Srisailam",
        h1_tag: "Saleswaram – Srisaila Devasthanam",
      },
    },
    "guptha-mallikarjunam": {
      heading: `Guptha Mallikarjunam`,
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/guptha-mallikarjunam.jpeg",
      seo_tag: {
        title: "Guptha Mallikarjuna Swamy Temple | Srisailam | Lord Shiva",
        desc: "One of the holy shrines of Lord Shiva is the Gupta Mallikarjuna Swamy Temple near Srisailam. It was a shelter for Saivas who lived there and performed rituals to Lord Shiva.",
        keywords: "Guptha Mallikarjuna Swamy Temple, Srisailam, Lord Shiva",
        h1_tag: "Guptha Mallikarjuna Swamy Temple",
      },
      description1: (
        <>
          The sacred Srisailam is ancient and elaborate in its historic
          significance. This holy precinct is home to many sacred temples,
          Ashrams, Mutts, water bodies and picturesque beauty. This is why it is
          believed that Siddhis and the greatest devotees of Lord Shiva travel
          from the Himalayas down to Srisailam to offer their prayers to
          Mallikarjuna Swamy. The compassion of God is in every bit of Srisailam
          in the form of holy water bodies, holy precincts and sacred trees.
          This is because the Lord has come down to reside in Srisailam along
          with all his beloved water bodies, trees and other gods to bless his
          devotees. One of such holy places is Gupta Mallikarjuna Kshetra
          Theertham.
          <br />
          <br />
          Srisailam is surrounded by naturally formed water streams, valleys,
          caves, historical places and nature's beauty that's beyond words.
          These places have become epicentres of earning spiritual knowledge for
          many Maharshis, munis, rishis and spiritual gurus who often meditate
          and practice their spiritual rituals. Long back Srisailam was home to
          many places that were centres for the Kapalika community who practised
          Saivism. One such place often inhabited by Kapalikas is Gupta
          Mallikarjuna Kshetra. The path leading up to this place is very
          difficult. The locals call this area Loddi, which means valley. The
          lord shiva here is called Loddi Mallanna by the locals. His name is
          Gupta Mallikarjuna. This area is between the hills. On one side is a
          deep valley and to another side is a huge hill. The lord has earned
          the name because people usually don’t talk about their limitations but
          boast about their achievements. Similarly, they boast about their good
          deeds and never talk about bad deeds. Loddi means valley that’s
          between two hills. Notably, the area is actually in between one single
          hill. The hills are home to many secret trees that gift ageless beauty
          and great health. Hence, the name Gupta Mallikarjuna or Loddi
          Mallanna. Here’s one popular folklore about the place.
          <br />
          <br />
          After destroying Ravana, Sri Rama established a Lingam in Rameshwaram,
          after which he was consumed with the burden of Braahmahatyadosham.
          Following the advice of Narada, Sri Rama comes down to Srisailam to
          relieve his guilt. Lord Shiva along with Kumaraswamy were frequenting
          the area of Tripurantakakshetra. Lord Shiva in the form of
          Mallikarjuna, when hearing upon Sri Rama’s arrival decided to stay in
          a cave alongside a water body among a hill of Srisailam. The peace at
          this place has consumed Mallikarjuna Swamy. Sri Rama reached the
          highest hill where the lord resides. By the time Sri Rama took the
          blessings of the lord, the lord turned into a Shiva Lingam. The lingam
          merged into the hill of Srisaialam. This lingam is now known as
          Mallikarjunaswamy Lingam. The hill that Sri Rama climbed is
          Sikhareshwaram and the place where the lord secretly resided is known
          as Gupta Mallikarjunam. As time went by, a shiva lingam has been
          placed in the cave. It is not clear when it was established. There are
          five routes to reach this place from Srisailam. Among these are 2
          walkable paths. The first path requires one to take the path behind
          Srisailam Temple towards the panchamatams, crossing the river Krishna,
          reaching Sangarigunda, Bhramaramba lake, via Appapuram. This route is
          approximately 46 km. The second route is a 58 km path starting at the
          south-west point where one must cross the Neeliganga Krishna river,
          visit Kadalivanam via Chukkalaparvatam, then make their way towards
          Akkamahadevi caves, Bhramaramba lake and Appapuram to reach the
          valley.
          <br />
          <br />
          Those who want to reach in their private vehicles can travel 60 km via
          Srisailam-Hyderabad road and travel further 6kms into the hilly road.
          Alternatively, they can reach via Acchampeta. Or, via Acchampeta, they
          can travel via Kondanagula and Banala. This area features few places
          namely, Maisamma, Gurralabanda and a hilly road which are very
          picturesque. A waterfall that falls from 100 meters above into a
          pristine water body, alongside which is a cave surrounded by huge
          hills. This sacred location is delightful for devotees to visit. This
          area seems out of this world which can move us to depths that we’ve
          never experienced before. It is beyond words what one feels after
          taking a bath in the water body post worshipping the lord. Nandi and
          Ganapati can be seen in front of the lord. The locals believe that
          couples who are struggling with years of childlessness, when offer
          their prayers here, the lord himself takes birth in their homes as a
          son.
          <br />
          <br />
          Celebrations take place here on Ashtadashuddi Ekadashi. Approximately
          10,000 devotees and pregnant women take part in the celebrations. So
          all devotees are requested to abide by the Devasthanam regulations,
          maintain the sanctity of the region and offer their prayers at the
          shrine.
          <br />
          <br />
          Sarvam Bhramaramba Sameta Mallikarjuna Charanaravindarpanamastu.
        </>
      ),
    },
    gogharbamu: {
      heading: `Gogharbamu`,
      description1: (
        <>
          One of the mysterious and mystical spot of Srisaila Kshetram is
          Gogarbham. It is located behind Mallamma Kanneeru Temple. Gogarbham is
          neither in a temple architecture or modern construction. It’s a
          peculiar construction made with a pile of stones. The interesting part
          is the construction is made in a shape of Cow’s ear. The devotee has
          to enter into Gogarbham from South- East way and has to exit from
          South - West. Though the entry and exits are right angled, the pathway
          inside Gogarbham is in cow’s ear shape which gives the visitors a
          strange feeling and confusion at the same time.
          <br />
          <br />A small spring named Gokarna Theertham is located nearby
          Gogarbham. It is believed that Gokarna, a sage lived here for a long
          time and thus it is named as Gokarna Theertham, on behalf of his
          memory. People have to believe this, as we all knew that Srisailam is
          a place of sages and saints. Above all, visitors can observe the
          stones around the Gogarbham are affected by high radiation and somehow
          like a melted stone. All these years, no one are able to find the
          actual reason of those intricate stones. So we can consider that,
          Srisailam is a mysterious world with many unknown and untraceable
          things of world. Thus devotees has to experience this strange things
          without missing by just travelling a distance of 2kms from Sri
          Bhramaramba sametha Mallikarjuna Swamy temple.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/gogharbamu.jpeg",
      seo_tag: {
        title: "Gogarbham | Mallikarjuna Swamy Temple | Srisailam Temple",
        desc: "Gogarbham is a stone temple complex where the devotee has to crawl to see God in Gogarbham. It is located 2kms away from Sri Mallikarjuna Swamy Temple at Srisailam.",
        keywords: "Gogarbham, Mallikarjuna Swamy Temple, Srisailam Temple",
        h1_tag: "Gogarbham",
      },
    },
    "kadali-vanam": {
      heading: `Kadali vanam`,
      description1: (
        <>
          The sacred Srisailam is ancient and elaborate in its historic
          significance. This holy precinct is home to many sacred temples,
          Ashrams, Mutts, water bodies and picturesque beauty. This is why it is
          believed that Siddhis and the greatest devotees of Lord Shiva travel
          from the Himalayas down to Srisailam to offer their prayers to
          Mallikarjuna Swamy. The compassion of God is in every bit of Srisailam
          in the form of holy water bodies, holy precincts and sacred trees.
          This is because the Lord has come down to reside in Srisailam along
          with all his beloved water bodies, trees and other gods to bless his
          devotees. One of such holy places is Kadali Vanam. Srisailam is
          surrounded by naturally formed water streams, valleys, caves,
          historical places and nature's beauty that's beyond words. These
          places have become epicentres of earning spiritual knowledge for many
          Maharshis, munis, rishis and spiritual gurus who often meditate and
          practice their spiritual rituals. This is because the Lord has come
          down to reside in Srisailam along with all his beloved water bodies,
          trees and other gods to bless his devotees. One such place is Kadali
          Vanam.
          <br />
          <br />
          Srisailam is filled with naturally formed water bodies, waterfalls,
          caves, valleys and much more. This is why it has been home to
          spiritual practices and meditation spot for many rishis, munis and
          siddhis.
          <br />
          <br />
          Kadali Vanam has been detailed in many ancient scripts. Although
          Srisaila Khandam doesn't have much to say about the place, it is well
          described in Prashyasthama Bahupuranam. Kadali Vanam has a great
          significance in regard to Srisailam. Dattatreya, during his days in
          Srisailam, worshipped the divinity, offered many Tapasyas and attained
          Siddhi in Kadali Vanam according to Guru Charitra.
          <br />
          <br />
          Many poems that mention Srisailam, also mention Kadali Vanam.
          Shivasharanas dating back to the 12th-century talk about Kadali Vanam.
          Also, Shrinatha stated that Srisailam shall attain a greatest glory
          with Kadalivana-Badarivana-Gourivana-Bilvavana when Agastya Maharshi
          along with his wife Lopamudra started their journey towards Srisailam
          after offering their prayers at Kashi. The same poem states that the
          Kadali Vanam has the powers to rid of people's sins.
          <br />
          <br />
          Nagalooty Seshanatha in his Sriparvata Purana states that there are 9
          Kadali Vanas and only Siddhis can discover all 9 of them. This
          suggests that the forests were hard to travel by during ancient times.
          <br />
          <br />
          Also, an inscription dating back to 100 A.D, found on a column near
          caves states that Srigiridevayya performed Tapasya here and
          constructed a flower garden nearby. Srisialam was often visited by
          many Siddhis. Agnipuranam refers to Srisailam as Siddhikshetram.
          Siddhis are people with immense godly knowledge. Siddhis have many
          powers gifted by the gods. When a mere human comes in contact with a
          Siddhi, they are known to educate them, gift wisdom and direct them on
          to the right path in life. There are proofs to the fact that Kadali
          Vanam has witnessed the greatest gurus like Dattatreya and ardent
          Shiva devotees like . This is why Kadali Vanam is known as the land of
          Tapasya. Dattatreya in his afterlife was reborn as Sripadavallabha in
          Peetikapuram near the banks of Godavari and as Nrusimhasaraswati in
          Karimnagar. Gurudutta Saraswateeyam and Narasimhasaraswatyupakyanam
          suggest that Nrusimhasaraswati resided in Karnataka for a while and
          reach Srisalaim in his later life and offered many Tapasyas in Kadali
          Vanam where he is said to have vanished. So, it is known that he ended
          his avatar in Srisailam.
          <br />
          <br />
          Allamaprabhu is one of the greatest devotees of Lord Shiva. His tale
          is an interesting one. He is also known as Prabhudeva. In the 11th
          century, Basaveshwara who invented the religion of Veerashaiva
          established an anubhava mandapam. The leader of the mandapam is
          Allamaprabhu. Allamaprabhu performed many Tapasyas in Kadali Vanam.
          <br />
          <br />
          When one taled the stairway from Srisailam and reach Patalaganga, bus
          transport from here would lead them to Kadali Vanam near Caves. There
          is also a route from the Srisailam project via Kadalivana Revu. One
          can also reach via Neeliganga Revu located Southwest of Srisailam's
          main temple. Alternatively, one can travel 60 km on the
          Srisailam-Hyderabad road, via Appapuram, Bhramaramba Cheruvu and
          travel 40 km from there to reach Kadali Vanam. There are many such
          routes to Kadali Vanam.
          <br />
          <br />
          The way that leads to the forest is mesmerizing. One travels the
          curves of the Krishna river towards the Kadali Vana Revu, then greeted
          by majestic trees with blooming flowers. On the hilly route that leads
          to the forest, Rashtriyabasavadala, Bidar, Gulbarga,
          Srilalitasevasamiti and Medak people have installed a signboard that
          guides the visitors. They deserve special thanks.
          <br />
          <br />
          There is a water stream 11 km away from Kadali Vanam. The stream is in
          its full glory during the rainy season. This is known as Kadali Vana
          Vagu. When one comes down from the water stream, they see a valley.
          Here are the 30 yr old Rasabala Banana trees.
          <br />
          <br />
          The naturally formed stone structures in Kadali Vanam are showcasing
          the magic of nature. A naturally formed stone hall here amazes
          everyone. It is 100 meters tall, 25 meters wide and accommodates 100
          people. It seems that nature has orchestrated stone formation to
          facilitate meditation. Nrusimhasaraswati and are said to have
          performed Tapasyas here. Water droplets often fall here. People have
          erected idols of , Shiva lingam and Nandi here. The idol of
          Nrusimhasaraswati has been recently erected here. The caves and the
          water streams that surround the area capture the minds and hearts of
          visitors.
          <br />
          <br />
          Kadali Vanam is very sacred. So all devotees are requested to abide by
          the Devasthanam regulations, maintain the sanctity of the region and
          offer their prayers here.
          <br />
          <br />
          Sarvam Bhramaramba Sameta Mallikarjuna Charanaravindarpanamastu.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/kadali-vanam.jpeg",
      seo_tag: {
        title: "Kadali Vanam Caves | Srisailam Caves | Srisailam Temple",
        desc: "Kadali Vanam is a literary spot of Srinatha who wrote Kashikhandam. Kadali Vanam has a big rock shelter that looks like a cave and can accommodate 500 people.",
        keywords: "Kadali Vanam Caves, Srisailam Caves, Srisailam Temple",
        h1_tag: "Kadali Vanam Caves",
      },
    },

    "pancha-mathalu": {
      heading: `Pancha Mathalu`,
      description1: (
        <>
          Srisailam which is considered Earth's Kailasa is an ancient holy
          shrine. World's Mother and Father, Lord Shiva and Goddess Parvathi
          with all the powers vested in them start their journey to permanently
          reside in Earth's Kailasa, Srisailam in the form of Sri Mallikarjuna
          Bhramaramba. Many great icons visited Srisailam, namely, Agastya,
          Lopamudra, Vyasa Maharshi, Garga Maharshi, Durvasoma Maharshi,
          Shankaracharyulu, leader of Mantralaya- Raghavendra Swamy, Hema Reddy
          Mallamma, Basaveshwarudu, Siddharamappa, Chakrapani Ranganatha, The
          leader of Kanchikamakoti Peetam-Chandrashekhara and many such rishis,
          siddhis and gurus. It is believed that Siddhis and the greatest
          devotees of Lord Shiva travel from the Himalayas down to Srisailam to
          offer their prayers to Mallikarjuna Swamy. Many mutts serve as the
          centre of knowledge and stand as a remembrance of ancient Gurukul
          Schools.It is believed that there were more than 100 mutts on the
          premises of Srisaila Mahakshetra. Apart from Arasamutam and Ganamatam
          which gained their place inside the temple premises, all other mutts
          are located on the north-west side of the main temple. When one
          classifies these mutts based on religion, they all belong to sects of
          Saivism namely, Kalamukha, Paashupata, Veerashaiva. Based on the
          literature found in the mutts, these can be classified as the
          following:
          <br />
          <br />
          Those belonging to Kalamukha sect: 1.Shilamatam 2.Agastyeishwaramatam
          <br />
          Those belonging to Paashupata sect: 1.Shivalingamatam 2.Brungimatam
          3.Arasamatam
          <br />
          Those belonging to Veerashaiva sect: Ganamatam 2.Bhikshavruttimatam
          3.Nandimatam4.Basavamatam
          <br />
          Other Shaiva matas: 1.Ghantamatam 2.Vibhuthimatam 3.Veerabhadramatam
          4.Saarangadharamatam 5.Rudraakshamatam 6.Bheemashankaramatam.
          <br />
          <br />
          Among all the mutts above, Ghantamatam is the most ancient one. Its
          existence dates prior to the 7th century. In the inscriptions of 1511
          A.D, during the reign of Krishnadevaraya in Srisailam, Sarangamatam,
          Vibhuthimatam and Ghantamatam have been detailed. By 1585 A.D, 30th
          September, in the inscription of Sarangamatam, Veerabhadradevamatam
          has been mentioned. Inscriptions of Rudrakshamatam and
          Bheemashankaramatam are nowhere to be found.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-TheTemple/sd-annapurna-devi.png",
      seo_tag: {
        title: "",
        desc: "",
        keywords: "",
        h1_tag: "",
      },
    },
    "ghanta-matham": {
      heading: `Ghanta Matham`,
      description1: (
        <>
          Ghanta Matha is located about 100 meters north-west of Goddess
          Bhramaramba Devi Temple. Among all the mutts here, Ghantamatam is the
          most ancient one. Ghanta Matham constitutes a group of eight temples
          and has undergone many architectural changes. It is believed that
          Ghanta Siddha mentioned in the Ratnavali play written by Harsha, is
          said to have stayed here. Its existence dates prior to the 7th
          century. Ghantasiddeshwara idol resides inside this mutt. The doorway
          of Veerabhadra temple located south-west to the Ghantamatam is said to
          have inscribed by Ghantasiddeshwara. The Ghantasiddeshwara idol inside
          the mutt must be the Ghantasiddeshwara, disciple of
          Mallikarjunacharya. The main temple in the mutt is facing the west. To
          the north of the temple, towards the north-east is the entrance into
          the main hall of the temple. An inscription on the entrance says that
          the hall and the doorway were constructed on Shravanashuddi Poornima,
          Anandanama year, 1314 A.D.
          <br />
          <br />
          The main temple has the sanctum, Prayer Hall, the main hall and an
          additional hall to the west.
          <br />
          <br />
          The name of the lingam as per ancient records is nowhere to be found.
          It is also not clear which century the lingam belongs to. Ganapati is
          carved on to the doorway of the main hall. North to the additional
          hall is another temple. A Shivalingam resides in this temple that's
          facing south. An inscription on a column here says that the hall was
          constructed on Shravanashuddi Poornima, Anandanama year, 1314 A.D by
          Shivayya who ordered sculptures of Brahma, Vishnu and Lord Shiva here
          in Ghantamatam. But these sculptures are nowhere to be found.
          <br />
          <br />
          To the west of the main temple, facing east are two other temples.
          Among these temples, a lingam receives prayers in the right temple.
          Devotees worship this lingam as Ghantasiddeshwara lingam.
          Mallikarjunacharyas disciple must have placed the lingam here. There
          is no idol in the temple that's on the left. The tall and lean temple
          is idle and to its east is a Gundam. This Gundam is known as Ghanta
          Gundam. Skandapuranam details this Gundam in many chapters.
          Nityanathasiddha in the 13th century in his writing, the
          Rasayanakhandam, mentioned many mysteries and glory of this place as
          follows. Ghantasiddeshwara resides on the left side of Mallinatha god.
          There is a Gundam here and a bell that hangs above. On
          Krishnachaturdashi, i.e, on Masashivaratri, 3 men must arrive here.
          One man must bring water, one must ring the bell, one must offer
          abhishekam to the god. If this ritual is performed for the whole
          night, the three men shall gain the powers of flying.
          <br />
          <br />
          The water in the Gundam never falls short. Even in the hottest of
          summers, the water is abundant. There is another Gundam to the east of
          this Ghantagundam. The water there too never dries up. To the
          north-west of the main temple is another temple. This small temple has
          a hollow space in between with a samadhi to the northern side of it.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/ghanta-matham.jpeg",
      seo_tag: {
        title:
          "Ghanta Matham | Bhramaramba Devi Temple | Srisailam Nearby Places to Visit",
        desc: "Ghanta Matham in Srisailam is a nearby place to visit as it is one of the oldest Mathas of Srisailam. Ghanta Matham is very near to Sri Bhramaramba Devi Temple.",
        keywords:
          "Ghanta Matham, Bhramaramba Devi Temple, Srisailam Nearby Places to Visit",
        h1_tag: "Bhramaramba Devi Temple",
      },
    },

    "vibhuthi-matham": {
      heading: `Vibhuthi Matham`,
      description1: (
        <>
          Among the ancient matas in Srisailakshetram, Vibhuthi matam is one of
          them. This is a huge mutt located 6km away from Ghantamatam and
          Bheemashakaramatam. The southern facing mutt has undergone many
          changes. This huge mutt has 6 columns on the southern side and a hall
          on its right. The four-column walled area in the middle of the mutt is
          2 feet deeper than the rest of the area. Around this area, a hall with
          16 columns is built on a 2 feet higher ground. This mutt is built for
          Gurus who educate their disciples. The Gurupeetam is on to one side.
          <br />
          <br />
          When one enters the mutt, two doorways greet them on either side.
          These doorways once had doors made out of stone. The right doorway
          leads to a long hall which is idle and the left doorway leads to a
          shiva lingam. This lingam is not an old one but seems like someone
          placed it here recently.
          <br />
          <br />
          There is a doorway to the north-west of the mutt. The roof above the
          open space in the middle of the mutt is in ruins. Poornakumbas are
          carved on either side of the doorway. Also on the inner door,
          guardians on either side and a shiva lingam with Nandi on either side
          are carved on the top. This matam is made mostly out of lime. There is
          a hall in ruins and a well located on the south-west part of the mutt.
          <br />
          <br />
          The mutt is built for educational and spiritual activities and
          rituals. In the mutt, on a black rock, Pashupata yantra has been
          carved. In the olden days, devotees used to apply vibhuthi smeared on
          to this yantra on their foreheads. Currently, this yantra is in ruins.
          This mutt is probably more than a thousand year old. Devasthanam has
          decided to revive this mutt to its former beautiful glory. So we
          request devotees to abide by the devasthanam regulations, maintain the
          sanctity of this place while visiting and offering prayers.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/vibhudi-matham.jpeg",
      seo_tag: {
        title:
          "Srisailam Nearby Places to Visit | Vibhuthi Matham | 16 Pillared Matham | Srisailam",
        desc: "Vibhuthi Matham was ruined with time. So Srisailam Temple initiated the reconstruction of Vibhuthi Matham which looks like an ancient one without changing the old plan.",
        keywords:
          "Srisailam Nearby Places to Visit, Vibhuthi Matham, 16 Pillared Matham | Srisailam",
        h1_tag: "Vibhuthi Matham - Srisailam",
      },
    },
    "rudraksha-matham": {
      heading: `Rudraksha Matham`,
      description1: (
        <>
          Among the ancient matas in Srisailakshetram, Rudrakshamatam is one of
          them. The Shivalingam that's receiving prayers must have been
          decorated with Rudraksha(prayer beads). The god who wears rudraksha
          all the time must have resided here. Or maybe as a part of worship,
          rudrakshas must have been distributed here. Or, the mutt premises may
          have been filled with rudraksha trees. One of the reasons above must
          have contributed to the name, Rudrakshamatam.
          <br />
          <br />
          The ancient mutt resembles the Vibhuthi matam. The east-facing mutt is
          100 yards away from Vibhuthi matam and has a 10 feet tall snake hill
          on the southern side of the main hall. Devotees believe that a snake
          that's 11 ft long stays here.
          <br />
          <br />
          When one enters the mutt, towards the west, facing east is a shiva
          lingam. The lingam carved out of beautiful black stone wears
          rudrakshas and matted hair. History suggests that many spiritual
          activities used to take place here. A well is located at the
          north-west side of the mutt.
          <br />
          <br />
          Devasthanam has decided to revive this mutt to its former beautiful
          glory. So we request devotees to abide by the devasthanam regulations,
          maintain the sanctity of this place while visiting and offering
          prayers.
          <br />
          <br />
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/rudraksha-matham.jpeg",
      seo_tag: {
        title:
          "Rudraksha Matham | Holy Mathas of Srisailam | Srisaila Devasthanam",
        desc: "Rudraabhishekam at Srisailam captures the glory of Lord Rudra and his significance. The main ingredients of Rudraabhishekam are honey, milk, flowers, water, gandham etc.",
        keywords:
          "Rudraksha Matham, Holy Mathas of Srisailam, Srisaila Devasthanam",
        h1_tag: "Rudraksha Matham - Srisailam",
      },
    },
    "sarangadhara-matham": {
      heading: `Sarangadhara Matham`,
      description1: (
        <>
          Among the ancient matas in Srisailakshetram, Sarangadharamatam is one
          of them. This mutt is huge facing the east and is believed to have
          gifted siddhi to Sarangasiddha. The ancient inscriptions suggest that
          many siddhis and great devotees of Lord Shiva offered their spiritual
          rituals and worship here to Sarangasiddheshwara. Devasthanam has
          decided to revive this mutt to its former beautiful glory. So we
          request devotees to abide by the devasthanam regulations, maintain the
          sanctity of this place while visiting and offering prayers.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/sarangadhara-matham.jpeg",
      seo_tag: {
        title: "Sarangadhara Matham | Matham in Srisailam | Srisailam Temple",
        desc: "Srisailam is popular for Mathas. Sarangadhara Matham is a peaceful place for Dhyanam.",
        keywords: "Sarangadhara Matham, Matham in Srisailam, Srisailam Temple",
        h1_tag: "Sarangadhara Matham - Srisailam",
      },
    },
    "bheemaSankara-matham": {
      heading: `Bheema Shankara Matham`,
      description1: (
        <>
          Among the five matas in Srisailakshetram, Bheemashankaramatam is one
          of them. Matam that's most close to the main temple apart from
          Ghantamatam is Bheema Shakara matam. This matam doesn’t have the usual
          features that we see in all matams. This is actually a temple since
          the two columns that we never see in mutts is present here. The temple
          has a sanctum, prayer hall and a front-facing hall with 16 columns.
          The temple is facing the east.
          <br />
          <br />
          The temple houses Bheemashankara. A stone is placed here instead of
          the shiva lingam. Many devotees offer prayers to the sacred stone even
          to this day. The south-west column in the front-facing hall has few
          inscriptions carved into it. Srigiri Shikharanivasi Santhata
          Varivasitha Panditaaradhyaha Shivaha Bhakti Charana Sharano Vibhuthi
          Gouru Vibhootimaapnuyaath is one among the inscriptions. Historians
          believe that these inscriptions date back to 1298 A.D. Based on the
          inscriptions, this temple that's called a mutt, maybe from the 1298
          era. The devasthanam has plans to restore the glory of this place by
          reconstructing it into a beautiful temple. So we request devotees to
          abide by the devsthanam regulations, maintain the sanctity of this
          place while visiting and offering prayers.
          <br />
          <br />
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/bheemasankara-matham.jpeg",
      seo_tag: {
        title:
          "Bheema Shankara Matham | Places Near Srisailam | Srisailam Tourism Places",
        desc: "Bheema Shankara Matham is a sub-temple of Sri Mallikarjuna Swamy Temple in Srisailam. It has Pillared Mandapam, Antralayam and Garbhagriha(Sanctum) for ShivaLingam.",
        keywords:
          "Bheema Shankara Matham, Places Near Srisailam, Srisailam Tourism Places",
        h1_tag: "Bheema Shankara Matham - Srisailam",
      },
    },
    "sacred-waters-&-nature": {
      heading: `Sacred waters & nature`,
      description1: <></>,
      image: SD_gallery_06,
      seo_tag: {
        title:
          "Srisailam Nature and Beauty | Srisailam Temple | Srisailam Forest",
        desc: "Srisailam Nature and Beauty is beyond words as Srisailam Temple is between Nallamala hills and dense forest with many wonders and waterfalls around.",
        keywords:
          "Srisailam Nature and Beauty, Srisailam Temple, Srisailam Forest",
        h1_tag: "Srisailam Nature and Beauty",
      },
    },
    "siddha-ramappa-kolanu": {
      heading: `Siddha Ramappa Kolanu`,
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/siddha-ramappa-kolanu.jpeg",
      seo_tag: {
        title:
          "Siddha Ramappa Kolanu | Waterfalls in Srisailam | Srisaila Devasthanam",
        desc: "There are many small waterfalls in and around Srisailam. Siddha Ramappa Kolanu is a cascade that flows uninterrupted with an attractive view of hills.",
        keywords:
          "Siddha Ramappa Kolanu , Waterfalls in Srisailam, Srisaila Devasthanam",
        h1_tag: "Siddha Ramappa Kolanu",
      },
      description1: (
        <>
          "Kotijanmakruteihi Punyeihi Shive Bhakthihi Prajayethe" All the good
          karma of many lives is found at the feet of Lord Shiva for the
          devotees who show faith. Lord Shiva becomes dearer to those who show
          faith and can grant happiness to all the living.
          "Samsaravishadashtanam Jantunaam Avevikaanaam
          Chandrashekarapaadabhajasevanam Paramoushudam" The Holy Srisailam was
          visited by many gurus and rishis, from Agastya to Adi Shankaracharya.
          Siddharamappa belongs to this list of great devotees. His tale is a
          spiritual one.
          <br />
          <br />
          Ancient holy precincts prove Srisailam's historic glory. Among such
          holy places, one is Siddharamappa Kolanu. The holy place where Lord
          Shiva rescued his great devotee Siddharamappa from falling down to the
          lake is named Siddharamappa Kolanu. Siddharamappa hailed from Sholapur
          of Maharastra in the 12th century A.D. Siddharamappa Kolanu
          geographically is a valley. In the valley, a natural and beautiful
          waterfall flows, where if one prays to Lod Shiva, his divine feet
          appear. Until recently there was no proper road leading up to the
          waterfall for the devotees to visit easily. Only during Ugadi,
          Maharashtrians and Kannadians used to visit this waterfall. As a part
          of reviving old temples and holy places, Devasthanam has laid a flight
          of steps that leads to this waterfall. After the steps were laid down
          many devotees visit this holy precinct. Also in the ancient literature
          and scripts, the history of Siddharameshwara has much importance.
          Especially in Maharashtra and Karnataka, the tale of Siddharameshwara
          is told to this day. Kannadavachana written by Siddharameshwara has
          earned much popularity. The book highlights the concepts of Shiva's
          knowledge, Spirituality, Meditation, Devotion and Social welfare.
          <br />
          <br />
          Once upon a time, there was a village named Ponnalapuram in the
          Sholapur region of Maharastra in the 12th century A.D. There lived a
          couple named Maddana Goud and Suggula Devi in the village. Troubled by
          childless years, they visit their Guru, Revanisiddhulavaru. The guru
          offered them coconut and asked them to worship it. The couple
          worshipped them coconut every day. They were blessed with a baby boy
          soon after. Since the boy was born with the blessings of
          Revanisiddhulavaru, they named him Siddharamappa.
          <br />
          <br />
          The boy couldn't speak although he had grown up into a 7-year-old kid.
          Once when the boy was playing along with his friends, an old man
          approached him saying his name was Mallayya, hailing from Srisailam.
          The hungry old man asked the kid if he could offer him some food.
          Strangely, the kid started to speak after seeing the old man. The kid
          ran into his home to bring some bread. By the time he came back to
          offer the bread, the old man was gone. The boy asked everyone in the
          village but no one seemed to notice the old man. The boy noticed a
          group of devotees travelling to Srisailam. In search of Mallayya, the
          boy joined the group with the bread in his hand. After reaching
          Srisailam, the boy asked a devotee who Mallayya was. The devotee told
          the boy that Mallayya was none other than Mallikarjuna Swamy. The boy
          was not convinced because the old man didn't resemble the Lingam he
          saw in the temple. In search of the old man, the boy started searching
          every bit of Srisailam. Siddhappa distraught about not finding the old
          man decided to take his own life by jumping off a valley. Just then
          Mallikarjuna Swamy held the boy's hand and rescued him. Siddhappa
          became one of the greatest devotees of Lord Shiva. Upon the advice of
          Lord Shiva, the boy returned to his village, started propagating the
          significance of Lord Shiva and was known as Siddharamappa.
          Siddharamappa built a beautiful shrine of Lord Shiva in Sholapur and
          attained Sajeeva Samadhi(becoming one with the universe) in the temple
          premise. The valley where the boy tried jumping is known as
          Siddharamappa valley. To the east of Siddharamappa Kolanu, one can
          notice footprints on a rock. The Maharashtrian, Kannadians and great
          devotees of Lord Shiva worship the footprints and consider it a result
          of prayers offered by Siddharamappa to Lord Shiva. The holy
          Krishnaveni river flows in front of it the valley, capturing the
          devotees attention with its picturesque beauty. The nature that
          surrounds the valley gives immense calm and happiness only found by
          true devotees. During ancient times Siddhis used to meet in this place
          to perform sacred rituals. This is why the devotees worship the holy
          precinct with all heart.
          <br />
          <br />
          We request all to visit this place that has immense significance, a
          place which is home to the holy tale of Siddharamappa and earn Lord
          Shiva's blessings.
          <br />
          <br />
          Sarvam Bhramaramba Sameta Mallikarjuna Charanaravindarpanamastu.
        </>
      ),
    },
    "bheemuni-padalu": {
      heading: `Bheemuni padalu`,
      description1: <></>,
      image: SD_gallery_01,
      seo_tag: {
        title: "Bheemuni Padalu | Mallikarjuna Swamy Temple | Srisailam",
        desc: "Bheema padalu located near Mallikarjuna Swamy Temple is proof that Pancha Pandavas visited here. Thus we can say that Srisailam origin can’t be explained.",
        keywords: "Bheemuni Padalu, Mallikarjuna Swamy Temple, Srisailam",
        h1_tag: "Bheemuni Padalu - Srisailam",
      },
    },
    "phaladhara-panchadhara": {
      heading: `Phaladhara Panchadhara`,
      description1: (
        <>
          World's Mother and Father, Lord Shiva and Goddess Parvathi with all
          the powers vested in them decide to reside in, Earth's Kailasa, the
          world's centre, home to Vedas, home to Bindu-Natha form,
          Upasanakendra, Sri Chakram, their new called home, Srisaila Kshetram.
          The only Lord Shiva's Dhaamam that's going to remain before and after
          the Universe is Srisaila Kshetram. Srisailam is that can't be sized by
          history but is for the devotees alone. The god shows his compassion in
          Srisailam in the form of holy places and holy precincts. Srisaila
          Khandam says that Srisailam has millions of holy places. Upon the
          request of Parvat, Lord Shiva and Goddess Parvathi with all the powers
          vested in them start their journey to permanently reside in Earth's
          Kailasa, Srisailam in the form of Sri Mallikarjuna Swamy and
          Bhramaramba and established Srisailam as Jyotirlinga shrine.
          <br />
          <br />
          Yaani Kaani Cha Theerthani Punyaani Bhuvanatraye
          <br />
          Taani Sarvaani Satatam Tishthasthu Mama Maardhani
          <br />
          <br />
          How many ever holy places exist in all the worlds, Let them all flow
          from my head for eternity. Upon the request of Parvat, Lord Shiva and
          Goddess Parvathi with all the powers vested in them start their
          journey to permanently reside in Earth's Kailasa, Srisailam in the
          form of Sri Mallikarjuna Swamy and Bhramaramba along with holy
          Theerthas. Phaladhara-Panchadhara belongs to that list of holy water
          bodies.
          <br />
          <br />
          Phaladhara-Panchadhara are the holy water streams located near
          Hatakeshwaram. People famously call one of the water streams
          Phaladhara but in Sanskrit, its actual name is Kshirinidhara.
          Kshirinidhara was translated in Telugu as Phaladhara. Each stream of
          Panchadhara has its own distinct taste. Gangadevi takes the form of
          Bhogavati among these streams. According to Srisaila Khandam,
          Panchadhara is addressed by many names such as Rudradhara,
          Vishnudhara, Brahmadhara, Chandradhara, Devadhara. Tatreika Rudradhara
          Svaath Vishnudhara Cha Punyadha Brahmadhara Chandradhara Devadhara Cha
          Panchama Yeichabhihi Pachadharabhihi Mahatheertham Ithi Smrutham.
          <br />
          <br />
          These five streams along with Kshiniridhara on the other side are the
          6 holy streams. The streams flow and disappear underground without
          moving forwards, which is a unique feature devotees notice. Many
          ancient books refer to these as Panchapallavadharalu. It is said that
          these 6 streams flow along 6 feet and join the Patalagnaga
          underground. In Parvatapuranam, the significance of bathing in these
          streams is said to be: "Tolagu Duritambu Shubhamulu Tolaginchu
          Hatakeshwara Theerthambunandu Grunki Panchapallavadaradi
          Bhavyatheertha Sevanambuna Nishtardha Siddhalodavu..." suggests a bath
          in Hatakeshwara Theertham and to drink the holy Theertham of 5 streams
          and Kshirinidhara.
          <br />
          <br />
          According to many holy teachers and gurus, if one meditates near these
          streams, it gifts great results. 3kms away from the main temple,
          beside the main road are Phaladhara-Panchadhara. Nature's beauty
          amazes the devotees here and lays the foundation to newfound
          spirituality among them. The supreme Guru, Sri Adi Shankaracharyulu
          admired Srisailam and performed a Tapasya here near
          Phaladhara-Panchadhara according to many holy books. Even the walking
          god on earth, the leader of Kanchipeetham visited Srisailam and
          performed a Tapasya here. Devasthanam has installed a sculpture of Sri
          Adi Shankaracharyulu here and often offer many prayers and rituals.
          Devotees who visit Srisailam never miss visiting these holy streams.
          So all devotees are requested to abide by the devasthanam regulations,
          visit Phaladhara-Panchadhara and earn complete blessings of the
          supreme god.
          <br />
          <br />
          Sarvam Bhramaramba Sameta Mallikarjuna Charanaravindarpanamastu.
          <br />
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/phaladhara-panchadhara.jpeg",
      seo_tag: {
        title:
          "Phaladhara Panchadhara Waterfalls | Waterfalls in Srisailam | Srisailam",
        desc: "A line located 4kms east of Srisailam Temple is Phaladhara Panchadhara. This waterfall was blessed by Adhi Shankaracharya as he penned Sivanandhalahari here.",
        keywords:
          "Phaladhara Panchadhara Waterfalls, Waterfalls in Srisailam, Srisailam",
        h1_tag: "Phaladhara Panchadhara Waterfalls",
      },
    },
    "bheemuni-kolanu": {
      heading: `Bheemuni Kolanu`,
      description1: (
        <>
          World's Mother and Father, Lord Shiva and Goddess Parvathi with all
          the powers vested in them decide to reside in, Earth's Kailasa, the
          world's centre, home to Vedas, home to Bindu-Natha form,
          Upasanakendra, Sri Chakram, their new called home, Srisaila Kshetram.
          The only Lord Shiva's Dhaamam that's going to remain before and after
          the Universe is Srisaila Kshetram. Srisailam is that can't be sized by
          history but is for the devotees alone. The god shows his compassion in
          Srisailam in the form of holy places and holy precincts. Srisaila
          Khandam says that Srisailam has a millions of holy places. Upon the
          request of Parvat, Lord Shiva and Goddess Parvathi with all the powers
          vested in them start their journey to permanently reside in Earth's
          Kailasa, Srisailam in the form of Sri Mallikarjuna Swamy and
          Bhramaramba and established Srisailam as Jyotirlinga shrine.
          <br />
          <br />
          Yaani Kaani Cha Theerthani Punyaani Bhuvanatraye
          <br />
          Taani Sarvaani Satatam Tishthasthu Mama Maardhani
          <br />
          <br />
          How many ever holy places exist in all the worlds, Let them all flow
          from my head for eternity. During ancient times, the devotees who
          would visit Srisailam from western and southern directions used to
          cross this Bheemuni Kolanu. The devotees who would travel from the
          south used to arrive at Siddavatam followed by Nandyala and the
          devotees who would travel from the west would arrive via Alampuram to
          Kurnool, then make their way towards Atmakur. Following a jungle path
          from Atmakur, the devotees would reach Bheemuni Kolani via Nagulooty
          before arriving at Srisailam via Kailasadwaram.
          <br />
          <br />
          Among all the four popular walkable paths that lead the Srisailam,
          Bheemuni Kolanu is the significant one. Kannadians who indulged
          themselves in the customs and spirituality of Srisailam choose this
          path. Here's an interesting tale about Bheemuni Kolanu.
          <br />
          <br />
          During the exile endured by the Pandavas, it is said that they often
          used to visit Bheemuni Kolanu. Draupadi once felt very thirsty and
          couldn't find a waterbody. This angered Bheema who then hit a hill
          with his mace that broke the hill in two, which resulted in splurting
          water flow. Bheema pleased with the water flow, established a Shiva
          Lingam here which is called Bheema Lingam. The area was called Bheema
          Gundam according to ancient scripts. To reach Bheemuni Kolanu one must
          arrive at Hatakeshwaram that is 4km away from Srisailam and make their
          way into the jungle path on the right side for 2kms to reach
          Kailasadwaram. At Kailasadwaram, 850 steps lead their way into a deep
          valley called Bheemuni Kolanu. These steps were said to have been
          built by Reddi Kings, the great devotees of Sri Mallikarjuna Swamy.
          <br />
          <br />
          Kailasadwaram is a significant place for devotees who want to visit
          Bheemuni Kolanu. Among the mighty hills of Srisailam, Kailasadwaram is
          the second highest peak, Srisailasikhareshwaram being the first.
          <br />
          <br />
          Ancient tales tell that Kailasadwaram had an entrance hall with a
          gopuram(pyramidal tower). As time went by these cease to exist, and
          the remains of Kailasadwaram stand as a remembrance. When one walks
          down to the 600th step, to the right is the Bheemeshwara Temple.
          According to historians, this is a very ancient shrine. The time and
          actions of thugs have diminished the temple's former glory.
          Bheemeshwara is the god of this temple. To the right of this temple is
          a stone-faced tank with steps leading to a water body.
          <br />
          <br />
          Similarly, when one walks down to the 300th step from Bheemeshwara
          temple, they would find another ancient shrine called Bheemuni Kolanu
          temple. The lingam in the temple is called Bheema Lingam.
          <br />
          <br />
          The scenic view at the Bheemuni Kolanu is beyond words. Nestled
          between two steep hills, Bheemuni Kolanu is surrounded by nature at
          its best. The view captures the minds and hearts of devotees. The
          hills harbour spectacular waterfalls that run down the hills to reach
          Bheemuni Kolanu. The water from the falls is said to have medicinal
          benefits as it flows among the many medicinal plants on the hills.
          This is why many devotees take bath here. The water here is always
          flowing even during the hottest fo summers. The water from Bheemuni
          Kolanu makes its way to meet with the Krishna River at Neeliganga. A
          devotee has placed a beautiful sculpture of Bheema here as a
          historical remembrance.
          <br />
          <br />
          So all devotees are requested to abide by the Devasthanam regulations,
          maintain the sanctity, preserve the natural beauty of the region
          without disturbing the wildlife while visiting Bheemuni Kolanu.
          <br />
          <br />
          Sarvam Bhramaramba Sameta Mallikarjuna Charanaravindarpanamastu.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/bheemuni-kolanu.jpeg",
      seo_tag: {
        title:
          "Srisailam Waterfalls | Waterfalls in Srisailam | Bheemuni Kolanu",
        desc: "Bheemuni Kolanu was enshrined with Bheema Lingam during the vanavasam of Pandavas in Dwapara Yuga. A stream of water flows through two steep hills named Kolanu.",
        keywords:
          "Srisailam Waterfalls, Waterfalls in Srisailam, Bheemuni Kolanu",
        h1_tag: "Bheemuni Kolanu Waterfalls",
      },
    },
    "bhairava-sila": {
      heading: `Bhairava Sela`,
      description1: (
        <>
          The sacred Srisailam is ancient and elaborate in its historic
          significance. This holy precinct is home to many sacred temples,
          Ashrams, Mutts, water bodies and picturesque beauty. This is why it is
          believed that Siddhis and the greatest devotees of Lord Shiva travel
          from the Himalayas down to Srisailam to offer their prayers to
          Mallikarjuna Swamy. The compassion of God is in every bit of Srisailam
          in the form of holy water bodies, holy precincts and sacred trees.
          This is because the Lord has come down to reside in Srisailam along
          with all his beloved water bodies, trees and other gods to bless his
          devotees. One of such holy places is Bhairava Sela.
          <br />
          <br />
          Srisailam is surrounded by naturally formed water streams, valleys,
          caves, historical places and nature's beauty that's beyond words.
          These places have become epicentres of earning spiritual knowledge for
          many Maharshis, munies, rishis and spiritual gurus who often meditate
          and practice their spiritual rituals. Long back Srisailam was home to
          many places that were centres for the Kapalika community who practised
          Saivism. One such place often inhabited by Kapalikas is Bhairava Sela.
          <br />
          <br />
          Bhairava Sela is located on the south-eastern side, 13km away from the
          main temple in Srisailam. To reach the Sela one must travel 10 km on
          the Srisailam-Dornala route then take a right and walk 1km into the
          forest and cross the Bayanna stream. The path following the stream is
          very narrow. The scary ghat route follows the edge of the hills with a
          view of a deep valley. after walking a kilometre, one reaches a
          cascade across which a cave and a waterbody can be seen. This cave is
          Bhairava Cave also known as Bayanna Cave. With approximately 20 feet
          in length, 10 feet wide and 6 feet in height, this cave can
          accommodate 25-30 people. Bhairava resides in this north-facing cave.
          <br />
          <br />
          Our Vedic culture worships Lord Shiva in the form of a Lingam. But
          idols that represent Lord Shiva in a human form are of five types. 1.
          Shantamurti 2. Anugrahamurti 3. Nrityamurti 4.Samharamurti
          5.Ugramurti. Bhairava belongs to the Ugramurti form. The Bhairava is
          depicted in a fearsome form.
          <br />
          <br />
          Asitagjo Rurushchdnaha Krodachshonmattabhairavaha Kapali
          Bhishanachsheiva Samharachshashta Bhairava. The Roopadyanaratnavali
          states that there are 8 main forms of Bhairava, namely, Asitangudu,
          Ruruvu, Chandabhairava, Krodhudu, Unmattabhairava, Kapali, Bheeshanudu
          and Samharudu. Roopamandanam also states that there are 8 main forms
          of Bhairava and 8 subforms under every 8 main form, 64 Bhairavas in
          total.
          <br />
          <br />
          Bheeroonamabhayaprado Bhavabhayakradnasya Hethusthato Hrudayagni
          Pradhitasya Bheeravaruchamishostakasyantakaha Bheeram Vaaryathi Yaha
          Svayaaginivahasthasvaprabhukhreravo. Lord Shiva is called Bhairava as
          he is the one who creates, rules and destroys this world. Vishvasva
          Bharanaath Ramanaath Vamanaath Shrushti Sthithi Samharakari
          Parashivaha- says Yogini Hrudayam. Troubles, hurdles, distress. The
          one who spawns fear for such disasters is Bhairava. Bibhithe Klesho
          Yasmaadhithi Bhairavaha. Bhairava is super powerful. All Shaktis and
          Siddhis are his followers. Usually, in Shiva temples, the Kala
          Bhairava temple is located in the North-Eastern direction.
          <br />
          <br />
          The four-armed Bhairava is pitch black with three eyes, always
          represented with sparks of flames. He holds in his right hand a
          trident and a skull while his left-hand holds restraint and a drum.
          His vehicle is a dog. It is believed that he is the guardian of the
          temple, the ornaments and possessions of Lord Shiva.
          <br />
          <br />
          In a Sanskrit play Malathimadavam, written by Bhavabhuti, a Kapalika
          kidnaps the heroine Malathi to offer her as a sacrifice in Srisailam.
          The hero, Madhava rescues her from the ill-fate. Also, is believed
          that once a Kapalika caught hold of Aadishankaracharyulu to offer him
          as a sacrifice. Padmapada, his disciple and a devotee of Lord Shiva
          intuitively divined the ill-fate. Through meditation, he assumed the
          form of Lord Narasimha and tore the Kapalika into pieces. The two
          stories shed light on the influence of Kapalikas in Srisailam. The
          sculptures of the Kapalikas can be seen near the Akkamahadevi Cave
          located near Ishtakameshwari temple near Srisailam. In the Ammavaru
          temple at Srisailam Bhairava sculptures can be seen. Since Bhairava
          resides in the cave, the Kapalikas inhibited the caves.
          <br />
          <br />
          The cave houses a beautiful idol of Bhairava made out of red
          quartzite. The four-armed god is holding a knife and a pellet drum in
          his right hand and restraint and a trident in his left, from bottom to
          the top. A beheaded head can also be seen at the bottom of the lower
          left hand and a fearsome woman is seen worshipping him near the lower
          right hand. On the bottom side, to the left, a dog can be seen. The
          god is wearing earrings, a snake necklace and anklets. In Bhairava
          Cave towards the left lower side, Bayanna can be seen. Bayanna has no
          form and is diety of a tribe known as the Chenchulu tribe. Four
          formless rocks are worshipped as Bayanna. Chenchulu, by worshipping
          the formless rocks are stating the fact that God has no form.
          Chenchulu tribe worship this god at the beginning of all auspicious
          works.
          <br />
          <br />
          One is shaken up by the beauty of natural streams that exist in
          Bhairava Sela. The beauty captures the heart of the devotees making
          them feel like they are a part of an unreal world. The scientific
          community says that the waterbodies in Bhairava Sela have pure and
          clean water due to the medicinal plants that surround this area. These
          water streams reach Bheemunikolanu at the merge with Krishna River at
          the Neeligangarevu. It is a historic truth that water streams that
          flow via Bhairava Sela are found nowhere and are incomparable to any
          water stream in the world.
          <br />
          <br />
          We request all to visit Bhairava Sela which is at least 1400 years
          old, worship the deities, enjoy the scenic beauty without causing harm
          to the surroundings and the environment.
          <br />
          <br />
          Sarvam Bhramaramba Sameta Mallikarjuna Charanaravindarpanamastu.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/bhairava-sila.jpeg",
      seo_tag: {
        title:
          "Bhairava Sela | Srisailam Mallikarjuna Swamy Temple | Waterfalls in Srisailam",
        desc: "Bhairava Sela is a sacred spot of the Bhairava cult. Silver cascade and lust green cover of the forest makes it an beguiling place in amidst of east ancient route to Srisailam.",
        keywords:
          "Bhairava Sela, Srisailam Mallikarjuna Swamy Temple, Waterfalls in Srisailam",
        h1_tag: "Bhairava Sela",
      },
    },
    "lingaala-gattu": {
      heading: `Lingaala Gattu`,
      description1: <></>,
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/lingaala-gattu.jpeg",
      seo_tag: {
        title: "",
        desc: "",
        keywords: "",
        h1_tag: "",
      },
    },
    "akkamahadevi-caves": {
      heading: `Akkamahadevi Caves`,
      description1: (
        <>
          Kotijanmakruteihi Punyeihi Shive Bhakthihi Prajayethe" All the good
          karma of many lives is found at the feet of Lord Shiva for the
          devotees who show faith. Lord Shiva becomes dearer to those who show
          faith and can grant happiness to all the living.
          "Samsaravishadashtanam Jantunaam Avevikaanaam
          Chandrashekarapaadabhajasevanam Paramoushudam". Many great icons
          visited Srisailam, namely, Agastya, Lopamudra, Vyasa Maharshi, Garga
          Maharshi, Durvasoma Maharshi, Shankaracharyulu, leader of
          Mantralaya-Raghavendra Swamy, Hema Reddy Mallamma, Basaveshwarudu,
          Siddharamappa, Chakrapani Ranganatha, The leader of Kanchikamakoti
          Peetam-Chandrashekhara, devotees like Manjari, Chandravati and many
          such rishis, siddhis and gurus. Among such great devotees is
          Akkamahadevi. In the main temple of Mallikarjuna Swamy, along with the
          idol of Hemareddi Mallamma, an idol of Akkamahadevi exists, which
          signifies her role as a devotee.
          <br />
          <br />
          This great devotee, Akkamahadevi's tale is one of a kind. Here's her
          tale from the 12th century. She was an ardent devotee of Lord Shiva.
          Just how Goda Devi worshipped Sriranganatha, Meera Bhai worshipped Sri
          Krishna, Akkamahadevi considered Lord Shiva as her universe and
          worshipped him. She was proficient in Sanskrit and Kannada. Her wisdom
          words are popularly known as Shivasharanalu. Pidavarti Somanatha
          translated her sayings in Telugu. He referred to her as Udutadi
          Mahadevi. Udutadi is a place located in Shivamogga Zilla of Karnataka.
          Sumati-Nirmala Shetty, a couple known to be Shiva's devotees are her
          parents. Lingadharana was performed to Akkamahadevi in her childhood
          days. She smeared Vibhuthi and always wore Rudraksha. She considered
          worshipping Mallikarjuna Swamy her most favourite part of the day. The
          ruler of her town, Kashikudu was mesmerised by her beauty and decided
          to marry her. Akkamahadevi rejected his proposal and said that Chinna
          Mallikarjuna Swamy was her universe and she would never marry anyone
          else other than the god himself. The angry king threatened her parents
          after which she agreed to marry him on one condition. He would need to
          leave his Jainism and convert into a devotee of Lord Shiva. She also
          said that he should never touch her when she is offering prayers and
          would need to join her during her prayers. The kind happily agreed to
          her condition. Once the king tried to hug her while she was offering
          her prayers. Since he did not abide by her condition, Akkamahadevi
          threw her clothes at him, covering her body with her long hair.
          Realising his grave mistake, the king bowed to her and she left the
          palace towards Kalayanapattanam. She reached anubhavamandapam where
          she was greeted and accepted by fellow Shiva devotees. She spent most
          of her time here, offering prayers to the lord. She started her
          journey to Srisailam for here as she always longed to stay close to
          god.
          <br />
          <br />
          She spent most of her time in the Akkamahadevi caves and attained
          siddhi in Kadalivanam, her place of many Tapasya. To the east wall of
          the main temple, a carving depicts an inviting hand coming from the
          lingam directed towards Akkamahadevi. This suggests that her tale was
          well known during the Vijayanagara dynasty.
          <br />
          <br />
          The caves witnessed her many Tapasyas and have been home to many
          Siddhis and munis who performed their Tapasya here. To reach the cave,
          one must travel via Patalaganga stairs or from the Srisailam project,
          by crossing the Krishna river. From the Srisailam project, the caves
          are 12 km away. Once one crossed the river, they are greeted by rocks
          that lead to the caves. Alternatively, one can travel via
          Panchamatalu, reach Chukkalaparvatam, then reach Jatararevi via
          Buggarevu to reach the caves. This route is approximately 15 km.
          <br />
          <br />
          There are no words sufficient to describe the beauty of the naturally
          formed caves. One must visit them to witness the glory. The caves have
          two entrances and in front of the cave is a rock that resembles a
          column. The beauty of the caves is indescribable. The entrance on the
          right leads to the end of the caves. The route that follows the
          entrance is 250 meters long. The end of the cave features a naturally
          formed dais that hosts a rock that resembles Shiva Lingam.
          Akkamahadevi worshipped this Shiva Lingam. The entrance on the left is
          150 meters long. The carvings on this route are messmerising. These
          resemble Adishesha and various other mythologically significant
          figures. Places near Srisailam like Vibhuthimatam, Sarangadharamatam,
          Chandraguptapattanam, Akkamahadevi caves, interiors of Kadali vanam
          are considered as dwellings of primitive humans. Many artifacts that
          were used by primitive humans were found here. This shows how ancient
          caves are.
          <br />
          <br />
          There is an idol of Kapalika near the caves. The Kapalikas must have
          stayed here for a while. Kapalikas are a sect of Saivism. Their
          customs and habits revolve around worshipping their idol, Bhairava.
          Srisailam was home to such Kapalikas. A 200 ft high stone wreathe seen
          near the caves is naturally formed and amazes the visitors. The
          formation is 200 ft in height, 16 ft wide and 4ft in breadth. This is
          one of the biggest stone formations in our country. So all devotees
          are requested to abide by the Devasthanam regulations, maintain the
          sanctity of the region and offer their prayers here.
          <br />
          <br />
          Sarvam Bhramaramba Sameta Mallikarjuna Charanaravindarpanamastu.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/akkamahadevi-caves.jpeg",
      seo_tag: {
        title:
          "Akkamahadevi Caves Srisailam | Srisailam Caves | Srisailam Temple",
        desc: "Akkamahadevi was a legendary devotee of Mallikarjuna Swamy who came to Srisailam. She did penance in these caves for many years. Hence, these caves were named after her.",
        keywords:
          "Akkamahadevi Caves Srisailam, Srisailam Caves, Srisailam Temple",
        h1_tag: "Akkamahadevi Caves",
      },
    },
    "bhramaramba-cheruvu": {
      heading: `Bhramaramba Cheruvu`,
      description1: (
        <>
          The sacred Srisailam is ancient and elaborate in its historic
          significance. This holy precinct is home to many sacred temples,
          Ashrams, Mutts, water bodies and picturesque beauty. This is why it is
          believed that Siddhis and the greatest devotees of Lord Shiva travel
          from the Himalayas down to Srisailam to offer their prayers to
          Mallikarjuna Swamy. The compassion of God is in every bit of Srisailam
          in the form of holy water bodies, holy precincts and sacred trees.
          This is because the Lord has come down to reside in Srisailam along
          with all his beloved water bodies, trees and other gods to bless his
          devotees. One of such holy places is Bhramaramba Cheruvu.
          <br />
          <br />
          Srisailam is surrounded by naturally formed water streams, valleys,
          caves, historical places and nature's beauty that's beyond words.
          These places have become epicentres of earning spiritual knowledge for
          many Maharshis, munies, rishis and spiritual gurus who often meditate
          and practice their spiritual rituals. This is because the Lord has
          come down to reside in Srisailam along with all his beloved water
          bodies, trees and other gods to bless his devotees. Srisailam was once
          filled with areas that were inhibited by Kapalikas who practised
          Saivism. One such place is Bhramaramba Cheruvu.
          <br />
          <br />
          This place is a huge pond. An ancient temple is located on the banks
          of the pond. It is notable that the pond is known by the name of the
          Srisailam Goddess, Sri Bhramaramba Devi. During olden times, when
          there was no bus transport to Srisailam, the devotees from Telangana
          used to cross the pond to reach Srisailam. They used to reach
          Appapuram, then proceed towards Akkagavi, Cross the Krishna river near
          the Jatararevu to reach Srisailam. Medimrakulu is an ancient village.
          It is located 9kms away from Bhramaramba Cheruvu. Alternatively,
          devotees used to travel via Appapuram, Bhramaramba Cheruvu,
          Medimrakulu, Sangadigundalu, the cross the Krishna river near
          Neeliganga Revu to reach Srisailam. Sangadigudalu is 12kms away from
          the pond. During ancient times, the kings who travelled by walk to
          reach Srisailam constructed many temples, halls and ordered to dig up
          many ponds. Nagalooty temple, Peddacheruvu, Kailasadwaram were
          constructed during these times. Bhramaramba Cheruvu has been dug
          during these times. The middle of the pond is said to be very deep.
          Based on observable facts, the pond seems to be expanded from a
          naturally formed water body.
          <br />
          <br />
          When one travels 60 km via Srisailam-Hyderabad road, then turn left
          and travel towards the forest route for 9 km and then reach Appapuram
          Chenchugudem. The Bhramaramba Cheruvu is 5kms away from Appapuram. The
          pond can also be reached via a route that starts from the backside of
          Srisailam temple, through Panchamatalu via crossing the Krishna river
          near Neeliganga. This route involves a difficult path that leads one
          through a jungle route via steep hills.
          <br />
          <br />
          The temple that's located near the Bhramaramba Cheruvu is an ancient
          one and its origin is unknown. The temple built out of stone is said
          to have been reconstructed 100-200 years back. The roof of the temple
          is constructed in a style that resembles a stairway. The newly
          constructed roof resembles the age-old one. The gopuram of this temple
          resembles that of the Mallikarjuna Swamy temple so that historians
          believe that this temple belongs to the 13th century. The east-facing
          temple of the god has a sanctum and a prayer hall. Pictures depicting
          guardians and Ganapati are carved on to the doorway. Inside the
          sanctum is a shiva lingam that resembles an ancient shiva lingam that
          used to reside here.
          <br />
          <br />A temple dedicated to the goddess is right beside this temple.
          This temple too is believed to be an ancient one. The locals worship
          this goddess in the name of Bhramaramba Devi. Although the idol
          doesn't look like Bhramaramba Devi, who resides in Srisaila, devotees
          consider the idol as, her. This is why the pond nearby is called
          Bhramaramba Cheruvu. There is another ancient temple at the backside
          of the god's temple which is very ancient. The origin of the temple is
          not clear.
          <br />
          <br />
          The pond is spread over 1100 ft. Nearby, at the height of 10 ft is a
          Katta built with rocks. This has been build prior to Elephant's Katta.
          Nearby is a Veerashila. This receives prayers and Archanas as devotees
          believe it's a representation of Veerabhadra or Bhairava. The rock is
          carved in a way that depicts the god holding a knife and a whip.
          Nearby the temple is Ontiveerannashila. This idol too receives many
          Archanas. So all devotees are requested to abide by the Devasthanam
          regulations, maintain the sanctity of the region and offer their
          prayers here.
          <br />
          <br />
          Sarvam Bhramaramba Sameta Mallikarjuna Charanaravindarpanamastu.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/bhramaramba-cheruvu.jpeg",
      seo_tag: {
        title:
          "Bhramaramba Cheruvu | Srisaila Bhramaramba Cheruvu | Srisaila Devasthanam",
        desc: "Devotees who come from Umamaheswaram to Srisailam can visit Bhramaramba Cheruvu. Bhramaramba Cheruvu is a huge man-made water storage tank in the forest.",
        keywords:
          "Bhramaramba Cheruvu, Srisaila Bhramaramba Cheruvu,Srisaila Devasthanam",
        h1_tag: "Bhramaramba Cheruvu",
      },
    },
    "mallela-theertham": {
      heading: `Mallela Theertham`,
      description1: (
        <>
          The sacred Srisailam is ancient and elaborate in its historic
          significance. This holy precinct is home to many sacred temples,
          Ashrams, Mutts, water bodies and picturesque beauty. This is why it is
          believed that Siddhis and the greatest devotees of Lord Shiva travel
          from the Himalayas down to Srisailam to offer their prayers to
          Mallikarjuna Swamy. The compassion of God is in every bit of Srisailam
          in the form of holy water bodies, holy precincts and sacred trees.
          This is because the Lord has come down to reside in Srisailam along
          with all his beloved water bodies, trees and other gods to bless his
          devotees. One of such holy places is Mallela Theertham.
          <br />
          <br />
          Srisailam is surrounded by naturally formed water streams, valleys,
          caves, historical places and nature's beauty that's beyond words.
          These places have become epicentres of earning spiritual knowledge for
          many Maharshis, munies, rishis and spiritual gurus who often meditate
          and practice their spiritual rituals. This is because the Lord has
          come down to reside in Srisailam along with all his beloved water
          bodies, trees and other gods to bless his devotees. One such place is
          Mallela Theertham. Here's one folklore popular among the locals.
          <br />
          <br />
          There was a place called Chandragupta on the other side of the Krishna
          river near Srisailam. The place was ruled by a king named
          Chandragupta. Chandravati was his daughter. She was raised with utmost
          love and care. Chandravati was an ardent devotee of Lord Shiva since
          her childhood. She used to offer her prayers every day. She also used
          to offer Archanas to a shiva lingam located in a valley nearby. White
          jasmine flowers along with the water flow rushed by a nearby waterfall
          would float on top of the water resembling a cover of snow. Since the
          water body had lakhs of white jasmine flowers floating atop, the place
          was called Mallika Theertham or Mallela Theertham. With her parent's
          permission, Chandravati used to come down to Srisailam to worship
          Mallikarjuna Swamy. The Chanchulu tribe used to adore her. The tribals
          built a small cottage for her and often used to bring her fresh honey
          and fruits from the forest. They used to treat her like their own
          daughter. The god pleased by her devotion granted her a wish. She
          wished the lord to always wear the Jasmine garland made by her. Since
          then, the god has gained the name, Mallikarjuna Swamy. This story
          features in the 2nd chapter of Chandravatyupakhyanam of Srisaila
          Khandam, a part of Skanda Puranam.
          <br />
          <br />
          Mallika Theertham is very sacred. The devotees believe that all their
          sins are rid when they visit this place. The locals believe that those
          who take bath here are rid of all illnesses and are granted great
          health.
          <br />
          <br />
          Mallika Theertham is at a distance of 54 km from Srisailam. One needs
          to travel down the Srisailam-Hyderabad road and reach Vatuvarlapalli,
          turn right into Sarlapalli via Kudichintalabailu and travel for 10 km
          from here to reach the Theertham. People have established a Ganapati
          idol at the start of the walkable path that leads to the Theertham.
          They believe that worshipping Ganapati before starting their journey
          will rid them of all hurdles.
          <br />
          <br />
          When devotees make their way through this path into the valley, they
          are greeted by a beautiful waterfall that falls from 120-130 ft. The
          water from the fall flows a distance and forms a water body. This
          water body is the Mallika Theertham. The water here is very pure and
          stays very cool even during summers. The locals believe that the water
          body is very deep and so, the devotees must be very cautious while
          taking a bath here. The water from the Theertham flows and forms a
          small waterfall at a distance and the place where the water collects
          below is called Mallika Mudugu.
          <br />
          <br />
          When devotees make their way through this path into the valley, they
          are greeted by a beautiful waterfall that falls from 120-130 ft. The
          water from the fall flows a distance and forms a water body. This
          water body is the Mallika Theertham. The water here is very pure and
          stays very cool even during summers. The locals believe that the water
          body is very deep and so, the devotees must be very cautious while
          taking a bath here. The water from the Theertham flows and forms a
          small waterfall at a distance and the place where the water collects
          below is called Mallika Mudugu.
          <br />
          <br />
          There is a shiva temple near the Mallika waterfall. The lingam here is
          known as Mallishwara Lingam. The origin of the lingam is unknown and
          is said to have been receiving prayers for centuries. This lingam is
          always touched by the cool waters from the natural waterfalls here.
          The devotees have established Ganapathi, Dattatreya and Shakti idols
          here. A snake hill can be seen here. Just nearby the hill, people have
          established an idol of naga and shiva lingam. This area is surrounded
          by mango trees. Trees as old as 200-300 yr old can be seen here. The
          Theertham is very sacred and those who disrespect the area by any
          means are said to have been followed and troubled by honey bees which
          can prove impossible to escape from. Celebrations take place here on
          Ashtadashuddi Ekadashi. Numerous devotees take part in the
          celebrations. So all devotees are requested to abide by the
          Devasthanam regulations, maintain the sanctity of the region and offer
          their prayers here.
          <br />
          <br />
          Sarvam Bhramaramba Sameta Mallikarjuna Charanaravindarpanamastu.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/mallela-theertham.jpeg",
      seo_tag: {
        title:
          "Mallela Theertham | Mallela Theertham Waterfalls | Waterfalls Near Srisailam",
        desc: "Mallela Theertham is a silver cascade falling from 150 feet hill. This small stream of water later joins the Krishna River near Srisailam.",
        keywords:
          "Mallela Theertham, Mallela Theertham Waterfalls, Waterfalls Near Srisailam",
        h1_tag: "Mallela Theertham",
      },
    },
    "srisailam-dam": {
      heading: `Srisailam Dam`,
      description1: (
        <>
          The second biggest dam of Andra Pradesh and second largest capacity
          working station in India is Neelam Sanjeeva Reddy project, also called
          as Srisailam Dam. The dam was constructed on a small ravine of River
          Krishna stream that flows in between Nallamala hills. These hills are
          located in Kurnool district of Andhra Pradesh and Mahabub Nagar
          district of Telangana. Srisailam dam is an hydroelectric dam which is
          also provides water supply for irrigation of surrounding agricultural
          lands. It was constructed 980feet (300metres) above sea level with
          1680 feet long and 476 feet of height.
          <br />
          <br />
          Srisailam dam construction was initially started in 1960 and was
          completed in 1980. It was inaugurated on 26th of July, 1980. It was
          initially started as a small project, which later turned into multi
          utility project with irrigation and hydroelectricity usage. It’s a
          huge dam with twelve radial crest gates. At present, the right bank
          power station has 7 Francis turbines producing 110 MW each, whereas
          left bank power station has 6 Francis turbines with a production
          capacity of 150 MW each. On a whole, the dam is producing 1670 MW.
          Srisailam dam has water reservoir capacity of 178.74 Tmcft.
          <br />
          <br />
          It’s a great gift to our region, that a dam with multipurpose usage is
          in between the aesthetic nature of Nallamala hills and across the
          sacred Krishna river. People can visit dam at day time for watching
          the beauty of the great construction. At evening times, the head of
          dam is arranged with colourful lights which mesmerises the viewers.
          Its just 15 kms near to Srisailam and 203kms away from Hyderabad.
          Visitors and pilgrims can enjoy the scenic beauty of Krishna river and
          back waters flowing across the green covered hills of Nallamala
          forest.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/srisailam-dam.jpg",
      seo_tag: {
        title:
          "Srisailam Reservoir | Srisailam Dam Water Level | Srisailam Dam",
        desc: "Srisailam Reservoir was constructed with the water level of 269.22 metres. Srisailam Dam left bank has a 6*150 MW powerhouse and the right bank has a 7*110 MW powerhouse.",
        keywords:
          "Srisailam Reservoir, Srisailam Dam Water Level, Srisailam Dam",
        h1_tag: "Srisailam Dam",
      },
    },

    "octopus-view": {
      heading: `Octopus View`,
      description1: (
        <>
          The Octopus View Point is Lord Maha Shiva’s extravaganza for nature
          lovers. It is located very near to Domalapenta Forest Check post. The
          view is enriched with verdure of Nallamala forest covered on Nallamala
          hills with backwaters of River Krishna, touching the foot of the
          hills. The River Krishna backwaters spread its streams between the
          hillocks, thus providing a strange appearance similar to an Octopus.
          Visitors can experience a bird’s eye view of the adjoining hilly
          forests and the Krishna River from the Octopus View Point. To get the
          forest live experience, visitors choose safari organised by the
          reserve that starts from Farahabad cross roads. Visitors of Srisailam
          from Hyderabad, usually choose to visit Farahabad view point which is
          25kms far from Octopus View Point. It is 42 kms away from Mannanur.
          <br />
          <br />
          Usually the ambiance of Octopus View Point appears in monsoon, autumn
          and early spring times. The lush green image of Octopus View Point
          disappears your blues with blue sky picturesque view and backwaters of
          the River Krishna. So just plan to visit octopus Viewpoint from July
          to January months. People who travel with private vehicle can enter
          viewpoint directly with vehicles. The people who come by public
          transport have to drop down at Dhomalapenta and have to travel 6kms on
          road by any private transport to reach Octopus View Point. There is
          ticket price of Rs.10/- collected by forest department for the
          maintenance of viewpoint. As visitors enters the forest, everyone has
          to be responsible for the cleanliness of nature and forest.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/octopus-view.jpg",
      seo_tag: {
        title:
          "Octopus Viewpoint Srisailam | Octopus viewpoint | Srisailam Devasthanam",
        desc: "Octopus Viewpoint got its name as Krishna river water splits between hills to look like an Octopus. Srisaila Devasthanam is 31kms away from Octopus Viewpoint.",
        keywords:
          "Octopus Viewpoint Srisailam, Octopus viewpoint, Srisailam Devasthanam",
        h1_tag: "Octopus Viewpoint Srisailam",
      },
    },
    "nature-and-beauty": {
      heading: `Nature and Beauty`,
      description1: (
        <>
          Srisailam is in the deep forests of Nallamala, which is located in
          both Andra Pradesh and Telangana states. Nallamala forest is the most
          tranquillising place in Andra Pradesh by enfolding aesthetic nature
          and green cover. Nallamala hills has many deep valleys, water streams,
          small springs that makes pilgrims of Srisailam and other visitors’
          souls calm and peaceful. After entering to the premises of Nallamala
          forest from any direction, a traveller will become an enthusiastic kid
          as it seems like the nature is just holding him/her, like a mother
          holding her kid. The gushing River Krishna’s beauty cannot be compared
          with any other river as the flow of river touches so many small tribal
          regions, streaming through deep valleys, splits to many sub streams
          and then finally joins to sea.
          <br />
          <br />
          On all the way to Srisailam, people experiences many things such as
          wild animals crossing the road, monkeys playing on trees with their
          babies, tiger reserve area, forest safaris, breathe taking scenic
          beauty. Few visitors take a small nap beside the ghat roads, making
          themselves relaxed in the hilly forest region, bewildered landscapes
          of plateaus with echoing valleys. To visit some holy spots around
          Srisailam, pilgrims has to make trekking on this wild paradise of
          Nallamala hills. The best time to visit the tiger reserve of Nallamala
          forest is November to June as it will be risky get into reserve on the
          hills of Nallamala in rainy season.
          <br />
          <br />
          On the top of all these, the wonderful waterfalls of Krishna river sub
          streams gives the best excitement to visitors. The temperatures of
          water varies from one waterfall to other. Mallela Teertham, Bhairava
          Sela, Loddhi Mallanna are some of waterfalls around Srisailam. To
          undergo all these enchanting elements of nature, one must plan for
          15days. The pristine air of forest lucks our hearts to stay for a
          while.
        </>
      ),
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-VisitingPlaces/nature-and-beauty.jpg",
      seo_tag: {
        title:
          "Srisailam Nature and Beauty | Srisailam Temple | Srisailam Forest",
        desc: "Srisailam Nature and Beauty is beyond words as Srisailam Temple is between Nallamala hills and dense forest with many wonders and waterfalls around.",
        keywords:
          "Srisailam Nature and Beauty, Srisailam Temple, Srisailam Forest",
        h1_tag: "Srisailam Nature and Beauty",
      },
    },
    trusts: {
      heading: `Trusts`,
      description1: <></>,
      image:
        "https://sbmsd.fra1.cdn.digitaloceanspaces.com/sd-web/SD-TheTemple/sd-annapurna-devi.png",
      seo_tag: {
        title:
          "Srisailam Devasthanam Trust | Srisailam Trust | Srisailam Devasthanam",
        desc: "Srisaila Devasthanam Trust comes under Srisaila Devasthanam to collect funds for preserving the ancient shrine. Contribute to Srisailam Trust.",
        keywords:
          "Srisailam Devasthanam Trust, Srisailam Trust, Srisailam Devasthanam",
        h1_tag: "Srisailam Devasthanam Trust",
      },
    },
    "sri-bhramaramba-mallikarjuna-swamyvarla-devasthanam": {
      heading: `Sri Bhramaramba Mallikarjuna Swamyvarla Devasthanam`,
      description1: (
        <>
          The Holy shrine which holds a great significance from Satyayuga to
          Kaliyuga is Srisailam. Its a great privilege for devotees to see both
          holy shrines of Lord Shiva and Parvathi Devi within a bailey of
          temple. This is an eternal place with so many wonders and mystics. To
          preserve the grandeur of this sacred place, Devasthanam is accepting
          donations through Sri Bhramaramba Mallikarjuna Swamy Devasthanam
          Trust. Devotees can donate to Sri Bhramaramba Mallikarjuna Swamy
          Devasthanam Trust online and earn blessings from Sri Bhramaramba
          Sametha Mallikarjuna Swamy.
        </>
      ),
      image: SD_gallery_03,
      seo_tag: {
        title:
          "Srisailam Devasthanam | Sri Bhramaramba MallikarjunaSwamy Varla Devasthanam",
        desc: "Sri Bhramaramba Mallikarjuna Swamy Varla Devasthanam Trust is a nonprofitable organisation. People make donations to SBMSD Trust on Srisaila Devasthanam Online.",
        keywords:
          "Srisailam Devasthanam, Sri Bhramaramba MallikarjunaSwamy Varla Devasthanam",
        h1_tag: "Sri Bhramaramba MallikarjunaSwamy Varla Devasthanam",
      },
    },
    "anna-prasadam-trust": {
      heading: `AnnaPrasadam Trust`,
      description1: (
        <>
          “Gaja thuraga sahasram gokula koti dhaanam Kanaka rajatha pathram
          methini saagarantham Upaya kula vishuttham koti kanyapradhaanam
          Nabhavathu samathulyam annadaana samaanaha”
          <br />
          <br />
          One Annadanam is greater than donating a thousand elephants, one crore
          cows, gold, land till the sea, contributing services of the whole
          dynasty, succouring brides in need during the marriage. The sacred
          place where Jyothirlingam and Sakthi Peetam reside within a temple
          bailey roof is one and only Srisailam. In this most sacred place,
          devotees have the privilege to donate for AnnaPrasadanam which is held
          at Annadaana Satram daily.
        </>
      ),
      image: SD_gallery_04,
      seo_tag: {
        title:
          "Srisailam Anna Prasadam Trust | Srisailam Online | Srisailam Temple",
        desc: "Srisailam Annaprasadam Trust helps the needy. One can donate to Annaprasadam trust online via Srisaila Devasthanam website.",
        keywords:
          "Srisailam Anna Prasadam Trust, Srisailam Online, Srisailam Temple ",
        h1_tag: "Srisailam Anna Prasadam Trust",
      },
    },
    "aagama-patasala-trust": {
      heading: `Aagama Patasala Trust`,
      description1: (
        <>
          Though a tree has grown with many branches, leaves, flowers, and
          fruits, yet the foundation of that tree is none other than its roots.
          Similarly, we could become progressive in the modern world, yet our
          roots of education and development are the Vedas. Srisailam, the abode
          of sacred Vedas has Aagama Patasala which is operated under the
          supervision of Srisaila Devasthanam. Aagama Patasala offers free
          education of Aaagamas. The culture and procedures of Pooja and Homam
          rituals are being taught in Aaagamas. Srisaila Devasthanam accepts
          donations to upkeep Aagama Patasala through Aagama Patasala Trust.
          People can donate online to Aagama Patasala Trust and become divine
          donors of education.
        </>
      ),
      image: SD_gallery_05,
      seo_tag: {
        title:
          "Aagama Patasala trust in srisailam | Srisailam Temple | Srisailam Online",
        desc: "Aagama Patasala Trust in Srisailam is a teaching centre.  Srisailam temple accepts online donations for the Patasala.",
        keywords:
          "Aagama Patashala, Aagama Patasala trust in srisailam, Srisailam Temple, Srisailam Online",
        h1_tag: "Aagama Patasala trust",
      },
    },
    "go-samrakshana-trust": {
      heading: `Go Samrakshana Trust`,
      description1: (
        <>
          “Sarva deva sthitha dhehe, Sarva devamihi gavo” Vedas suggest that all
          gods reside in every fraction of a cow. So it is believed that a cow
          has the sanctity of all the gods present in the whole universe. Now
          it’s time to save and serve the holy cow. For this purpose, Srisaila
          Devasthanam operates a huge Gosala with more than 1500 cows. To save
          and serve cows, you can donate to the GoSamrakshana trust operated
          under the Srisaila Devasthanam via both online and offline.
        </>
      ),
      image: SD_gallery_08,
      seo_tag: {
        title:
          "Go Samrakshana Trust | Go Samrakshana Trust in Srisailam | Srisailam",
        desc: "GoSamrakshana Trust in Srisailam takes care of more than 1500 cows. One may donate money or cows via  GoSamrakshana Trust in Srisailam.",
        keywords:
          "GoSamrakshana Trust, Go Samrakshana Trust in Srisailam, Srisailam, Go Samrakshana",
        h1_tag: "Go Samrakshana Trust",
      },
    },
    "pranadana-trust": {
      heading: `Pranadana Trust`,
      description1: (
        <>
          Srisailam is sanctified with Sri Mallikarjuna Swamy in Jyothirlinga
          form and Sri Bhramaramba Devi as Sakthi Rupam. Srisaila Devasthanam
          operates a Health Care Centre for the sake of devotees who visit
          Srisailam. It provides first aid and basic treatment for common
          illnesses like fever and minor infections. Devotees and generous
          people can donate to this Health Care Centre through Pranadana Trust.
          The online donation facility is available via internet banking and the
          UPI facility.
        </>
      ),
      image: SD_gallery_07,
      seo_tag: {
        title: "Pranadana trust | Srisailam Pranadana Trust | Srisailam Online",
        desc: "Srisailam Pranadana Trust funds a medical care centre near Srisailam Temple. Donate to Pranadana Trust online.",
        keywords:
          "Pranadana trust, Srisailam Pranadana Trust, Srisaila Devasthanam ",
        h1_tag: "Pranadana trust",
      },
    },
    "swachh-srisailam": {
      heading: `Swachh Srisailam`,
      description1: (
        <>
          Lord always admires the shower of pure-hearted prayers from his
          devotees. To pray wholeheartedly, many devotees travel all the way to
          Srisailam. To facilitate hearty prayers, it’s our responsibility to
          keep Srisailam and its premises clean. Srisaila Devasthanam has
          arranged Swachh Srisailam Trust to maintain Srisailam clean. Devotees
          can donate to Swachh Srisailam Trust to become part of conserving
          beautiful nature and maintaining the cleanliness of Srisailam.
        </>
      ),
      image: SD_gallery_06,
      seo_tag: {
        title: "Swachh Srisailam Online | Swachh Srisailam | Srisailam Online",
        desc: "Swachh Srisailam is being organised by Srisaila Devasthanam for maintaining the temple and Srisailam premises clean. Devasthanam accepts funds for Swachh Srisailam.",
        keywords: "Swachh Srisailam Online, Swachh Srisailam, Srisailam Online",
        h1_tag: "Swachh Srisailam Online",
      },
    },
    "do's-&-don'ts": {
      heading: `Do's & Don'ts`,
      description1: (
        <>
          Do’s in Srisaila Devasthanam: <br />
          -Maintain quietness, inside the Sanctum <br />
          -Chant Panchakshari Mantram Om Namah Sivaya in the queue <br />
          -Keep your centre of attention on Lord Shiva and Parvathi Devi to
          attain peace of mind in the temple <br />
          -Leave your footwear outside temple <br />
          -Dispose off wastes in the recycle bins provided
          <br />
          -Vehicles must be parked at designated parking areas only <br />
          -Deposit your offerings only in the Hundi <br />
          -Keep Vibhuthi on forehead <br />
          -Follow the procedures of the temple and wait for your turn for the
          Darshanam of the God and Goddess <br />
          <br />
          Don’ts in Srisaila Devasthanam: <br />
          -Don’t Wear footwear, hats, sunglasses, caps and turbans inside the
          temple
          <br />
          -Taking photos and videos in the temple is prohibited <br />
          -Don’t bring electronics and gadgets inside the temple <br />
          -Don’t waste Prasadam given by Devasthanam <br />
          -Smoking / Alcohol / Drugs are completely banned in Srisailam <br />
          -Don't eat non-vegetarian food in Srisailam <br />
          -Do not approach touts for any services of Devasthanam like Darshanam,
          Prasadam and Accommodation <br />
          -Do not encourage beggars at Srisailam <br />
          -Don’t spit or create nuisance in the premises of the temple <br />
          -Don’t throw any plastic bags or waste at side of roads while
          travelling in forest of Srisailam
          <br />
          -Don’t feed monkeys in forest
        </>
      ),
      image: SD_gallery_05,
      seo_tag: {
        title: "Do's and Don'ts in Srisailam Temple | Srisailam Devasthanam",
        desc: "Srisaila Devasthanam is a sacred place of Lord Shiva and Parvathi Devi. Do’s and Don’ts in Srisailam Temple must be followed by devotees in this divine spot.",
        keywords: "Do's and Don'ts in Srisailam Temple, Srisailam Devasthanam",
        h1_tag: "Do's and Don'ts in Srisailam Temple",
      },
    },
    "intellectual-property": {
      heading: `Intellectual Property`,
      description1: (
        <>
          The materials on this site and any other World Wide Web site/Mobile
          Application owned, operated, licensed or controlled by Srisaila
          Devasthanam or any of its related, affiliated or subsidiary companies,
          may not be copied, distributed, republished, uploaded, posted, linked
          or transmitted in any way without the prior written consent of
          Srisaila devasthanam. <br />
          All material(Logos, trademarks, GI, etc) are protected under
          copyright, trademark and other intellectual property laws.
          Modification or use of the materials for any other purpose than
          personal/non-commercial use violates Srisaila Devasthanam’s
          intellectual property rights.
        </>
      ),
      image: SD_gallery_04,
      seo_tag: {
        title:
          "Srisailam Temple Logo | Srisailam Temple Trademark | Srisailam Online",
        desc: "Srisailam Temple Logo is completely Trademarked and any misuse will invite legal action on the accused person. Srisailam Online registered Srisailam Temple Trademarks.",
        keywords:
          "Srisailam Temple Logo, Srisailam Temple Trademark, Srisailam Online",
        h1_tag: "Srisailam Temple Logo",
      },
    },
    certifications: {
      heading: `Certifications`,
      description1: (
        <>
          Srisaila Devasthanam complies in accordance with aagamas or Yogic way
          of providing spiritual assistance. The quality services, environmental
          friendly affairs, security and strict adherence to rituals qualify for
          certifications
        </>
      ),
      image: SD_gallery_03,
      seo_tag: {
        title: "Srisailam Devasthanam ISO certificate | Srisailam Devasthanam",
        desc: "Srisaila Devasthanam is operated by the Endowment Department. The government of Andhra Pradesh issued Srisaila Devasthanam ISO certificate under many ISO standards.",
        keywords:
          "Srisailam Devasthanam ISO certificate, Srisailam Devasthanam",
        h1_tag: "Srisailam Devasthanam ISO certificate",
      },
    },
    "12-jyotirlinga": {
      heading: `12 Jyotirlinga`,
      description1: (
        <>
          Maha Dev or Maha Shiva , the eternal source of cosmic energy for
          entire universe split up himself into three Gods named as Lord Brahma
          for creation, Lord Vishnu for existence and Lord Shiva for
          destruction. Lord Shiva later reformed into Lingam, said in Shiva
          Puranam. In Sanskrit, the meaning of Lingam is a Distinguish symbol
          which is oval- shaped that looks similar to universe. People believe
          that Lingam is representation of generative power and energy source
          for the existence of universe.
          <br />
          <br />
          According to Shiva Purana, formation of Lingam ( Lingavirbhavam ) took
          place by the incident happened between Lord Vishnu and Lord Brahma.
          Thousands of years ago, Lord Vishnu and Lord Brahma had a discussion
          about the superiority between themselves. Then Lord Shiva thought to
          teach them a lesson and reformed himself as a infinite pillar
          structure. Lord Shiva told Vishnu and Brahma to find the end of his
          reformation and the one who did first, will be considered as the
          superior. Thus Lord Vishnu started to dig down to find the deep end of
          infinite pillar. Lord Brahma started flying up on his Peacock to find
          top end of pillar. After years of travelling down, Lord Vishnu
          realised that he made mistake of himself feeling superior and came
          back to Lord Shiva for apology. Lord Shiva accepted Vishnu’s apology
          and forgiven him. But Lord Brahma lied in front of Shiva that he found
          the end of pillar. Thus Lord Shiva outraged and cursed Lord Brahma
          that he won’t receive any prayers from the people of earth. Out of
          Lord Shiva’s rage, a radiance generated. The lustrous rays impacted 64
          places on the Earth. Thus 64 lingas were formed. Amongst 64 lingas, 12
          are known as most sacred Jyothirlingas. These Jyothirlingas have some
          specific characteristics and people believe that Lord Shiva resides in
          these Lingas and blesses them <br />
          <br />
          <span style={{textAlign: "center"}}>
            Here is the list of 12 Jyothirlingas, also knows as Dwadasa
            Jyothirlingas.
            <br />
            <br />
            Somnath Jyothirlinga in Somnath, Gujarat
            <br />
            Mallikarjuna in Srisailam, Andhra Pradesh
            <br />
            Mahakaleshwara in Ujjain, Madhya Pradesh
            <br />
            Omkareshwara in Khandwa, Madhya Pradesh
            <br />
            Kedarnath in Rudraprayag, Uttarakhand
            <br />
            Bhimashankar in Pune, Maharashtra
            <br />
            Vishveshwara in Varanasi, Uttar Pradesh
            <br />
            Trayambakeshwara in Trayambakam, Maharashtra
            <br />
            Vaidyanath in Deodhar, Jharkhand
            <br />
            Nageshwara in Dwaraka, Gujarat
            <br />
            Rameshwara in Rameswaram, Tamil Nadu
            <br />
            Ghrishneshwara in Aurangabad, Maharashtra
          </span>
        </>
      ),
      image: SD_gallery_02,
      seo_tag: {
        title:
          "12 Jyotirlinga | About 12 Jyotirlinga | Mallikarjuna Jyotirlinga | Jyotirlinga",
        desc: "Sri Mallikarjuna Jyotirlinga is considered as the second of 12 Jyotirlingas. About 12 Jyotirlingas, Romaharshana described in a book named Shiva Puranam.",
        keywords:
          "12 Jyotirlinga, About 12 Jyotirlinga, Mallikarjuna Jyotirlinga, Jyotirlinga",
        h1_tag: "",
      },
    },
    "18-shakti-peethas": {
      heading: `18 Shakti Peethas`,
      description1: (
        <>
          According to Hinduism, Sakthi is considered as Source of Power and
          Energy. Sakthi is primordial goddess, later split up as three
          goddesses named Parvathi, Lakshmi and Saraswathi. Amongst three
          goddesses, Parvathi plays key role of Sakthi. Goddess Parvathi has
          many forms such as Durga (Goddess of Strength), Mahakali (Goddess of
          destruction and courage), Annapurna (Goddess of Food to World) etc. In
          Hinduism, Sakthism has more significance and considered as major
          source of energy to other cults. In Sanskrit, the word Sakthi means
          ‘Power’. As Sakthi is primordial energy, it is the source of creation,
          existence and destruction.
          <br />
          <br />
          Coming to Sakthi Peethas, there are 51 Sakthi Peethas all over the
          world and among them, 18 Peethas are contemplated as Maha Sakthi
          Peethas. The origin of Sakthi Peethas is Satya Yuga. Daksha, the son
          of Brahma has 27 daughters. Amongst 27 daughters, Sathi (a form of
          Parvathi) fell in love with Lord Shiva and married him against wishes
          of Daksha. Few years later, Daksha carried out fire ritual named
          Daksha Yagna. Daksha invited all the God and the Goddesses except Lord
          Shiva and Sathi. He felt himself was seeking revenge on Lord Shiva for
          marrying his daughter. Though Daksha didn’t invite, Sathi thought to
          attend the fire ritual. Lord Shiva dehorted her, but she insisted
          attending the Yagna. Thus Shiva let Sathi attend the ritual along with
          his disciples called Ganas. Daksha gave Sathi a hostile receiving and
          humiliated Lord Shiva by uttering about Shiva’s attire and deeds.
          Sathi got depressed with Daksha’s actions and flung herself in Yagna
          fire. Shiva got to know about Sathi’s Immolation through Ganas, he
          gone mad with rage and cut off the head of Daksha. Then he destroyed
          the whole ritual. Later Lord Shiva took the half burn body of Sathi
          and went to Kailas. There he started dancing fiercely with Sathi’s
          body on his shoulder. By his cosmic dance called as Shiva Thandavam,
          it seemed that jeopardy of world might happen. Lord Vishnu with his
          Sudharshan Chakra cut the body of Sathi to several pieces to
          conciliate Lord Shiva’s fury. Thus the cut pieces of Sathi’s body fell
          in different places of world and are widely known as Sakthi Peethas.
        </>
      ),
      image: SD_gallery_01,
      seo_tag: {
        title:
          "About 18 Shakthi Peethas | 18 Shakthi Peethas | Shakthi Peetha | Bhramaramba devi",
        desc: "Sri Bhramaramba Devi Shakthi Peetham is 6th one of the 18 Shakthi Peethas. Srisaila Devasthanam website clearly describes about 18 Shakthi Peethas.",
        keywords:
          "About 18 Shakthi Peethas, 18 Shakthi Peethas, Shakthi Peetha, Bhramaramba devi",
        h1_tag: "",
      },
    },
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {" "}
          {data[type]?.seo_tag?.title ??
            "Srisaila Devasthanam Official Website"}
        </title>
        <meta
          name="description"
          content={
            data[type]?.seo_tag?.desc ?? "Srisaila Devasthanam Official Website"
          }
        />
        <meta name="keywords" content={data[type]?.seo_tag?.keywords} />
      </Helmet>
      <div className="sd-about-temple-history">
        {visible && (
          <div className="sd-about-temple-history-left">
            <ol>
              <li
                className={type === "visiting-places" ? "sd-active" : ""}
                onClick={() => {
                  setDrop6(false);
                  setDrop1(!drop1);
                }}
              >
                Visiting Places
                {drop1 ? (
                  <img
                    height="18px"
                    src={sd_minus_icon}
                    alt="-"
                    style={{marginTop: "6px"}}
                    onClick={() => {
                      setDrop1(false);
                    }}
                  ></img>
                ) : (
                  <img
                    height="18px"
                    style={{marginTop: "6px"}}
                    src={sd_plus_icon}
                    onClick={() => {
                      setDrop1(true);
                    }}
                  />
                )}
              </li>
              <div className={drop1 ? "drop1 block" : "drop1 none"}>
                <div
                  className={type === "dieties" ? "main sd-active" : "main"}
                  onClick={() => {
                    setDrop2(!drop2);
                    // setType("dieties");
                  }}
                >
                  <span>Dieties</span>
                  {drop2 ? (
                    <img
                      height="18px"
                      src={sd_minus_icon}
                      alt="-"
                      style={{marginTop: "6px"}}
                      onClick={() => {
                        setDrop2(false);
                      }}
                    ></img>
                  ) : (
                    <img
                      height="18px"
                      style={{marginTop: "6px"}}
                      src={sd_plus_icon}
                      onClick={() => {
                        setDrop2(true);
                      }}
                    />
                  )}
                </div>
                <div className={drop2 ? "drop2 block" : "drop2 none"}>
                  <div
                    className={type === "sakshi-ganapathi" && "sd-active"}
                    onClick={() => {
                      setType("sakshi-ganapathi");
                    }}
                  >
                    Sakshi Ganapathi{" "}
                  </div>
                  <div
                    className={
                      type === "bailu-veerabhadra-swamy" && "sd-active"
                    }
                    onClick={() => {
                      setType("bailu-veerabhadra-swamy");
                    }}
                  >
                    Bailu Veerabhadra Swamy{" "}
                  </div>
                  <div
                    className={
                      type === "arama-veerabhadra-swamy" && "sd-active"
                    }
                    onClick={() => {
                      setType("arama-veerabhadra-swamy");
                    }}
                  >
                    Arama Veerabhadra Swamy{" "}
                  </div>
                  <div
                    className={type === "jata-veerabhadra-swamy" && "sd-active"}
                    onClick={() => {
                      setType("jata-veerabhadra-swamy");
                    }}
                  >
                    Jata Veerabhadra Swamy{" "}
                  </div>
                  <div
                    className={type === "ankalamma-talli" && "sd-active"}
                    onClick={() => {
                      setType("ankalamma-talli");
                    }}
                  >
                    Ankalamma Talli{" "}
                  </div>
                  <div
                    className={type === "pathaleswara-swamy" && "sd-active"}
                    onClick={() => {
                      setType("pathaleswara-swamy");
                    }}
                  >
                    Pathaleswara Swamy{" "}
                  </div>
                  <div
                    className={type === "kala-bhairava-swamy" && "sd-active"}
                    onClick={() => {
                      setType("kala-bhairava-swamy");
                    }}
                  >
                    Kala Bhairava Swamy{" "}
                  </div>
                  <div
                    className={
                      type === "nagalooty-veerabhadra-swamy" && "sd-active"
                    }
                    onClick={() => {
                      setType("nagalooty-veerabhadra-swamy");
                    }}
                  >
                    Nagalooty Veerabhadra Swamy{" "}
                  </div>
                  <div
                    className={type === "istakameshwari-temple" && "sd-active"}
                    onClick={() => {
                      setType("istakameshwari-temple");
                    }}
                  >
                    Istakameshwari Temple{" "}
                  </div>
                </div>

                <div
                  className={
                    type === "mystical spots" ? "main sd-active" : "main"
                  }
                  onClick={() => {
                    setDrop3(!drop3);
                  }}
                >
                  Mystical spots
                  {drop3 ? (
                    <img
                      height="18px"
                      src={sd_minus_icon}
                      alt="-"
                      style={{marginTop: "6px"}}
                      onClick={() => {
                        setDrop3(false);
                      }}
                    ></img>
                  ) : (
                    <img
                      height="18px"
                      style={{marginTop: "6px"}}
                      src={sd_plus_icon}
                      onClick={() => {
                        setDrop3(true);
                      }}
                    />
                  )}
                </div>

                <div className={drop3 ? "drop3 block" : "drop3 none"}>
                  <div
                    className={type === "hatakeswaram" && "sd-active"}
                    onClick={() => {
                      setType("hatakeswaram");
                    }}
                  >
                    Hatakeswaram{" "}
                  </div>
                  <div
                    className={type === "sikhareswaram" && "sd-active"}
                    onClick={() => {
                      setType("sikhareswaram");
                    }}
                  >
                    Sikhareswaram{" "}
                  </div>

                  <div
                    className={
                      type === "hemareddymallamma-(mallamma-kanneeru)" &&
                      "sd-active"
                    }
                    onClick={() => {
                      setType("hemareddymallamma-(mallamma-kanneeru)");
                    }}
                  >
                    Hemareddy Mallamma{" "}
                  </div>
                  {/* <div
                    onClick={() => {
                      setType("Saleswaram");
                    }}
                  >
                    Saleswaram
                  </div> */}
                  <div
                    className={type === "guptha-mallikarjunam" && "sd-active"}
                    onClick={() => {
                      setType("guptha-mallikarjunam");
                    }}
                  >
                    Guptha Mallikarjunam{" "}
                  </div>
                  <div
                    className={type === "gogharbamu" && "sd-active"}
                    onClick={() => {
                      setType("gogharbamu");
                    }}
                  >
                    Gogharbamu{" "}
                  </div>
                  <div
                    className={type === "kadali-vanam" && "sd-active"}
                    onClick={() => {
                      setType("kadali-vanam");
                    }}
                  >
                    Kadali vanam{" "}
                  </div>
                </div>

                <div
                  className={
                    type === "pancha-Mathalu" ? "main sd-active" : "main"
                  }
                  onClick={() => {
                    setDrop4(!drop4);
                  }}
                >
                  Pancha Mathalu{" "}
                  {drop4 ? (
                    <img
                      height="18px"
                      src={sd_minus_icon}
                      alt="-"
                      style={{marginTop: "6px"}}
                      onClick={() => {
                        setDrop4(false);
                      }}
                    ></img>
                  ) : (
                    <img
                      height="18px"
                      style={{marginTop: "6px"}}
                      src={sd_plus_icon}
                      onClick={() => {
                        setDrop4(true);
                      }}
                    />
                  )}
                </div>
                <div className={drop4 ? "drop4 block" : "drop4 none"}>
                  <div
                    className={type === "ghanta-matham" && "sd-active"}
                    onClick={() => {
                      setType("ghanta-matham");
                    }}
                  >
                    Ghanta Matham{" "}
                  </div>
                  <div
                    className={type === "vibhuthi-matham" && "sd-active"}
                    onClick={() => {
                      setType("vibhuthi-matham");
                    }}
                  >
                    Vibhuthi Matham{" "}
                  </div>
                  <div
                    className={type === "rudraksha-matham" && "sd-active"}
                    onClick={() => {
                      setType("rudraksha-matham");
                    }}
                  >
                    Rudraksha Matham{" "}
                  </div>
                  <div
                    className={type === "bheemaSankara-matham" && "sd-active"}
                    onClick={() => {
                      setType("bheemaSankara-matham");
                    }}
                  >
                    Bheema Shankara Matham{" "}
                  </div>
                  <div
                    className={type === "sarangadhara-matham" && "sd-active"}
                    onClick={() => {
                      setType("sarangadhara-matham");
                    }}
                  >
                    Sarangadhara Matham{" "}
                  </div>
                </div>

                <div
                  className={
                    type === "sacred-waters-&-nature"
                      ? "main sd-active"
                      : "main"
                  }
                  onClick={() => {
                    setDrop5(!drop5);
                  }}
                >
                  Sacred waters & nature{" "}
                  {drop5 ? (
                    <img
                      height="18px"
                      src={sd_minus_icon}
                      alt="-"
                      style={{marginTop: "6px"}}
                      onClick={() => {
                        setDrop5(false);
                      }}
                    ></img>
                  ) : (
                    <img
                      height="18px"
                      style={{marginTop: "6px"}}
                      src={sd_plus_icon}
                      onClick={() => {
                        setDrop5(true);
                      }}
                    />
                  )}
                </div>

                <div className={drop5 ? "drop5 block" : "drop5 none"}>
                  <div
                    className={type === "siddha-ramappa-kolanu" && "sd-active"}
                    onClick={() => {
                      setType("siddha-ramappa-kolanu");
                    }}
                  >
                    Siddha Ramappa Kolanu{" "}
                  </div>
                  <div
                    className={type === "phaladhara-panchadhara" && "sd-active"}
                    onClick={() => {
                      setType("phaladhara-panchadhara");
                    }}
                  >
                    Phaladhara Panchadhara{" "}
                  </div>
                  <div
                    className={type === "bheemuni-kolanu" && "sd-active"}
                    onClick={() => {
                      setType("bheemuni-kolanu");
                    }}
                  >
                    Bheemuni Kolanu
                  </div>
                  {/* <div
                    onClick={() => {
                      setType("Bheemuni padalu");
                    }}
                  >
                    Bheemuni padalu
                  </div> */}
                  <div
                    className={type === "bhairava-sila" && "sd-active"}
                    onClick={() => {
                      setType("bhairava-sila");
                    }}
                  >
                    Bhairava Sela
                  </div>
                  {/* <div
                    onClick={() => {
                      setType("Lingaala Gattu");
                    }}
                  >
                    Lingaala Gattu
                  </div> */}
                  <div
                    className={type === "akkamahadevi-caves" && "sd-active"}
                    onClick={() => {
                      setType("akkamahadevi-caves");
                    }}
                  >
                    Akkamahadevi Caves{" "}
                  </div>
                  <div
                    className={type === "bhramaramba-cheruvu" && "sd-active"}
                    onClick={() => {
                      setType("bhramaramba-cheruvu");
                    }}
                  >
                    Bhramaramba Cheruvu
                  </div>
                  <div
                    className={type === "mallela-theertham" && "sd-active"}
                    onClick={() => {
                      setType("mallela-theertham");
                    }}
                  >
                    Mallela Theertham
                  </div>
                  <div
                    className={type === "srisailam-dam" && "sd-active"}
                    onClick={() => {
                      setType("srisailam-dam");
                    }}
                  >
                    Srisailam Dam
                  </div>

                  <div
                    className={type === "octopus-view" && "sd-active"}
                    onClick={() => {
                      setType("octopus-view");
                    }}
                  >
                    Octopus View
                  </div>
                  <div
                    className={type === "nature-and-beauty" && "sd-active"}
                    onClick={() => {
                      setType("nature-and-beauty");
                    }}
                  >
                    Nature and Beauty{" "}
                  </div>
                </div>
              </div>

              <li
                className={type === "trusts" ? "sd-active" : ""}
                onClick={() => {
                  setDrop6(!drop6);
                }}
              >
                <span> Trusts</span>{" "}
                {drop6 ? (
                  <img
                    height="18px"
                    src={sd_minus_icon}
                    alt="-"
                    style={{marginTop: "6px"}}
                    onClick={() => {
                      setDrop6(false);
                    }}
                  ></img>
                ) : (
                  <img
                    height="18px"
                    style={{marginTop: "6px"}}
                    src={sd_plus_icon}
                    onClick={() => {
                      setDrop6(true);
                    }}
                  />
                )}
              </li>
              <div className={drop6 ? "drop6 block" : "drop6 none"}>
                <div
                  className={
                    type ===
                      "sri-bhramaramba-mallikarjuna-swamyvarla-devasthanam" &&
                    "sd-active"
                  }
                  onClick={() => {
                    setType(
                      "sri-bhramaramba-mallikarjuna-swamyvarla-devasthanam"
                    );
                  }}
                >
                  Sri Bhramaramba Mallikarjuna Swamyvarla Devasthanam
                </div>
                <div
                  className={type === "anna-prasadam-trust" && "sd-active"}
                  onClick={() => {
                    setType("anna-prasadam-trust");
                  }}
                >
                  AnnaPrasadam Trust{" "}
                </div>
                <div
                  className={type === "aagama-patasala-trust" && "sd-active"}
                  onClick={() => {
                    setType("aagama-patasala-trust");
                  }}
                >
                  Aagama Patasala Trust
                </div>
                <div
                  className={type === "go-samrakshana-trust" && "sd-active"}
                  onClick={() => {
                    setType("go-samrakshana-trust");
                  }}
                >
                  Go Samrakshana Trust
                </div>
                <div
                  className={type === "pranadana-trust" && "sd-active"}
                  onClick={() => {
                    setType("pranadana-trust");
                  }}
                >
                  Pranadana Trust
                </div>
                <div
                  className={type === "swachh-srisailam" && "sd-active"}
                  onClick={() => {
                    setType("swachh-srisailam");
                  }}
                >
                  Swachh Srisailam
                </div>
              </div>

              <li
                className={type === "do's-&-don'ts" ? "sd-active" : ""}
                onClick={() => {
                  setDrop1(false);
                  setDrop6(false);
                  setType("do's-&-don'ts");
                }}
              >
                Do's & Don'ts{" "}
              </li>
              <li
                className={type === "intellectual-property" ? "sd-active" : ""}
                onClick={() => {
                  setDrop1(false);
                  setDrop6(false);
                  setType("intellectual-property");
                }}
              >
                Intellectual Property{" "}
              </li>
              <li
                className={type === "certifications" ? "sd-active" : ""}
                onClick={() => {
                  setDrop1(false);
                  setDrop6(false);
                  setType("certifications");
                }}
              >
                Certifications{" "}
              </li>

              <li
                className={type === "12-jyotirlinga" ? "sd-active" : ""}
                onClick={() => {
                  setDrop1(false);
                  setDrop6(false);
                  setType("12-jyotirlinga");
                }}
              >
                12 Jyotirlinga{" "}
              </li>
              <li
                className={type === "18-shakti-peethas" ? "sd-active" : ""}
                onClick={() => {
                  setDrop1(false);
                  setDrop6(false);
                  setType("18-shakti-peethas");
                }}
              >
                18 Shakti Peethas{" "}
              </li>
            </ol>
          </div>
        )}{" "}
        <div className="sd-about-temple-history-right">
          <div style={{display: imageLoading ? "none" : "block"}}>
            <div className="sd-about-temple-history-right-top" ref={titleRef}>
              <h1>
                About
                <span> {data[type]?.heading}</span> {}
              </h1>
              <img src={SD_title_divider} alt={"divider"}></img>
            </div>
            <div className="sd-about-temple-history-right-bottom">
              <div className="sd-athrb-top">
                <img
                  src={data[type]?.image}
                  alt="alternative"
                  align="left"
                  onLoadStart={() => {
                    setImageLoading(true);
                  }}
                  onLoad={() => {
                    setImageLoading(false);
                  }}
                />
                <p>{data[type]?.description1}</p>
              </div>
              {/* <div className="sd-athrb-bottom">{data[type].description2}</div> */}
            </div>
          </div>
          <div
            style={{
              display: !imageLoading ? "none" : "block",
              height: "30%",
            }}
          >
            <Sd_loading />
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(Sd_about_general_information_component);
