import Axios from "../Utils/sd-axios/Axios";

const ParokshaSevaService = {
  getAll: async () => {
    try {
      const resp = await Axios.get("/seva/all?sevaType=parokshaSeva");
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  bookings: async (bookingData, paymentVendor, validity, currency) => {
    try {
      const resp = await Axios.post(
        `cart`,
        {
          cart: bookingData,
          paymentVendor: paymentVendor,
          check_validity: validity,
          currency,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  getPratyakshaSlots: async (id, date) => {
    try {
      const resp = await Axios.get(
        `/seva/slots/available/${id}?bookingDate=${date}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        // //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
};

export default ParokshaSevaService;
