import React, {useContext, useEffect} from "react";
import {Helmet} from "react-helmet";
import Sd_gallery_semi_view from "../../Components/sd-common-components/sd-gallery-semi-view";
import TemplateFour from "../../Components/body/sd-home-template/sd-home-component-four";
import TemplateOne from "../../Components/body/sd-home-template/sd-home-component-one";
import TemplateThree from "../../Components/body/sd-home-template/sd-home-component-three";
import TemplateTwo from "../../Components/body/sd-home-template/sd-home-component-two";
import "../../Utils/sd-styles/sd-home.scss";
import {SD_Context} from "../../context/sd-context";
const Sd_Home = () => {
  const {setActiveTopHeader, setActiveDownHeader, setCurrentDashboardLower} =
    useContext(SD_Context);
  useEffect(() => {
    setActiveDownHeader("");
    setActiveTopHeader("");
    setCurrentDashboardLower("");
  }, []);
  // console.log("home element rerender");
  return (
    <div className="w-100">
      <Helmet>
        <meta charSet="utf-8" />
        <title> Srisaila Devasthanam Official Website</title>
        <meta name="description" content="Home Page of srisaila devasthanam" />
        <meta name="keywords" content="Srisaila, Devastanam, Home" />
      </Helmet>
      <TemplateOne />
      <TemplateTwo />
      <TemplateThree />
      <TemplateFour />
      <Sd_gallery_semi_view />
    </div>
  );
};
export default Sd_Home;
