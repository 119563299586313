import React, {useEffect, useState} from "react";
import {SD_Right_Arrow_white, SD_srisaila_tv} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-main-sup.scss";
import Iframe from "react-iframe";
import YoutubeService from "../../../services/sd-youtube.service";

const TemplateThree = () => {
  //-------------------------------------------------------------states--------------------------------------------------------//

  const [isYoutubeLive, setIsYoutubeLive] = useState(true);
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  useEffect(async () => {
    try {
      const videoId = await YoutubeService.getHomePageId();
      // console.log(videoId);
      setIsYoutubeLive(videoId);
    } catch (error) {
      setIsYoutubeLive(false);
      // console.log(`problem getting youtube video id`);
    }
  }, []);
  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  return (
    <div className="sd-template-three">
      <div className="sd-t3-inner-div">
        <div className="sd-t3-box-left">
          <div className="sd-t3-box-left-one">
            <img src={SD_srisaila_tv} alt="logo" /> <h2>Srisaila TV</h2>
          </div>
          <div className="sd-t3-box-left-two">
            <p>
              Srisaila TV is a 24 x 7 Webcast devotional channel in Telugu which
              caters to the people of Hindu religion. It was launched on
              March,2017. It is from Srisaila Devasthanam Product.
            </p>
          </div>
          <div className="sd-t3-box-left-three">
            <p>
              It's south India's first, Lord Shiva's devotional channel on
              Telugu. The channel telecasts fiction and non-fiction programs.
            </p>
          </div>
          <div className="sd-t3-box-left-four">
            <button
              onClick={() => {
                window.open(
                  "https://www.youtube.com/channel/UCNIzL2dT2gZxQae2LHXvl0A",
                  "_blank"
                );
              }}
            >
              Watch Now <img src={SD_Right_Arrow_white} alt=">" />
            </button>
          </div>
        </div>
        <div className="sd-t3-box-right">
          {isYoutubeLive ? (
            <Iframe
              url={`https://www.youtube.com/embed/live_stream?channel=UCNIzL2dT2gZxQae2LHXvl0A&rel=0`}
              display="initial"
            />
          ) : (
            <Iframe
              url={`https://www.youtube.com/embed/bgyVTq7Xa64?rel=0`}
              display="initial"
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default TemplateThree;
