import React, {useContext, useEffect, useState} from "react";
import {withRouter} from "react-router";
import {
  SD_appstore_icon,
  SD_Call_Icon,
  SD_email_icon,
  SD_facebook_icon,
  SD_footer_bottom_strip,
  SD_instagram_icon,
  sd_map_icon,
  SD_newsletter_arrow_icon,
  SD_playstore_icon,
  SD_Print_Icon,
  SD_twitter_icon,
  SD_Web_Icon,
  SD_youtube_icon,
} from "../../Assets/imageList";
import NewsletterSubService from "../../services/sd-newsletter-sub.service";
import "../../Utils/sd-styles/sd-footer.scss";
import "react-toastify/dist/ReactToastify.css";
import {SD_Context} from "../../context/sd-context";
// eslint-disable-next-line react/display-name
const Footer = React.memo(({history}) => {
  //-------------------------------------------------------------states----------------------------------------------------------//

  const [selectedLanguage, setSelectedLanguages] = React.useState("en-in");
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [errorFlag, setErrorFlag] = useState(false);
  const [subEmail, setSubEmail] = useState("");
  const [successFlag, setSuccessFlag] = useState(false);
  const {getVisitors, visitorCount} = useContext(SD_Context);
  const [errMsg, setMsg] = useState("");

  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------functions--------------------------------------------------------//

  const subscribeButton = async () => {
    try {
      if (subEmail) {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(subEmail).toLowerCase())) {
          setErrorFlag(true);
          setMsg("Please enter a valid Email address");
          return;
        }
        let res = await NewsletterSubService.subscribe(subEmail);
        if (res) {
          setErrorFlag(false);
          setMsg("");
        }
        // toast.success("Subscribed to newsletter.");
        setSuccessFlag(true);
        setSubEmail("");
      } else {
        setErrorFlag(true);
      }
    } catch (error) {
      setMsg(error.message);
      setErrorFlag(true);

      // toast.error(error.message);
    }
  };
  //-------------------------------------------------------------functions--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//
  useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedLanguages(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedLanguages("en-in");
    }
    getVisitors();
  }, []);

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  return (
    <div className="sd-footer-main">
      <div className="sd-footer-top">
        <div className="sd-footer-top-left">
          <p>Download Mobile App</p>
          <div>
            <img
              src={SD_playstore_icon}
              alt="google play"
              style={{marginLeft: "10px", marginRight: "10px"}}
            />
            <img src={SD_appstore_icon} alt="google play" />
          </div>
        </div>
        <div className="sd-footer-top-right">
          <p>Subscribe Newsletter</p>
          <div className="sd-sub">
            <div
              className={
                errorFlag
                  ? "subscribe sd-red"
                  : subEmail !== ""
                  ? " sd-orange subscribe"
                  : "subscribe"
              }
            >
              <img src={SD_email_icon} alt="email icon" />

              <input
                type="text"
                maxLength="30"
                placeholder="Email Address"
                style={{textTransform: "none"}}
                onKeyUp={(e) => {
                  if (e.keyCode === 13 || e.key === "Enter") {
                    subscribeButton();
                  }
                }}
                onChange={(e) => {
                  if (errorFlag) {
                    setErrorFlag(false);
                  }
                  setSubEmail(e.target.value);
                }}
                value={subEmail}
              />
              <img
                src={SD_newsletter_arrow_icon}
                onClick={subscribeButton}
                alt="newsletter icon"
              />
              {errorFlag && <h4>{errMsg}</h4>}
            </div>
          </div>
        </div>

        {successFlag && (
          <div className="callout">
            <div className="callout-header">Success</div>
            <span
              className="closebtn"
              onClick={() => {
                setSuccessFlag(false);
              }}
            >
              ×
            </span>
            <div className="callout-container">
              <p>
                Thanks for subscribing!{" "}
                {/* <a >Learn more</a> or close it if it is in your way. */}
              </p>
              <button
                onClick={() => {
                  setSuccessFlag(false);
                }}
              >
                Ok
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="sd-footer-middle">
        <footer
          className="text-center text-lg-start sd-footer-middle-f"
          style={{backgroundColor: "#efefef!important"}}
        >
          <div
            className="sd-container-r"
            style={{
              backgroundColor: "#efefef!important",
              width: "90%!important",
            }}
          >
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                textAlign: "initial",
                backgroundColor: "#efefef",
              }}
            >
              <div className="sd-list-body w-16">
                <h5
                  className="text"
                  onClick={() => {
                    history?.push(`/${selectedLanguage}/about`);
                  }}
                >
                  About
                </h5>
                <ul className="list-unstyled mb-0">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/about`);
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/about/the-temple/srisailam-devasthanam`
                        );
                      }}
                    >
                      The Temple
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/about/the-temple-history/the-history`
                        );
                      }}
                    >
                      The Temple Story
                    </a>
                  </li>

                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/about/general-information/sakshi-ganapathi`
                        );
                      }}
                    >
                      General Information
                    </a>
                  </li>
                </ul>
              </div>

              <div className="sd-list-body w-18">
                <h5
                  className="text "
                  onClick={() => {
                    history?.push(`/${selectedLanguage}/sevas-and-darshanam`);
                  }}
                >
                  Sevas & Darshanam{" "}
                </h5>

                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/sevas-and-darshanam`
                        );
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/sevas-and-darshanam/darshanam`
                        );
                      }}
                      className="text-dark"
                    >
                      Darshanam{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/sevas-and-darshanam/paroksha-seva`
                        );
                      }}
                      className="text-dark"
                    >
                      Paroksha Seva
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/sevas-and-darshanam/pratyaksha-seva`
                        );
                      }}
                      className="text-dark"
                    >
                      Pratyaksha Seva{" "}
                    </a>
                  </li>
                  {/* <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/sevas-and-darshanam/saswata-seva`)}}
                      className="text-dark"
                    >
                      Saswata Seva
                    </a>
                  </li> */}
                </ul>
              </div>

              <div className="sd-list-body w-18">
                <h5 className="text">Donations</h5>

                <ul className="list-unstyled mb-0">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/donations`);
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/donations/e-hundi`);
                      }}
                    >
                      e-Hundi
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/donations/sbms-annaprasadam-trust`
                        );
                      }}
                    >
                      AnnaPrasadam Trust
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/donations/sbms-aagama-patashala-trust`
                        );
                      }}
                    >
                      Aagama Patasala Trust
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/donations/sbms-gosamrakshana-trust`
                        );
                      }}
                    >
                      GoSamrakshana Trust
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/donations/sbms-pranadana-trust`
                        );
                      }}
                    >
                      Pranadana Trust
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/donations/sbms-srisaila-tv`
                        );
                      }}
                    >
                      Srisaila TV
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/donations/sbms-swachaa-srisailam`
                        );
                      }}
                    >
                      Swachh Srisailam
                    </a>
                  </li>
                </ul>
              </div>

              <div className="sd-list-body w-18">
                <h5 className="text ">Online Booking</h5>

                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/online-booking`);
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        if (localStorage.getItem(`accessToken`)) {
                          history?.push(
                            `/${selectedLanguage}/devotee-app/online-booking/pratyaksha-seva`
                          );
                        } else {
                          history?.push(
                            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-seva`
                          );
                        }
                      }}
                    >
                      Pratyaksha Seva Booking
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        if (localStorage.getItem(`accessToken`)) {
                          history?.push(
                            `/${selectedLanguage}/devotee-app/online-booking/paroksha-seva`
                          );
                        } else {
                          history?.push(
                            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/paroksha-seva`
                          );
                        }
                      }}
                    >
                      Paroksha Seva Booking
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        if (localStorage.getItem(`accessToken`)) {
                          history?.push(
                            `/${selectedLanguage}/devotee-app/online-booking/darshanam`
                          );
                        } else {
                          history?.push(
                            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/darshanam`
                          );
                        }
                      }}
                    >
                      Darshanam Tickets
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        if (localStorage.getItem(`accessToken`)) {
                          history?.push(
                            `/${selectedLanguage}/devotee-app/online-booking/donations`
                          );
                        } else {
                          history?.push(
                            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/donations`
                          );
                        }
                      }}
                    >
                      Donations
                    </a>
                  </li>

                  {/* <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        if (localStorage.getItem(`accessToken`)) {
                          history?.push(
                            `/${selectedLanguage}/devotee-app/online-booking/prasadam`
                          );
                        } else {
                          history?.push(
                            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/prasadam-seva`
                          );
                        }
                      }}
                    >
                      Prasadam
                    </a>
                  </li> */}
                  {/* <li>
                    <a
                      className="text-dark"
                      onClick={()=>{history?.push(`/${selectedLanguage}/online-booking/kalyana-katta-tickets`)}}
                    >
                      Kalyana Katta Tickets
                    </a>
                  </li> */}
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        if (localStorage.getItem(`accessToken`)) {
                          history?.push(
                            `/${selectedLanguage}/devotee-app/online-booking/accommodations`
                          );
                        } else {
                          history?.push(
                            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/accommodations`
                          );
                        }
                      }}
                    >
                      Accommodation
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        if (localStorage.getItem(`accessToken`)) {
                          history?.push(
                            `/${selectedLanguage}/devotee-app/online-booking/publications`
                          );
                        } else {
                          history?.push(
                            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/publications`
                          );
                        }
                      }}
                    >
                      Publications
                    </a>
                  </li>
                  {/* <li>
                    <a
                      className="text-dark"
                      onClick={()=>{history?.push(`/${selectedLanguage}/online-booking/srisaila-prabha`)}}
                    >
                      Srisaila Prabha
                    </a>
                  </li> */}
                </ul>
              </div>

              <div className="sd-list-body w-15">
                <h5 className="text ">Media Room</h5>

                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/media-room`);
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/media-room/media-kit`
                        );
                      }}
                      className="text-dark"
                    >
                      Media Kit
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/media-room/photo-gallery`
                        );
                      }}
                      className="text-dark"
                    >
                      Gallery
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/media-room/whats-new/temple-diary`
                        );
                      }}
                      className="text-dark"
                    >
                      Whats New
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/media-room/press/temple-news`
                        );
                      }}
                      className="text-dark"
                    >
                      Press
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/media-room/tenders`
                        );
                      }}
                      className="text-dark"
                    >
                      Tenders
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/media-room/rti-act`
                        );
                      }}
                      className="text-dark"
                    >
                      RTI Act
                    </a>
                  </li>
                  {/* <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/media-room/temple-dairy`)}}
                      className="text-dark"
                    >
                      Temple Dairy
                    </a>
                  </li>

                  <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/media-room/events`)}}
                      className="text-dark"
                    >
                      Events
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/media-room/publications`)}}
                      className="text-dark"
                    >
                      Publications
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/media-room/press-releases`)}}
                      className="text-dark"
                    >
                      Press Releases
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/media-room/press-coverage`)}}
                      className="text-dark"
                    >
                      Press Coverage
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/media-room/news`)}}
                      className="text-dark"
                    >
                      News
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/media-room/newsletter`)}}
                      className="text-dark"
                    >
                      Newsletter
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/media-room/media-queries`)}}
                      className="text-dark"
                    >
                      Media Queries
                    </a>
                  </li>

                  <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/media-room/rti-act`)}}
                      className="text-dark"
                    >
                      RTI Act
                    </a>
                  </li>
               */}
                </ul>
              </div>

              <div className="sd-list-body w-15">
                <h5 className="text ">Support</h5>

                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/support`);
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/support/faqs-all`);
                      }}
                      className="text-dark"
                    >
                      FAQs
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/support/facilities-to-pilgrims`
                        );
                      }}
                      className="text-dark"
                    >
                      Facilities to Pilgrims
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/support/connectivity`
                        );
                      }}
                      className="text-dark"
                    >
                      Connectivity
                    </a>
                  </li>

                  {/* <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/support/call-center`)}}
                      className="text-dark"
                    >
                      Call Center
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/support/live-chat`)}}
                      className="text-dark"
                    >
                      Live Chat
                    </a>
                  </li> */}
                  <li>
                    <a
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/support/contactUs`);
                      }}
                      className="text-dark"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>

              {/* <div className="sd-list-body">
                <h5
                  className="text "
                  onClick={() => {
                    history?.push(`/${selectedLanguage}/legalities/terms-and-conditions`);
                  }}
                >
                  Legalities{" "}
                </h5>

                <ul className="list-unstyled">
                  <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/legalities/privacy-policy`)}}
                      className="text-dark"
                    >
                      Privacy Policy{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/legalities/terms-and-conditions`)}}
                      className="text-dark"
                    >
                      Refund Policy{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/trademarks`)}}
                      className="text-dark"
                    >
                      Trademarks{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/legalities/terms-and-conditions`)}}
                      className="text-dark"
                    >
                      Terms and Conditions{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/legalities/terms-and-conditions`)}}
                      className="text-dark"
                    >
                      Cautionary Notice{" "}
                    </a>
                  </li>
                </ul>
              </div>
           */}
            </div>
          </div>

          <div className="sd-additional-footer">
            <div className="left">
              <p>
                <img src={sd_map_icon} alt="main" /> Srisaila Devasthanam,
              </p>
              <p>
                <img
                  src={sd_map_icon}
                  alt="main"
                  style={{visibility: "hidden", marginLeft: "3px"}}
                />
                Srisailam - 518101, Kurnool (Dist.), Andhra Pradesh, India.
              </p>
            </div>
            <div className="middle sd-border-g">
              <p>
                <img src={SD_email_icon} alt="mail" className="sep" />
                endow-eosri@gov.in
              </p>
              <p>
                <img src={SD_Web_Icon} alt="mail" />
                www.srisailadevasthanam.org
              </p>
            </div>
            <div className="right">
              <p>
                <img src={SD_Call_Icon} alt="number" /> +91-8333901351 /2 /3 /4
                /5 /6
              </p>
              <p>
                <img src={SD_Print_Icon} alt="print" /> +91-8524-287126
              </p>
            </div>
          </div>

          <div className="sd-visitors">
            <div className="left">
              <p>
                {" "}
                Total Visitors <span>{visitorCount.total}</span>
              </p>{" "}
            </div>
            <div className="right">
              <p>
                Todays Visitors <span>{visitorCount.today}</span>
              </p>
            </div>
          </div>
        </footer>
      </div>
      <div className="sd-footer-bottom">
        <img src={SD_footer_bottom_strip} alt={SD_footer_bottom_strip} />
      </div>
      <div className="sd-footer-tag">
        <div className="left">
          <img
            onClick={() => {
              window.open(
                "https://www.facebook.com/SrisailaDevasthanamOfficial",
                "_blank"
              );
            }}
            src={SD_facebook_icon}
            alt="icon"
          />
          <img
            onClick={() => {
              window.open("https://twitter.com/sbmsdevasthanam", "_blank");
            }}
            src={SD_twitter_icon}
            alt="icon"
          />

          <img
            onClick={() => {
              window.open(
                "https://www.instagram.com/sbmsdevasthanam/",

                "_blank"
              );
            }}
            src={SD_instagram_icon}
            alt="icon"
          />
          <img
            onClick={() => {
              window.open(
                "https://www.youtube.com/channel/UCNIzL2dT2gZxQae2LHXvl0A",

                "_blank"
              );
            }}
            src={SD_youtube_icon}
            alt="icon"
          />
        </div>
        <div className="middle">
          <p className="border-r">
            {" "}
            © {new Date().getFullYear()} Srisaila Devasthanam.
          </p>
          {/* <p
            onClick={() => {
              history?.push(`/${selectedLanguage}/trademarks`);
            }}
            className="border-r color"
          >
            Trademarks
          </p> */}
          <p
            onClick={() => {
              history?.push(`/${selectedLanguage}/legalities/privacy-policy`);
            }}
            className="border-r color"
          >
            Privacy Policy
          </p>

          <p
            onClick={() => {
              history?.push(
                `/${selectedLanguage}/legalities/terms-and-conditions`
              );
            }}
            className=" color"
          >
            Terms & Conditions
          </p>
        </div>
        <div
          className="right"
          onClick={() => {
            window.open("https://www.9and9.com/", "_blank");
          }}
        >
          Developed by <span> 9and9</span>
        </div>
      </div>
    </div>
  );
});
export default withRouter(Footer);
