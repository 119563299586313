import React, {useEffect, useState} from "react";
import {
  SD_divider,
  SD_title_divider,
  SD_highlets_border,
} from "../../../../Assets/imageList";
import "../../../../Utils/sd-styles/sd-ganapathihomam.scss";
import Sd_loading from "../../../sd-common-components/sd-loading";
const BodyData = {
  suprabhataseva: {
    firstbanner: [
      "Devim Vaachamajanayanta Devaaha Thaam Vishwaroopa Pashavo Vadatni",
      "Saano Mandhreshu Moorjham Dhuhaanaa Dhenurvaagathasmanupanushtutthethu",
    ],
    firstHeading: null,
    firstDesc: (
      <>
        These lines suggest that Sanskrit, the god's language shall only be used
        as prescribes in God's strotras. This is why all strotras addressing the
        god are in Sanskrit. This has always been a tradition.
        <br /> <br />
        All well-known temples organize Suprabhatam. It is included in many
        services or Sevas often requested by devotees. Among them, the following
        are famous: Sri Mallikarjuna Suprabhatham, Sri Venkateshwara
        Suprabhatham, Bhadradri Rama Suprabhatham, Yadadri Narasimha
        Suprabhatham, Kanchi Kamakshi Suprabhatham.
        <br /> <br />
        Suprabhatham Anu Shabdham Bhuthum Pravuttham, Prabhaatham Kaga
        Sushguprabhaatham-Suprabhaatham. Which means Since this day is
        auspicious, the beginning of this day is auspicious, as your devotees
        this early morning, let this day be auspicious and as a protector, O
        God, let your day begin with auspiciousness. Suprabhatham comprises of
        waking up the lord(Suprabhatham), reciting Stotras, Prapatthi and
        Mangalaashasanam. Sometimes few changes/additions are made according to
        devotees request.
        <br /> <br />
        Currently, Srisaila Mallikarjuna Suprabhrathagrandham comprises of
        waking up the lord(Suprabhatham), reciting Stotras, Prapatthi and
        Mangalaashasanam. The reason is as follows: "Shaantham padmaasthanasdham
        Shashidharamukutam Panchavaktram Trinethram" This way worshipping Lord
        Shiva is prescribed. It is prescribed to offer archanas to Shiva's five
        aspects: Sadyojata, Vamadeva, Aghora, Tatpurusha and Ishana.
        <br /> <br />
        The essence of Suprabhatham which comprises the above said four parts is
        to worship all senses/aspects of Lord Shiva.
        <br /> <br />
        Many devotees have written numerous Suprabhathams. But the significance
        of Srisaila Mallikarjuna Suprabhatham is that it's taken from Srisaila
        Khandam that details the strotras recited by Nandi himself. Hence, the
        Suprabhatham offered here is exactly similar to what is offered in
        Kailasa and how Nandi worshipped Lord Shiva. Bhramaramba Sametha
        Mallikarjuna Swamy is worshipped through this Suprabhatham which touches
        all five aspects/senses of Lord Shiva.
        <br /> <br />
        So to participate in Suprabhatham at Srisaila Kshetram which is the
        centre of the whole world and the abode to great teachings and Vedas, an
        auspicious location, the world's centre, in the presence of Sri
        Mallikarjuna Bhramaramba, destroys all sins, gifts auspicious
        beginnings, and bestows complete blessings of Sri Mallikarjuna
        Bhramaramba. So devotees are requested to abide by the devasthanam
        regulations to worship and participate in Mallikarjuna Suprabhaatham
        through Pratyaksha Seva organised by devasthanam administration.
      </>
    ),
    secondbanner: null,
    secondHeading: null,
    secondDesc: null,
  },

  samuhikaabhishekamulusingle: {
    firstbanner: [
      "Nasthi Ligjarchanaath Punyamadhikam Bhuvanatraye",
      "Ligjerchathe Khalu Vishwamarchitham Snannasamshayaha",
      "Maayayaa Mohithaatmano Na Janatni Maheshwaram",
      "Vrudivyaam Yaani Theerthani Punyaanyaayatanani Cha",
      "Shivaligje Vasatnveya Taani Sarvani",
    ],
    firstHeading: null,
    firstDesc: (
      <>
        The supreme god manifested himself as the Lingam. All the worlds in the
        universe lie within the Lingam. This lingam holds the power of all three
        supreme gods. This is why performing Archana to the lingam is greater
        than performing Archana to the idol. Lingarchana is performing Archana
        to the whole world. All the holy places and all of the universe is
        within the Lingam.
        <br /> <br />
        Umamaheshwara himself in Srisaila Khandam stated that the Mahalingam,
        Jyotirlangam, Mallikarjuna Swamy Lingam has all the significance that's
        mentioned above.
      </>
    ),
    secondbanner: null,
    secondHeading: null,
    secondDesc: (
      <>
        Rudraadhyayam captures the glory of Lord Rudra and his significance.
        Tanme Manaha Shivasankalpamastu. Hence, Rudraanuvaakam recited during
        Rudrabhishekam at the centre of the world, the Sri Chakra, abode to the
        Vedas, Crown place of all mantras, which is Srisaila Devasthanam,
        inhibited by Sri Mallikarjuna Bhramaramba liberates devotees from all
        hurdles, renders the wisdom of Lord Shiva and invites fortune. It is
        also notable that Rudra Abhishekam offered at Srisailam showers endless
        positive outcomes and the divine blessings of Sri Mallikarjuna Swamy and
        Bhramaramba. As the devotees are not supposed to enter into Sanctum
        right in this scenario, those who want to offer Abhishekam to Sri
        Mallikarjuna MahaLingam can participate in Samuhika Abhishekamulu via
        Pratyaksha Seva. With this, devotees can participate in Rudram
        recitation and the holy water touched by Devotees is used in Abhishekam
        of Sri Mallikarjuna Swamy in sanctum.
        <br />
        <br />
        So all devotees are requested to abide by the Devasthanam regulations
        and participate in Samuhika Abhishekamulu through Pratyaksha Seva.
      </>
    ),
  },
  samuhikaabhishekamulucouple: {
    firstbanner: [
      "Nasthi Ligjarchanaath Punyamadhikam Bhuvanatraye",
      "Ligjerchathe Khalu Vishwamarchitham Snannasamshayaha",
      "Maayayaa Mohithaatmano Na Janatni Maheshwaram",
      "Vrudivyaam Yaani Theerthani Punyaanyaayatanani Cha",
      "Shivaligje Vasatnveya Taani Sarvani",
    ],
    firstHeading: null,
    firstDesc: (
      <>
        The supreme god manifested himself as the Lingam. All the worlds in the
        universe lie within the Lingam. This lingam holds the power of all three
        supreme gods. This is why performing Archana to the lingam is greater
        than performing Archana to the idol. Lingarchana is performing Archana
        to the whole world. All the holy places and all of the universe is
        within the Lingam.
        <br /> <br />
        Umamaheshwara himself in Srisaila Khandam stated that the Mahalingam,
        Jyotirlangam, Mallikarjuna Swamy Lingam has all the significance that's
        mentioned above.
      </>
    ),
    secondbanner: null,
    secondHeading: null,
    secondDesc: (
      <>
        Rudraadhyayam captures the glory of Lord Rudra and his significance.
        Tanme Manaha Shivasankalpamastu. Hence, Rudraanuvaakam recited during
        Rudrabhishekam at the centre of the world, the Sri Chakra, abode to the
        Vedas, Crown place of all mantras, which is Srisaila Devasthanam,
        inhibited by Sri Mallikarjuna Bhramaramba liberates devotees from all
        hurdles, renders the wisdom of Lord Shiva and invites fortune. It is
        also notable that Rudra Abhishekam offered at Srisailam showers endless
        positive outcomes and the divine blessings of Sri Mallikarjuna Swamy and
        Bhramaramba. As the devotees are not supposed to enter into Sanctum
        right in this scenario, those who want to offer Abhishekam to Sri
        Mallikarjuna MahaLingam can participate in Samuhika Abhishekamulu via
        Pratyaksha Seva. With this, devotees can participate in Rudram
        recitation and the holy water touched by Devotees is used in Abhishekam
        of Sri Mallikarjuna Swamy in sanctum.
        <br />
        <br />
        So all devotees are requested to abide by the Devasthanam regulations
        and participate in Samuhika Abhishekamulu through Pratyaksha Seva.
      </>
    ),
  },
  vruddhamallikarjunaswamyvaariabhishekam: {
    firstbanner: ["Seve Shrigiri Mallikarjunamahalingam Shivalingintham"],
    firstHeading: null,
    firstDesc: (
      <>
        In Shivanandalahari, Sri Aadi Shankaracharyulu stated that among all the
        Jyotirlingams, Mallikarjuna Swamy Lingam is the Maha Lingam.
        <br />
        <br />
        "Abhishekapriya Shivaha"
        <br />
        Shiva is fond of Abhishekam
        <br />
        <br />
        "Layanalingamuchyate"-Where it all comes together. This is another
        meaning if Lingam. Where all the universe comes together is Lingam.
        <br />
        "Leeyamaanamidham Sarvam Bhrahmanyeva Hi Liyate"- All the universe
        becomes one in Lord Shiva.
      </>
    ),
    secondbanner: (
      <>
        Lingagarbham Jagatsarvam Treilokyam Cha Charaacharam
        <br />
        Lingabahyatparam Nasti Tallingam Cha Prapoojayeth.
      </>
    ),
    secondHeading: null,
    secondDesc: (
      <>
        All the universe is at the core of Lingam. There is nothing without
        Lingam. All the universe belongs to Shiva's Lingam.
        <br />
        <br />
        Lokam Lingatmakam gnaatvaa Linge Yaarchayanthe Hi Maam Na Me
        Tasmatruyathaha Priya Va Vidyathe Kvachith The one who learns that all
        the universe is in Lingam and performs Abhishekam to Lingam, there would
        be no one dearer to me than that learned soul, says Lord Shiva. All the
        letters in all the mantras become one in Lingam says Rudraadhyayam. All
        gods lie within the Lingam. Just how a tree grows from a seed, all gods
        are pleased when we perform Abhishekam to the Lingam. Shruti Lingayame
        Namaha, Smruthi Lingayame Namaha, Purana Lingayame Namaha, Surya
        Lingayame Namaha, say Vedas. This is how powerful and significant the
        Shiva Lingam is. Shiva spoke of the universe as filled with the power of
        fire. Agni(light), Somam(fluid, water). The light is found abundantly in
        the universe.
        <br />
        <br />
        Somam is Shakti(power), Agni is a powerful light.
        <br />
        <br />
        This is Yagnatattva. The elements used in Yagna are forms of fire. They
        perish at the feet of Shiva. Fire is the root of power and power if the
        root of fire. Water on the earth evaporates under the heat of the sun
        and comes backs as raindrops that feed the living. The heat along with
        water acts as a source of energy for plants, which them become an energy
        source for all living. This way, it can be said that the universe is
        filled with energy points that originate because of the fire element.
        <br />
        <br />
        The same Tattva is used in Yagna and Abhishekam. Since Lord Shiva
        represents the Fire element, the Ganga on his head represents the water
        element. Together they are an unstoppable force.
        <br />
        The elements used in Abhishekam are all in liquid forms, milk, water,
        honey, ghee and fruit juices.
        <br />
        <br />
        Just how our hearts are filled with many emotions, many elements with
        spiritual significance are used in Abhishekam which come back as
        theertham for devotees to relish and fill their souls with positive
        emotions. Prior to Shiva Archana or Abhishekam, a ritual called
        Mahasnanam is performed where the body is cleansed, purified and filled
        with thoughts of Rudra. Tanme Manasshiva Sankalpamastu, Shirasesvaha,
        Netrabhyamnamaha: Such mantras are recited and recalled in thoughts.
        After the ritual, one is filled with thoughts of Shiva alone and then
        Rudranamakam and Rudrachamakam are offered in Abhishekam.
        <br />
        <br />
        Hence, Rudra Abhishekam offered at the centre of the world, the Sri
        Chakra, abode to the Vedas, Crown place of all mantras, which is
        Srisaila Devasthanam, inhibited by Sri Mallikarjuna Swamy and
        Bhramaramba liberates devotees from all hurdles, renders the wisdom of
        Lord Shiva and invites fortune. It is also notable that Rudra Abhishekam
        offered at Srisailam showers endless positive outcomes and the divine
        blessings of Sri Mallikarjuna Swamy and Bhramaramba. So all devotees are
        requested to abide by the devasthanam regulations, offer Rudra
        Abhishekam and earn the blessings of Sri Mallikarjuna Swamy and
        Bhramaramba here at Srisailam.
      </>
    ),
  },
  srisakshiganapathihomam: {
    firstbanner: [
      "Vaagee Shaadyaaha Sumanasaha Sarvardhanamupakrame Yam Nathvaa Krutakrutyaasuhu tam namami gajananam",
      "Vighnesha Vidhimarthanda Chandrendropendravanditha Namo Ganapthe Thubhyam Brahmanaam Brahmanaspathe",
    ],
    firstHeading: ["Suraadhyakshaya", "Namaha"],
    firstDesc: (
      <>
        The above said valuable lines explain the significance of Lord
        Ganapathi. The Akshara Shastra suggests that the letter 'Ga' is the most
        powerful. And, it is also known through Aagama Sastras that 'Ganeshaha
        Tarpanapriyaaha'. The lord Ganapathi according to Mantra Sastras can be
        worshipped in 32 ways and 56 avatars. A complete prayer is that when
        Lord Ganapathi is recalled in 440 mantras. Devotees who idolise any lord
        must worship Lord Ganapathi first. Success is rewarded when those who
        don't worship any lord, worship Lord Ganapathi. Success is not rewarded
        to those who worship other gods but don't offer prayers to Lord
        Ganapathi. This is why Lord Ganapathi earned the names, 'First of all'
        and 'The most important one'. Hence, called by the name
        Suradhyaakshaaya.
      </>
    ),
    secondbanner: [
      <>
        Tham Ganapathim Yo Vismarathi Loke <br />
        Santhathamu Bhagyo Majjathi Sasoke
      </>,
    ],
    secondHeading: null,
    secondDesc: `Sakshi Ganapathi, the beloved son of Lord Shiva and Goddess Parvathi resides at a temple, located 2 km east of the main temple. Since Ganapathi presents proof that each devotee has visited Srisailam to Lord Shiva in Kailasa, he is called Sakshi Ganapathi.
    He is sculpted beautifully out of a black stone. His trunk is folded to his left and seems to write down the names of the devotees who visit Srisailam. The devotees who worship Srisailam visit Sakshi Ganapathi and tell him their Gotra names.
    Devasthanam organises Pratyaksha seva facility for devotees to take part in Ganapathi homam that takes place here. Also, daily prayers and rituals are offered here. So all devotees are requested to abide by the devasthanam regulations and worship the ancient and significant Sakshi Ganapathi at Srisaila MahaKshetram.`,
  },
  rudrahomam: {
    firstbanner: [
      `Shiva Shivethi Shivethi Shivethi Vaa
    Bhava Bhavethi Bhavethi Bhavethi Vaa`,
      `Haraa Harethi Harethi Harethi Vaa
    Bhaja Manaha Shivameva Nirastharam`,
    ],
    firstHeading: null,
    firstDesc: (
      <>
        The quintessence of god is truly described in the above Veda. Ananthaa
        Vai Vedaaha, by following this Veda, Vedavyasa has divided Vedas into
        four parts from its vastness as the following: 1.Samhita 2.Aranyakam
        3.Brahmanam 4. Upanishad.
        <br />
        Among these Samhita comes first in the form of Rig, Yajus, Saama, and
        Atharva. These four are recited as four Vedas. Yajurvavedam is the heart
        of all these four Vedas. Rudranamakam has originated from this Veda
        under the names of Rudraikadashi, Sri Rudram, and Rudhraadhyayam.
        1.Although Rudraadhyayam explains the significance of god, Rudranamakam
        must be considered as a god itself while reciting it.
        <br />
        2. In Yajurveda, Rudranamakam has been pronounced as Karma Kanda, the
        same has been pronounced in Aranyakam and Brahmanam as Upaasana Kanda
        and as Gyana Kanda in Upanishad. Hence, Rudraadhyayam has been
        pronounced as Karma, Gyaana, and Upaasana Kanda in the Vedas. The Veda
        itself declared it as Shatha Rudriyam.
        <br />
        All the avatars of god have been clearly declared in Rudranamakam.
        <br />
        3.In Jabalopanishad, Gods versed in Brahma Tattva asked Sage Yajnavalkya
        'Kim Jupyena Amrutatvam Bruhi', to which the sage answered;
        Shatarudraayenethi, Eethaani Hava Amrutasya Naamaani, Ethena hava,
        Amrutattvam Bhavathi'. Which clearly states that with the above
        Rudranamakam if a Homam is performed, one would attain Amrutattvam.
        Similarly, in Skanda Puranamu Suthasamhita...
      </>
    ),

    secondbanner: [
      <>
        Shatashaakaghatham Saakshaat Shatarudriyamutthamam Tasmaat
        Tajjapamaathrena Sarvapapaihi Pramuchyate <br />
        Imaani Me Rahasyaani Naamani Shrunu Chaanagha Sarva Vedeshu Geethani,
        Samsaarashamanani Cha
      </>,
    ],
    secondHeading: `From the above lines, in Vedas and all ancient holy scripts, Rudranamakam has been pronounced as the
    essence of god and to be recited during Homam and Archanas.`,
    secondDesc: (
      <>
        Rudra Homam Sastrass describe this world as the oneness of fire and
        water(Agnisomatmakam). Agni(Light), Somamu(liquid, water). Agni is
        abundantly available in the form of light and electricity.
        Somam-Powerful, Agni- Powerful light. This is Yagnatattvam. Elements of
        Yagna are powerful forms. These elements unify with the god who is an
        embodiment of fire. Shakti is the pillar for Agni and Agni is the pillar
        for Shakti. Rudranamakam is the essence of god itself and is of such
        significance and is being recited and prayed through Rudra Homam by
        gods, sages, yogis, and gurus since ancient times at the center of the
        world, the Sri Chakra, which is Srisaila Devasthanam, inhibited by Sri
        Mallikarjuna Swamy and Bhramaramba. Hence it is fair to say when
        devotees perform Rudra Homam at Srisailam, it is equivalent to Archana
        of the god himself. Thus devotees can offer Rudra Homam at Srisaila
        Devasthanam via Pratyaksha Seva. Rudra Homam bestows redemption from
        sins, prevents uncalled troubles, and destroys all faults in your stars
        while showering the divine blessings of Sri Bhramaramba sametha
        Mallikarjuna Swamy.
      </>
    ),
  },
  lakshabilvarchana: {
    firstbanner: null,
    firstHeading: null,
    firstDesc: (
      <>
        <br />
        <br />
        Bilva tree is commonly known as Beal or Bengal Quince tree. The tree is
        also referred to as Sri Vruksham, Shivapriyam, Shriphalam and
        Shandilyam. Since it originated from the southern hand of Lakshi Devi,
        it is called Sri Vruksham. Since it is very dear to Lord Shiva, it is
        called Shivapriyam, as it cures many diseases, it's called Bilvam. Since
        gods wear it, it is Shriphalam, as Shandilya Maharshi worshipped the
        tree it's called Shandilyam.
        <br />
        <br />
        Maharshi's believe that this tree is a divine wish-fulfilling tree. The
        Vedas suggest that immense positive results stem from worshipping,
        offering prayers, Abhishekam and planting the trees. Bilva Tree
        originated from Lord Shiva just how the Sun originated from him. To
        bless and fulfil the wishes of devotees the tree has originated from the
        god. The origin of this tree is described in Shrisuktam 6th Bhu as:
      </>
    ),
    secondbanner: (
      <>
        Adityavarne Tapasodhijato Vanaspatistava vrukshodhabilvaha
        <br />
        Tasyaphalani Tapasanudantu Maayantaraayashcha Bahyaa Aalakshmihi
      </>
    ),
    secondHeading: null,
    secondDesc: (
      <>
        O Goddess who shines like the brightness of the sun! The Bilva tree took
        birth from your powers. The preachers prayed to the goddess by saying
        'May the fruits of Bilva tree eradicate the evils within us and give
        birth to positivity and knowledge.' "Bilvo Lakshmanyaha Kare Bhaveth"
        <br />
        <br />
        Vaamanapurana suggests that the Bilva tree took birth from the right
        hand of Sri Devi who was performing Tapasya in the name of Lord Shiva.
        <br />
        "Tanumadhyaam Svaahasthodtha Bilvadrumamapashritha"
        <br />
        Shrimanmahalakshmi- Bhargava Thantram suggests that the tree originated
        from her hand. The tree that is dear to Lord Shiva hence earned the name
        Sri Vruksham.
        <br />
        In Skanda Puranam, Bilvavanamahatvam is very well described if one
        offers Bilvarchana in Srisailam to Sri Mallikarjuna Swamy in the
        presence of Sanatkumara.
        <br />
        Goddess Lakshmi accompanied her husband, Lord Vishnu in an intense
        Tapasya in the name of Lord Shiva. The Tapasya was performed to save &
        protect the world, during which the tree took birth from the goddesses
        hand. Hence the name Sri Vruksham.
        <br />
        Lord Vishnu then used the leaves of the Bilva tree to perform Trikarana
        Shuddhi which pleased Lord Shiva. He then granted Lord Vishnu the power
        to protect the world.
        <br />
        Lord Vishnu in Kailasa, Vaikuntam, heaven, by many Maha Rishis, munis
        and on earth by many holy places and people, the Bilva tree is being
        planted.
      </>
    ),
    thirdBanner: (
      <>
        Gnanapradhamimam Vruksham Daaridryapariharakam
        <br />
        Shripradham Yaarchayedh Bilvam Sa Naro Bhagyavanakaveth
      </>
    ),
    thirdDesc: (
      <>
        Worshipping of the Bilva tree in Srisailam and offering Bilvarchana to
        Mallikarjuna Swamy is said to have the powers of eliminating all the
        evil, hurdles and fills one life with fulfilled wishes, positivity, good
        luck and prosperity. Also in Mahabilvarchana Strotram,
        <br />
        <br />
        Aneka Daanaphaladam Ananta Sukrutaadikam
        <br />
        Teerthayaatrakhilam Punyam Ekabilvam Shivarpanam...
        <br />
        <br />
        If a Bilvarchana has such powers what would a Bilvarchana with one lakh
        Bilva leaves do to one's destiny is beyond words. This is the reason the
        idol of Lakshmi Devi exists in the Grabhalaya of Mallikarjuna Swamy
        Temple.
        <br />
        <br />
        Hence, Bilvarchana offered at the centre of the world, the Sri Chakra,
        abode to the Vedas, Crown place of all mantras, which is Srisaila
        Devasthanam, resided by Sri Mallikarjuna Swamy and Bhramaramba liberates
        devotees from all hurdles, renders good luck, knowledge, prosperity and
        invites fortune. It is also notable that Bilvarchana offered at
        Srisailam showers endless positive outcomes and the divine blessings of
        Sri Mallikarjuna Swamy and Bhramaramba. So all devotees are requested to
        abide by the devasthanam regulations, offer Bilvarchana and earn the
        blessings of Mallikarjuna Bhramaramba Devi here at Srisailam.
      </>
    ),
  },
  mahanyasapurvakarudrabhishekam: {
    firstbanner: null,
    firstHeading: null,
    firstDesc: (
      <>
        <br />
        <br />
        The supreme god manifested himself as the Lingam. All the worlds in the
        universe lie within the Lingam. This lingam holds the power of all three
        supreme gods. This is why performing Archana to the lingam is greater
        than performing Archana to the idol. Lingarchana is performing Archana
        to the whole world. All the holy places and all of the universe is
        within the Lingam. The significance of Lingarchana
      </>
    ),
    secondbanner: (
      <>
        Nasthi Ligjarchanaath Punyamadhikam Bhuvanatraye
        <br />
        Ligjerchathe Khalu Vishwamarchitham Snannasamshayaha
        <br />
        Maayayaa Mohithaatmano Na Janatni Maheshwaram
        <br />
        Vrudivyaam Yaani Theerthani Punyaanyaayatanani Cha
      </>
    ),
    secondHeading: null,
    secondDesc: (
      <>
        Shivaligje Vasatnveya Taani Sarvani... Umamaheshwara himself in Srisaila
        Khandam stated that the Mahalingam, Jyotirlangam, Mallikarjuna Swamy
        Lingam has all the significance that's mentioned above.
      </>
    ),
    thirdBanner: (
      <>
        Shatashaakaghatham Saakshaat Shatarudriyamutthamam
        <br />
        Tasmaathajjapamaathrena Sarvapapaihi Pramuchyate
        <br />
        Abhishekapriyo Shivaha
        <br /> Vrukshasya Moola sekeena shaakaaha Pushyanti Vai Yadha
        <br />
        Shive Rudre Japaathpreethe Pritha Evasya Devataaha.
      </>
    ),
    thirdDesc: (
      <>
        Just how the branches of a tree strengthen when you water the roots,
        with Rudyaadyaayajapam, Patanam and Abhishekam, Lord shiva and all gods
        associated with him shall be pleased. Hence Rudraadhyaya Abhishekam
        rewards outstanding results. Na Rudro Rudramarchayeth
        <br />
        <br />
        (One who isn't a Rudra can not offer Archana to Lord Rudra)
        <br />
        Sri Rudra is the mightiest god. He is regionally called by many names
        like Shivudu, Shankarudu, Mrududu, Pashupati, Bhavudu, etc. Lord Rudra
        is the kindest, wise and omnipresent. His glory is beautifully described
        in many Vedas and Puranas. Atti Rudrunistotramu has got great
        significance in Rudradhyaayam. Krishna Yajurveda Taitariyasamhitha,
        Chaturthaakaandam, Panchama Prapaatakam Namakamu, Saptamaprapaatakam
        Chamakamu. The full glory of the god is integrated in Rudraadhyaama
        Namaka-Chamakaadam. Also Chamakams compliments as teachings of peace for
        Namakams. So, Namaka-Chamakams are integral part of abhishekams, japams,
        homams and paaraayanams. Firstly, Rudhyaadhyayamu
        <br />
        <br />
        1.Pradhavaanuvaakam is the prayer to Lord Rudhra
        <br />
        2.From Dvitiyaanuvaakam to Navamaanuvaakam, Lord Rudra is addressed as
        the one with the world and omnipresent mighty lord. In Ashtamaanuvaakam
        Namah Shivaaya, the Shiva Panchaakshari mantram originates.
        <br />
        3.Dashamaanuvaakam is Lord Rudras prayer.
        <br />
        4.Ekaadasaanuvaakam is the prayer to Lord Rudra and his allies.
        <br />
        Rudraadhyayam in Yajurvavedam, which is the heart of all four Vedas. has
        been pronounced as Karma, Gyaana and Upaasana Kanda in the Vedas.
        <br />
        <br />
        Rudraadhyayam captures the glory of Lord Rudra and his significance.
        Tanme Manaha Shivasankalpamastu. Hence, Rudraanuvaakam recited during
        Rudhraabhishekam at the centre of the world, the Sri Chakra, abode to
        the Vedas, Crown place of all mantras, which is Srisaila Devasthanam,
        inhibited by Sri Mallikarjuna Swamy and Bhramaramba liberates devotees
        from all hurdles, renders the wisdom of Lord Shiva and invites fortune.
        It is also notable that Rudra Abhishekam offered at Srisailam showers
        endless positive outcomes and the divine blessings of Sri Mallikarjuna
        Bhramaramba. So all devotees are requested to abide by the devasthanam
        regulations and participate in Rudrabhishekam through Pratyaksha and
        Paroksha Seva's.
        <br />
      </>
    ),
  },
  kumkumapoojasrichakram: {
    firstbanner: null,
    firstHeading: null,
    firstDesc: (
      <>
        <br />
        <br />
        The word 'Amma' comes from the two letters 'A' 'Ma', whereas in
        Sanskrit, 'A' 'Ba' form the word 'Amba'. While 'A' comes from a place of
        birth of all letters, the epiglottis and letters 'Ma' and 'Ba' come from
        the place where all letters find their destination, the lips. Hence, the
        word 'Amba' or 'Amma' serve as the origin point of all beginnings and
        endings. The significance of the word 'Amma' in Vedas started as
        'Maatrudevobhava'. Also,
      </>
    ),
    secondbanner: (
      <>
        Maathaa Poorvaroopam Pithotthararoopam Prajasandhihi
        <br />
        (Thaitthareeyaparishad- Shikshavalli)
      </>
    ),
    secondHeading: null,
    secondDesc: (
      <>
        For the worldly formation, the mother is the prior form and the father
        is the latter form. The two forms come together to give rise to all of
        the living. The powerful mother and father forms come together to create
        the whole world. The mantra above says that Lord Shiva-the father,
        Shakthi-the mother, The World-the result of their divine coming
        together.<br></br>
        <br />
        The kind avatar of Lord Shiva, Aruna is protecting the devotees with
        kindness. Based on this, Shankaracharya in Soundaryalahari has laid down
        the same as, Jayathi Karuna Kaachidharuna. The Vedas also suggest that
        the body of goddess Shakthi is in the colour of Aruna which i.e., with
        the glory of Kukumarchana. That's why...
      </>
    ),
    thirdBanner: (
      <>
        Arunaam Karunatarangitaakshim Sakumkumavilepanaam <br />
        Arunimasarvasya Sampradaayena Rakthivarnaa
        <br />
        Tripuraam Kulanidhimidei Arunishriyam
      </>
    ),
    thirdDesc: (
      <>
        This describes the colour of the Goddess as Aruna colour. Brahma has
        taken a particle from the dust beneath the Goddess Shakti's foot to
        create the world, Lord Vishnu protects the world while Lord Shiva
        destroys it into ashes. If goddess Shakti is the avatar of kindness, the
        dust particle beneath her feat possesses kindness as well. Hence, the
        world itself is kind. Lord Shiva takes kindness in the form of ashes and
        smears on his body. Hence, in Lord Shiva's temple, you find ashes and at
        Goddess Shakti's temple, you find Kumkuma.
        <br />
        <br />
        Kumkuma is the most blessed and auspicious ingredient for the goddess
        Shakthi. 'Kum Aithi Shabdham Karothi' Aithi Kumkuma. This says that when
        turmeric is exposed to the sun, it makes the sound, 'Kum'. Kumkuma is
        the colour of kindness(Arunavarnam). The colour of kindness(Arunavarnam)
        is the signal of kindness for the goddess Shakthi. The kindness of the
        goddess is also called as love. The word Kumkuma is the seed of mantras.
        So, Kumkuma is worn after worshipping the goddess. Also wearing Kumkuma
        at the centre of the forehead or mid-brow region opens up portals to
        true awakening. It also helps devotees to conquer the Rajo Gunam(mode of
        passion) and change their lives for good.
        <br />
        <br />
        Kumkumarchana when performed in Srisailam, which is considered as an
        incarnation of Kailasa, the centre of the world, and in the presence of
        Arunasurasamhari, Nadha Swarupini, Sri Vidya Swarupini, Sri Mallikarjuna
        Bhramaramba Devi, at the Sri Chakram established by spiritual master Adi
        Shankaracharya in the Bhramaramba Devi Temple, renders the complete
        positive outcomes, life filled with good luck, good fortune, and wishes
        fulfilled by Padarchana of Bhramarambika Devi. So devotees are requested
        to abide by the devasthanam regulations and attend the
        Pratyaksha-Paroksha Sevas, have the fortunate offering of Kumkumarchana
        and earn the complete blessings of the mother of the universe!
      </>
    ),
  },
  kumkumarchanaantharalayam: {
    firstbanner: null,
    firstHeading: null,
    firstDesc: (
      <>
        <br />
        <br />
        The word 'Amma' comes from the two letters 'A' 'Ma', whereas in
        Sanskrit, 'A' 'Ba' form the word 'Amba'. While 'A' comes from a place of
        birth of all letters, the epiglottis and letters 'Ma' and 'Ba' come from
        the place where all letters find their destination, the lips. Hence, the
        word 'Amba' or 'Amma' serve as the origin point of all beginnings and
        endings. The significance of the word 'Amma' in Vedas started as
        'Maatrudevobhava'. Also,
      </>
    ),
    secondbanner: (
      <>
        Maathaa Poorvaroopam Pithotthararoopam Prajasandhihi
        <br />
        (Thaitthareeyaparishad- Shikshavalli)
      </>
    ),
    secondHeading: null,
    secondDesc: (
      <>
        For the worldly formation, the mother is the prior form and the father
        is the latter form. The two forms come together to give rise to all of
        the living. The powerful mother and father forms come together to create
        the whole world. The mantra above says that Lord Shiva-the father,
        Shakthi-the mother, The World-the result of their divine coming
        together.<br></br>
        <br />
        The kind avatar of Lord Shiva, Aruna is protecting the devotees with
        kindness. Based on this, Shankaracharya in Soundaryalahari has laid down
        the same as, Jayathi Karuna Kaachidharuna. The Vedas also suggest that
        the body of goddess Shakthi is in the colour of Aruna which i.e., with
        the glory of Kukumarchana. That's why...
      </>
    ),
    thirdBanner: (
      <>
        Arunaam Karunatarangitaakshim Sakumkumavilepanaam <br />
        Arunimasarvasya Sampradaayena Rakthivarnaa
        <br />
        Tripuraam Kulanidhimidei Arunishriyam
      </>
    ),
    thirdDesc: (
      <>
        This describes the colour of the Goddess as Aruna colour. Brahma has
        taken a particle from the dust beneath the Goddess Shakti's foot to
        create the world, Lord Vishnu protects the world while Lord Shiva
        destroys it into ashes. If goddess Shakti is the avatar of kindness, the
        dust particle beneath her feat possesses kindness as well. Hence, the
        world itself is kind. Lord Shiva takes kindness in the form of ashes and
        smears on his body. Hence, in Lord Shiva's temple, you find ashes and at
        Goddess Shakti's temple, you find Kumkuma.
        <br />
        <br />
        Kumkuma is the most blessed and auspicious ingredient for the goddess
        Shakthi. 'Kum Aithi Shabdham Karothi' Aithi Kumkuma. This says that when
        turmeric is exposed to the sun, it makes the sound, 'Kum'. Kumkuma is
        the colour of kindness(Arunavarnam). The colour of kindness(Arunavarnam)
        is the signal of kindness for the goddess Shakthi. The kindness of the
        goddess is also called as love. The word Kumkuma is the seed of mantras.
        So, Kumkuma is worn after worshipping the goddess. Also wearing Kumkuma
        at the centre of the forehead or mid-brow region opens up portals to
        true awakening. It also helps devotees to conquer the Rajo Gunam(mode of
        passion) and change their lives for good.
        <br />
        <br />
        Kumkumarchana when performed in Srisailam, which is considered as an
        incarnation of Kailasa, the centre of the world, and in the presence of
        Sri Bhramaramba sametha Mallikarjuna Swamy, renders the complete
        positive outcomes, good fortune, and also showers the blessings of Sri
        Bhramaramba sametha Mallikarjuna Swamy. Devotees can offer Kumkumarchana
        as Pratyaksha Seva is offered at Aseervachana Mandapam, beside Sri
        Bhramaramba Devi Temple.
      </>
    ),
  },
  lakshakumkumarchana: {
    firstbanner: null,
    firstHeading: null,
    firstDesc: (
      <p>
        <br />
        <br />
        The word 'Amma' comes from the two letters 'A' 'Ma', whereas in
        Sanskrit, 'A' 'Ba' form the word 'Amba'. While 'A' comes from a place of
        birth of all letters, the epiglottis and letters 'Ma' and 'Ba' come from
        the place where all letters find their destination, the lips. Hence, the
        word 'Amba' or 'Amma' serve as the origin point of all beginnings and
        endings of all other words.
        <br /> The significance of the word 'Amma' in Vedas started as
        'Maatrudevobhava'.
      </p>
    ),
    secondbanner: "Maathaa Poorvaroopam Pithotthararoopam Prajasandhihi",
    secondHeading: null,
    secondDesc: (
      <p>
        For the worldly formation, the mother is the prior form and the father
        is the latter form. The two forms come together to give rise to all of
        the living. The powerful mother and father forms come together to create
        the whole world. The mantra above says that Lord Shiva-the father,
        Shakthi-the mother, The World-the result of their divine coming
        together.
        <br />
        The kind avatar of Lord Shiva, Aruna is protecting the devotees with
        kindness. Based on this, Shankaracharya in Soundaryalahari has laid down
        the same as, Jayathi Karuna Kaachudharuna. The Vedas also suggests that
        the body of goddess Shakthi is in the color of Aruna which i.e., with
        the glory of Kukumarchana.
      </p>
    ),
    thirdBanner: (
      <>
        Arunaam Karunatarangitaakshim.... Sakumkumavilepanaam...
        <br />
        Arunimasarvasya Sampradaayena... Rakthivarnaa...
        <br />
        Tripuraam Kulanidhimidei Arunishriyam...
      </>
    ),
    thirdDesc: (
      <p>
        Brahma has taken a particle from the dust beneath the Goddess Shakti's
        foot to create the world, Lord Vishnu protects the world while Lord
        Shiva destroys it into ashes. If goddess Shakti is the avatar of
        kindness, the dust particle beneath her feat possesses kindness as well.
        Hence, the world itself is kind. Lord Shiva takes kindness in the form
        of ashes. Hence, in Lord Shiva's temple, you find ashes and at Goddess
        Shakti's temple, you find Kumkuma.
        <br />
        <br />
        Kumkuma is the most blessed and auspicious ingredient for the goddess
        Shakthi. 'Kum Aithi Shabdham Karothi' Aithi Kumkuma. This says that when
        turmeric is exposed to the sun, it makes the sound, 'Kum'. Kumkuma is
        the colour of kindness(Arunavarnam). The colour of kindness(Arunavarnam)
        is the signal of kindness for the goddess Shakthi. The kindness of the
        goddess is also called love. The word Kumkuma is the seed of mantras.
        So, Kumkuma is worn after worshipping the goddess. Also wearing Kumkuma
        at the centre of the forehead or mid-brow region opens up portals to
        true awakening. It also helps devotees to conquer the Rajo Gunam(mode of
        passion) and change their lives for good.
        <br />
        <br />
        Kumkumarchana when performed in Srisailam, which is considered as an
        incarnation of Kailasa, the centre of the world, and in the presence of
        Sri Mallikarjuna Swamy and Bhramaramba, renders the complete positive
        outcomes, good fortune, and also showers the blessings of Sri
        Mallikarjuna Bhramarambika.
      </p>
    ),
  },
  sriswamyammavarlakalyanam: {
    firstbanner: null,
    firstHeading: null,
    firstDesc: (
      <>
        <br />
        <br />
        Sarva Seva Padhakam offered to Sri Bhramaramba Sametha Mallikarjuna
        Swamy at Srisaila Maha Kshetram is a blessing in disguise. This program
        involves Suprabhata Seva, Lord's Special Abhishekam, Kumkuma Pooja,
        Rudra Homam, Chandi Homam, Kalyanotsavam and Ekantha Seva offered on one
        day in the name of the ticket holder. The ticket holders of Sarva Seva
        Padhakam are offered free accommodation, meals and Sesha Vastram(ritual
        clothing) by the Devasthanam. Also, one ticket is applicable for an
        individual or one married couple who are offered Prasadam. On special
        days(Ugadi & Maha Shivaratri) the ticket availability depends on the
        devotee count.
      </>
    ),
    secondbanner: null,
    secondHeading: null,
    secondDesc: null,
  },
  chandihomam: {
    firstbanner: [
      "Durge Smrutha Harasi Bhitimaseshajantoha Swastaihi Smrutamatimativa Shubhaam Dadaasi",
      "Daaridriya Dhukha Bhayahaarini Kaatvadhanyaa Sarvopakaarakaranaya Dayaadhra Chitthaa",
    ],
    firstHeading: null,
    firstDesc:
      "O Devi, every soul who recollects you when they face hurdles shall be liberated of their fears. When those who are untroubled and healthy recall you, you gift them godly knowledge. Similarly, you eradicate the fears and misfortune of your devotees. Who else has got the golden heart to help all the living of the world other than you?",
    secondbanner: [
      <>
        Yaa Devi Sarvabhooteshu Maatrurupena Samstitha
        <br /> Namasthathyai Namasthathyai Namasthathyai Namo Namaha
      </>,
    ],
    secondHeading: null,
    secondDesc: (
      <>
        This shloka details the kindness of the universal mother over her
        devotees and preachers. The devotees shall gain abundant Vaagmayamu when
        prayed to Goddess Sridevi. There are many ways to traditionally pray
        her. Her preachers pray her with many emotions and many names like
        Durga-Chandi-Kaali-Baala-Lalitha-Rajarajeswari-Bagala-Chinnamastha and
        earn her blessings. <br />
        Sri Devi Mahathyamu is a supreme holy book for worshipping the goddess
        with Sri Durga-Chandi Namams. This holy book is also known as Durga
        Saptashathi and Chandi Saptashathi. This holy scripture admires the
        goddess in the form of Maha Kali-Maha Lakshmi-Maha Saraswathi and also
        the Sapthamatrika avatar. <br />
        <br />
        From the Vedas, devotees are bestowed with the knowledge of Karmakaanda
        and Upaasanakanda. To take a part of Puranas and perform Japam, Homam
        and Paraayanam and considering it as an equivalent to Vedas is a rare
        scenario. But, from Markandeya Puranam, from chapters 78 to 90, history
        of the goddess and shlokas have been given most prominence and the glory
        of the goddess has been pronounced equivalent to the Vedas. Also, the
        shlokas have been promoted as powerful mantras.
        <br />
        <br />
        Along with the 578 shlokas in the Durga Sapthashathi, Uvaachalu has been
        promoted as mantras after which, 700 shlokas have been derived. In Durga
        Sapthashathi, the avatars of Maha Kali-Maha Lakshmi-Maha Saraswathi has
        been gloriously described to complete the history of the goddess. The
        Homam is as dear to the goddess as the Durga Sapthashathi.
        <br />
        Also, the last Shakti addressed in Durga Sapthashathi is of Bhramari,
        who is none other than Bharamaraambika Devi.
        <br />
        'Bhraamareethi Cha Maam Lokaasthadha Sthoshyanthi Sarvataha.' This
        shloka clearly states that all Shaktis originated from Bhramari Shakti.
        Those who worship through Durga Sapthashathi Paarayanam and offer Homam
        shall reach their destinations and the world too comes to their feet.
        <br />
        <br />
        Srisaila Khandam clearly states that Maha Kali-Maha Lakshmi-Maha
        Saraswathi avatars described in Devimahatyam originated as one Shakti,
        i.e, Sri Bhramarambika Devi.
        <br />
        Hence, when the most significant Chandi Homam, which is equivalent to
        the Vedas is performed in the presence of universal guardians, Sri
        Mallikarjuna Bhramaramba at the Sri Chakram, i.e, Srisailam, via
        Pratyaksha Seva, the devotees are liberated from worldly troubles and
        earn the complete blessings of Sri Mallikarjuna Swamy and Bhramaramba.
      </>
    ),
  },
  abhishekamalamkaramandapamcoupleonlyalamkaradarshanam: {
    firstbanner: [
      "Nasthi Ligjarchanaath Punyamadhikam Bhuvanatraye",
      "Ligjerchathe Khalu Vishwamarchitham Snannasamshayaha",
      "Maayayaa Mohithaatmano Na Janatni Maheshwaram",
      "Vrudivyaam Yaani Theerthani Punyaanyaayatanani Cha",
      "Shivaligje Vasatnveya Taani Sarvani",
    ],
    firstHeading: null,
    firstDesc: (
      <>
        The supreme god manifested himself as the Lingam. All the worlds in the
        universe lie within the Lingam. This lingam holds the power of all three
        supreme gods. This is why performing Archana to the lingam is greater
        than performing Archana to the idol. Lingarchana is performing Archana
        to the whole world. All the holy places and all of the universe is
        within the Lingam.
        <br /> <br />
        Umamaheshwara himself in Srisaila Khandam stated that the Mahalingam,
        Jyotirlangam, Mallikarjuna Swamy Lingam has all the significance that's
        mentioned above.
      </>
    ),
    secondbanner: null,
    secondHeading: null,
    secondDesc: (
      <>
        Rudraadhyayam captures the glory of Lord Rudra and his significance.
        Tanme Manaha Shivasankalpamastu. Hence, Rudraanuvaakam recited during
        Rudrabhishekam at the centre of the world, the Sri Chakra, abode to the
        Vedas, Crown place of all mantras, which is Srisaila Devasthanam,
        inhibited by Sri Mallikarjuna Bhramaramba liberates devotees from all
        hurdles, renders the wisdom of Lord Shiva and invites fortune. It is
        also notable that Rudra Abhishekam offered at Srisailam showers endless
        positive outcomes and the divine blessings of Sri Mallikarjuna Swamy and
        Bhramaramba. As the devotees are not supposed to enter into Sanctum
        right in this scenario, those who want to offer Abhishekam to Sri
        Mallikarjuna MahaLingam can participate in Samuhika Abhishekamulu via
        Pratyaksha Seva. With this, devotees can participate in Rudram
        recitation and the holy water touched by Devotees is used in Abhishekam
        of Sri Mallikarjuna Swamy in sanctum.
        <br />
        <br />
        So all devotees are requested to abide by the Devasthanam regulations
        and participate in Samuhika Abhishekamulu through Pratyaksha Seva.
      </>
    ),
  },
  leelakalyanotsavam: {
    firstbanner: [
      "Pashyanthi Paripullapadmanayana Poornendubhimbaanana Pathyuha Premasamaakula Kulavadhoornatyotsavam Leelayaa",
      "Brahmemdraadi Suropalaalitha Gunam Bhakteshtadaanonmukam Yutkinchitkutukam Manassakalayaddhevi Shubhaayasthu Naha",
    ],
    firstHeading: null,
    firstDesc: (
      <>
        With eyes as large as lotus petals, the face that shines like the moon,
        the qualities lauded by the gods itself, may Bhramarambhika Devi with
        the love from the god Mallikarjuna Swamy shower all their blessings
        together upon us!
        <br />
        60th chapter of Srisaila Khandam describes the marital union of Lord
        Shiva and Goddess Parvathi. Lord Shiva means the provider of
        auspiciousness. Parvathi means the queen of the hills. Lord Shiva and
        Parvathi Devi complete each other to become a part of one another. The
        secret to such a powerful union occurs when the soul that is twined with
        pride, heart, intellect, etc reaches the higher stage of awakening. This
        kind of union is a mystic power. Lord Shiva and Goddess Parvathi
        followed austerity for each other to gain the mystic powers.
        <br />
        Their union is of the highest nature, beyond the gratification in
        physical form. The couple expresses their joy through dance thus
        presenting happiness as a boon to the devotees. Just how language is
        required to express the world, Goddess Parvathi is quintessential for
        the essence of Lord Shiva. Just as there is no body without a soul,
        there is no Lord Shiva without Goddess Parvathi. This is why Lord Shiva
        is addressed as 'Lord Who Is Half Woman' which signifies "totality that
        lies beyond duality". This is why Kalidasu said: 'Vagarthavivi
        Sampruktau...Jagatah Pitarau Vande Parvathiparameshwarau'
      </>
    ),
    secondbanner: <>Kotijanmaakrutaihi Punyeihi Shive Bhakthihi Prajaayathe.</>,
    secondHeading: `Devotees shall earn the good karma
    worth many lives at the feet of Lord Shiva. Lord Shiva and Goddess Parvathi are inseparable,
    that's why.`,
    secondDesc: (
      <>
        Kamesha baddha maangalya sutra shobhita kandhara...' The nuptial chain
        of goddess Parvathi is very powerful. Shiva preachers in
        Soundaryalahari..'Shivah Shaktya yukto yadi bhavati Shaktah
        prabhavitum.' 'Tava Janani Taatamkamahima'. 'Tvaya Hrutvaa Vaamam Vapuhu
        Aparitruptena manasaa.' 'Jaganmaatha Swaadhinavallabha'
        'Suvaasinarchanapreetha' 'Nijabhrarthumukaambhojachintanaaye Namo
        Namaha'. These lines describe Lord Shiva and Goddess Parvathi as a
        couple. The goddesses auspicious nuptial thread is described as:
        <br />
        "Mringedivaadu Vibhundani Mringedidiyu Garalamaniyu Mailani Prajakun
        Mringamune Sarvamangala Mangalasutrambunenta Madi Namminado.. In many
        such examples and also through games like Shiva-Sharvaani,
        Bhava-Bhavaani, Rudra-Rudraani, Mruda-Mrudaani, Eesha-Eeshwari, their
        marriage is signified. <br />
        With such mystic powers, Lord Shiva and Goddess Parvathi leave from
        Kailasa to earthy Kailasa, Srisailam as Sri Mallikarjuna Swamy and
        Bhramaramba to shower their blessings upon the world and stand as a
        shining example of a perfect marital couple. So offering Sri
        Mallikarjuna Swamy and Bhramaramba Leelakalyanam is equivalent to
        offering Kalyanam to the mother and father of this world. This Kalyana
        gifts marital bliss, fortune, prosperity. When devotees see the marital
        rituals and hear the mantras, they earn good karma and complete
        blessings from Sri Bhramaramba and Mallikarjuna Swamy. Kalyanam offered
        at Srisailam is unique as the Kalyanam is offered to the lord and
        goddess in their purest forms as Lord Shiva and Goddess Parvathi during
        Sankranti and to their powerful avatars, i.e, Sri Bhramaramba and
        Mallikarjuna Swamy during Maha Shivaratri. This kind of ritual is
        nowhere to be found except at Srisaialam. So devotees can now offer Sri
        Mallikarjuna Swamy and Bhramaramba Leelakalyanam through Pratyaksha Seva
        organised by Srisaila Devasthanam.
      </>
    ),
  },

  ekanthaseva: {
    firstbanner: null,
    firstHeading: null,
    firstDesc: (
      <>
        <br />
        <br />
        Ekantha Seva is offered every day after the Kalyanotsavam of Sri
        Bhramarambika Devi & Mallikaruna Swamy. Devotees believe those who
        participate in Ekantha Seva are cleansed of Venus malefic effects,
        marital difficulties, and are gifted with marital bliss and family
        progress. This program involves Pushpotsavam and Nayanotsavan offered to
        the god and goddess. Ekantha Seva is offered every day during
        Chadrakantha at 09:30. Devasthanam offers Laddoo Prasadham to an
        individual or a married couple as applicable under a single ticket.
      </>
    ),
    secondbanner: null,
    secondHeading: null,
    secondDesc: null,
  },
  abhishekamatgarbhalayam: {
    firstbanner: null,
    firstHeading: null,
    firstDesc: null,
    secondbanner: null,
    secondHeading: null,
    secondDesc: null,
  },
  vipbreakdarshanam: {
    firstbanner: null,
    firstHeading: null,
    firstDesc: null,
    secondbanner: null,
    secondHeading: null,
    secondDesc: null,
  },

  vallidevasenasametasubramanyeswaraswamykalyanam: {
    firstbanner: [
      `Anekaarka Koti Prabhaavajjwalantam
    Manohari Maanikya Bhushojjwalantham`,
      `Srithanaamabhishtam Nishantam Nithantham
    Bhaje Shanmukham Tham Saraschandrakantham`,
    ],
    firstHeading: null,
    firstDesc: (
      <p>
        <span>
          In the glow of crore glowing suns, with all precious gems adorned, I'm
          worshipping the one who grants the wishes of devotees with a pleasant
          face as that of a moon.
          <br />
          The day Lord Shiva and Goddess Parvathi took little Subramanya Swamy
          under their wings on the day that's now celebrated as Margashira
          Shudha Shashti. Also, Skandapanchami and Kumarashashti are both of
          much importance. Subramanya Swamy has many names and here are a few:
        </span>
        <br />
        <br />
        <span style={{textAlign: "left", display: "flex"}}>
          Shanmukudu - The one with 6 heads
          <br />
          Mahasenudu - The general of god's army
          <br />
          Kumara Swamy - The child avatar
          <br />
          Dandapani - The one who holds the guard stick
          <br />
          Valli Nayaka - The one who married Valli
          <br />
          Devasenapathi - Husband to Indra's daughter Devasena
          <br />
          Guhudu - The one with a wealth of knowledge
          <br />
          Taarakaari - The one who killed Tarakasura
          <br />
          Kaartikeyudu - The one who's mothers are Kritikas
          <br />
          Subramanyudu - The one who gifts the wisdom of Brahma
          <br />
          Agniputrudu - The one who is protected by the Fire god
          <br />
          Kritikaputrudu- The son of six Kritikas
        </span>
        <br />
        <br />
        Subramanyaswamy's birth significance is to destroy evil. He's got two
        wives, Sri Valli and Devasena. Under the Burflower tree is his favourite
        place to be. He is pleased when devotees offer him the garland of
        Burflower tree flowers. He is also pleased when devotees offer
        <br />
        Bananas, Grapes, Dates, Anjeer and Mangoes. Similarly, when cow ghee,
        honey and Kalakand are mixed into sugarcane juice and offered along with
        the saffron flower, he is most happy. Peacock is his mount and is always
        seen with a staff/spear in his hand. His mother is the goddess of all
        existence and his avatar as Subramanya Swamy is the representation of
        Kundalini. While Ganapathi considered his parents as the whole world,
        Kumaraswamy considered that the whole world is Lord Shiva.
        <br />
        Srisaialam stands tall as the Maha Jyotirlingam, Shakti Peetam, world's
        centre, Home to Vedas, Kailasa on Earth and all the credit goes to the
        presence of Subramanyaswamy. Skanda Puranam and Shiva Puranam suggest
        that Srisailam is the first and foremost likeable home to Subramanya
        Swamy. At such a significant place, when Subramanyakalyanam is offered
        Kalyanam as Pratyaksha Seva in the presence of Sri Mallikarjuna Swamy
        and Bhramaramba, the devotees are freed from troubles and Sarpadosha,
        and also are gifted with the wisdom of Lord Brahma. So, we urge devotees
        to earn the complete blessings of Sri Bhramaramba sametha Mallikarjuna
        Swamy and Subramanya Swamy.
      </p>
    ),
    secondbanner: null,
    secondHeading: null,
    secondDesc: null,
  },
  mrityunjayahomam: {
    firstbanner: [
      "Doore Vaapi Sameepe Vaa Srisailotsavachintakaha Vimukta Sarvapapebhyo",
      "Gachhanthi Paramaam Gathim",
    ],
    firstHeading: null,
    firstDesc: `Those who stay far away or those who stay nearer, if one remembers the festivities at
    Srisailam, they would attain a higher path in life.`,
    secondbanner: (
      <>
        Hasthaambhojayugastha Kumbayugalaadudrutya Toyam Shiraha Shimchintam
        <br />
        Karayooryugena Dadatham Svanke Sa Kumbhow Karov Akshasrak
        <br />
        Mrugahasthamanjubhujagatam Moordhastha Chandrasravak Priyoosharthatanam
        <br />
        Bhajesha Girijam Treyksham Cha Mrityunjayam.
      </>
    ),
    secondHeading: null,
    secondDesc: (
      <>
        One of the avatars of Lord Shiva, i.e, of Mrityunjaya is clearly stated
        in the above shloka. Lord Mrityunjaya appears with his third eye and
        eight shoulders. The upper left and right arms of his hold a pitcher of
        Amrutham that is directed to be poured onto himself. One of his right
        hands holds a string of beads and one of his left hand is posed to show
        the seal of deer (Mrugi mudra). The remaining hands hold pitchers of
        amrutham Lord Shiva appears in Mrityunjaya avatar to rescue Markandeya,
        the son of Mrikandu Maharshi from the crutches of Yama. He not only
        saves Markandeya from untimely death but also pronounces him immortal.
        That's why.
      </>
    ),
    thirdBanner: (
      <>
        Chandrasekaramasraye Mama Kimkarishyathi Vai Yamaha Chandrasekhara
        <br />
        Chandrasekhara Chandrasekhara Paahimaam Chandrasekhara Chandrasekhara
        <br />
        Chandrasekhara Rakshamaam
      </>
    ),
    thirdDesc: (
      <p>
        Markandeya has sung the above lines. Mrityunjaya has three eyes: that of
        the sun, fire and moon. The Fire eye destroys the sins, Sun eye
        eradicates ignorance and the moon eye provides relief from soaring
        temperatures. Mrityunjaya Archana and Homam rescue the devotees from
        untimely death and defects in stars. When performed with
        Rudradhyaayanuvaakam and Moolamantra havanam, devotees shall gain the
        results of Rudra Homam and complete blessings of Lord Shiva. Mrityunjaya
        Homam when performed at the residence of devotees has its benefits but
        when performed in Srisailam, which is considered as incarnation of
        Kailasa, the centre of the world and in the presence of Sri Mallikarjuna
        Bhramarambha, renders the complete positive outcomes that of a Shiva
        Archana. This Homam not only protects devotees from untimely death but
        also showers the blessings of Sri Bhramaramba sametha Mallikarjuna
        Swamy. Devotees can participate in the Mrityunjaya Homam within the main
        temple via Pratyaksha Seva.
      </p>
    ),
  },
};
const SD_Pratyaksha_Seva_Inner_Component_One = ({data}) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const [index, setIndex] = useState(0);
  const [loading, setLoadin] = useState(true);
  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useffects-----------------------------------------------------------//
  useEffect(() => {
    setLoadin(true);
    if (data) {
      setIndex(
        data?.name
          ?.replace?.(/\s/g, "")
          ?.replace?.(/[{()}]/g, "")
          .toLowerCase()
      );
    }
    setLoadin(false);
  }, []);
  //-------------------------------------------------------------useffects-----------------------------------------------------------//
  return (
    <>
      {!loading ? (
        <div className="sd-gp-one">
          {BodyData[index]?.firstbanner !== null && (
            <div className="sd-first-banner">
              <div className="inner">
                <img
                  className="inner-image"
                  src={SD_highlets_border}
                  alt={SD_highlets_border}
                />
                <p>
                  {BodyData[index]?.firstbanner[0]}
                  {/* <br></br> */}
                  <br></br>
                  {BodyData[index]?.firstbanner[1]
                    ? BodyData[index]?.firstbanner[1]
                    : ""}
                </p>
              </div>
            </div>
          )}

          {BodyData[index] && (
            <div className="t-1">
              {BodyData[index]?.firstHeading !== null && (
                <div className="top">
                  <h1>
                    <span>{BodyData[index]?.firstHeading[0]}</span>
                    {BodyData[index]?.firstHeading[1]}
                  </h1>
                  <img src={SD_title_divider} alt={SD_title_divider}></img>
                </div>
              )}
              {BodyData[index]?.firstDesc !== null && (
                <h4 className="bottom">{BodyData[index]?.firstDesc}</h4>
              )}
            </div>
          )}
          {BodyData[index]?.secondbanner !== null && (
            <div className="sd-second-banner">
              <div className="inner">
                <img
                  className="inner-image"
                  src={SD_highlets_border}
                  alt={SD_highlets_border}
                />
                <p>{BodyData[index]?.secondbanner}</p>
              </div>
            </div>
          )}
          {BodyData[index] && (
            <div className="t-2">
              {BodyData[index]?.secondHeading !== null && (
                <div className="t-2-top">
                  <h1>{BodyData[index]?.secondHeading}</h1>
                  <img src={SD_title_divider} alt={SD_title_divider}></img>
                </div>
              )}
              {BodyData[index]?.secondDesc !== null && (
                <h4 className="t-2-bottom">{BodyData[index]?.secondDesc}</h4>
              )}

              {BodyData[index]?.thirdBanner && (
                <div className="sd-second-banner">
                  <div className="inner">
                    <img
                      className="inner-image"
                      src={SD_highlets_border}
                      alt={SD_highlets_border}
                    />
                    <p>{BodyData[index]?.thirdBanner}</p>
                  </div>
                </div>
              )}
              {BodyData[index]?.thirdDesc && (
                <h4 className="t-2-bottom">{BodyData[index]?.thirdDesc}</h4>
              )}
            </div>
          )}
          <div className="t-3">
            <img src={SD_divider} alt={SD_divider} />
            <h3>
              Sarvam Bhramaramba Sameta Mallikarjuna Charanaravindarpanamastu
            </h3>
            <img src={SD_divider} alt={SD_divider} />
          </div>
        </div>
      ) : (
        <Sd_loading />
      )}
    </>
  );
};
export default SD_Pratyaksha_Seva_Inner_Component_One;
