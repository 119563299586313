import Axios from "../Utils/sd-axios/Axios";
import {EncryptInformation} from "../Utils/sd-encrypt/encrypt";

const UserService = {
  createUserPass: async (phone, displayName, OTP, password) => {
    try {
      const resp = await Axios.post("/user/create/pass", {
        phone: phone,
        display_name: displayName,
        otp: parseInt(OTP),
        password: EncryptInformation(password),
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },

  isRegistered: async (phone) => {
    try {
      const resp = await Axios.get(`/user/is-registered?phone=${phone}`);
      return resp.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },

  guestLogin: async (phone, OTP) => {
    try {
      const resp = await Axios.post("/user/guest-login", {
        phone: phone,
        otp: parseInt(OTP),
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error(error?.response?.data?.message);
      } else if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },

  checkToken: async () => {
    try {
      const resp = await Axios.get("/user/check-token", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("unauthorized");
      } else if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  getUserDetails: async () => {
    try {
      const resp = await Axios.get("/user/details/withId", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("unauthorized");
      } else if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  otpogin: async (phone, otp) => {
    try {
      const resp = await Axios.post("/user/login/phone-otp", {
        phone: phone,
        otp: parseInt(otp),
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error(error?.response?.data?.message);
      } else if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  resetPassword: async (phone, otp, password) => {
    try {
      const resp = await Axios.post("user/password/forgot", {
        phone: phone,
        otp: parseInt(otp),
        password: EncryptInformation(password),
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("unauthorized");
      } else if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  userLogin: async (phone, password) => {
    try {
      const resp = await Axios.post("/user/login", {
        password: EncryptInformation(password),
        PhoneOrEmail: phone,
      });
      return resp.data;
    } catch (error) {
      if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  changePassword: async (old, pass, phone) => {
    try {
      const resp = await Axios.post("/user/password/reset", {
        oldPassword: EncryptInformation(old),
        phone: phone,
        newPassword: EncryptInformation(pass),
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("unauthorized");
      } else if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  editUserDetails: async (data) => {
    try {
      const resp = await Axios.post("/user/edit-details", data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("unauthorized");
      } else if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  getVisitors: async () => {
    try {
      const resp = await Axios.get("/analytics/visitors-count");
      return resp.data;
    } catch (error) {
      if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  addVisitors: async () => {
    try {
      const resp = await Axios.post("/analytics/visitors-count/inc");
      return resp.data;
    } catch (error) {
      if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
};

export default UserService;
