import React, {useState, useContext, useRef, useEffect} from "react";
import {
  sd_btn_loading,
  sd_dashboard_dob,
  sd_dashboard_side_arrow,
  SD_Dropdown_Arrow_icon,
} from "../../../../Assets/imageList";
import "../../../../Utils/sd-styles/sd-css/main.css";
import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
import IndiaTime from "../../../../Utils/sd-moment/IndiaTime";
import {SD_Context} from "../../../../context/sd-context";
import "../../../../Utils/sd-styles/sd-dashboard.scss";
import DatePicker from "react-date-picker";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ParokshaSevaService from "../../../../services/sd-parokshaseva.service";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
const CommonRegex = /^[#.0-9a-zA-Z\s,-/]+$/;
// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {BASE_BANK} from "../../../../Utils/sd-axios/sd-api-url";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const Sd_dashboard_parokshaseva_seva_details = ({history, match}) => {
  const [loading, setLoading] = useState(true);
  const {
    setActivePath,
    setActive,
    setCurrentBooking,
    currentBooking,
    parokshaSevaData,
    setParokshaSevaData,
    userData,
    devoteeList,
    logout,
  } = useContext(SD_Context);

  const [selectedlanguage, setSelectedlanguage] = useState("en-in");
  const [checkbox, setCheckbox] = useState(false);
  const [mobile, setMobile] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const [show, setShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedArray, setSelectedArray] = useState([]);
  const [occasion, setOccasion] = useState("");
  const [gothram, setGothram] = useState("");
  const [startDate, setStartDate] = useState("");
  const [nameArray, setNameArray] = useState([""]);
  const [err, setErr] = useState([]);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("India");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [autoFill, setAutoFill] = useState("none");
  const ref = useRef(null);
  const termsList = [
    `Paroksha Seva will be performed at Devasthanam with Devotee Gothra Namam read out in the name of those who booked the seva online.`,
    `After offering seva, Prasadham will be sent to Devotee’s Postal Address, which was filled out while online booking by the devotee.`,
    `Paroksha Seva is solely an online seva and pilgrims are not allowed to attend Pratyaksha seva at the templewith Paroksha seva tickets.`,
    `Devotees can witness the live telecast of Puja/Homa from their respective location through Srisaila Tv via YouTube, Facebook.`,
    `Bank Charges or Handling charges if any shall be payable by devotee only.`,
  ];

  function addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }

  useEffect(() => {
    setActive("online-booking-parokshaseva-enter-details");
    setActivePath("Onlinebooking-Paroksha Seva-Enter Details");
    setLoading(true);

    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }

    if (currentBooking) {
      // console.log("in");
      setCheckbox(false);

      let total = 0;
      currentBooking.selectedArray.map((item) => {
        total += item?.price;
      });
      setTotalCost(total);
      let array = [];
      currentBooking?.details?.map((item) => {
        array.push(item?.name);
      });
      setNameArray(array);
      setAddress1(currentBooking?.address1);
      setAddress2(currentBooking?.address2);
      setCity(currentBooking?.city);
      setState(currentBooking?.state);
      setCountry(currentBooking?.country);
      setZip(currentBooking?.zip);
      setMobile(currentBooking?.mobile);
      setGothram(currentBooking.details[0].gothram);
      currentBooking?.occasion && setOccasion(currentBooking.occasion);
      setStartDate(currentBooking.startDate);
      setSelectedArray(currentBooking.selectedArray);
      setLoading(false);
    } else {
      if (parokshaSevaData.length === 0) {
        getData();
      } else {
        let finder = parokshaSevaData.find(
          (item) =>
            item?.name?.replace?.(/\s/g, "-").toLowerCase() ===
            match.params.type
        );
        // console.log(finder);
        if (finder?.disabled || finder === undefined) {
          // console.log("sada");
          history?.push(
            `/${selectedArray}/devotee-app/online-booking/paroksha-seva`
          );
        }
        setSelectedArray([finder]);
        setTotalCost(finder?.price);
        setLoading(false);
      }
    }
  }, []);
  //----------------------------------------------------------------useeeffect------------------------------------------------------------------//

  //----------------------------------------------------------------functions------------------------------------------------------------------//

  function capitalize(str) {
    let splitStr = str?.toLowerCase?.().split?.(" ");
    for (let i = 0; i < splitStr?.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr?.join(" ");
  }
  const handleSubmit = async () => {
    let a = true;
    setLoading(true);
    if (a) {
      let data = [...err];
      if (mobile.substring(0, 2) === "91") {
        if (mobile.length !== 12) {
          data.push({type: "phone", msg: "Invalid mobile number"});
        }
      } else {
        if (mobile.length < 8) {
          data.push({type: "phone", msg: "Invalid mobile number"});
        }
      }
      if (selectedArray.length === 0) {
        data.push({type: "seva", msg: "Select a seva"});
      }
      if (address1 === "") {
        data.push({type: "address1", msg: "Please enter a valid address"});
      } else if (address1.length < 3) {
        data.push({type: `address1`, msg: "Minimun 3 characters"});
      }
      if (city === "") {
        data.push({type: "city", msg: "Please enter a city"});
      } else if (city.length < 3) {
        data.push({type: `city`, msg: "Minimun 3 characters"});
      }
      if (state === "") {
        data.push({type: "state", msg: "Please select your state"});
      }
      if (zip.length === "") {
        data.push({type: "zip", msg: "Invalid zipcode! Try again"});
      }
      if (country === "") {
        data.push({type: "country", msg: "Please select a country"});
      }
      if (startDate === "") {
        data.push({type: "date", msg: "Please select a date"});
      }
      if (gothram === "") {
        data.push({type: "gothram", msg: "Please enter your gothram"});
      } else if (gothram.length < 3) {
        data.push({type: `gothram`, msg: "Minimun 3 characters"});
      }
      for (let i = 0; i < nameArray.length; i++) {
        if (nameArray[i] === "") {
          data.push({type: `name${i}`, msg: "Please enter a devotee name"});
        } else if (nameArray[i].length < 3) {
          data.push({type: `name${i}`, msg: "Minimun 3 characters"});
        }
      }
      if (checkbox === false) {
        data.push({
          type: `checkbox`,
          msg: "Please agree to terms and conditions",
        });
      }
      setErr(data);
      if (data.length !== 0) {
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });

        setLoading(false);
        return;
      }
    }

    let details = [];
    nameArray.map((item) => {
      if (item !== "") {
        return details.push({
          name: capitalize(item),
          gothram: capitalize(gothram),
        });
      }
    });

    let statesArray = {
      details,
      startDate,
      selectedArray,
      address1,
      address2,
      city,
      state,
      country,
      zip,
      mobile,
      totalCost,
    };
    if (occasion !== "") {
      statesArray.occasion = occasion;
    }
    try {
      let array = [];
      for (const obj of selectedArray) {
        const temp = [];

        for (let i = 0; i < details?.length; i++) {
          const element = details[i];
          if (element.name !== "") {
            temp.push(element);
          }
        }
        let data = {
          sevaPersonDetails: temp,
          itemType: "parokshaSeva",
          bookingDate: moment(startDate).format("YYYY-MM-DD"), // same for all
          itemId: obj.id, // per entry
          address: capitalize(address1),
          city: capitalize(city),
          state: state,
          country: country,
          pin_code: zip,
          contact: `+${mobile}`,
        };
        if (address2 !== "") {
          data.address2 = capitalize(address2);
        }
        if (occasion) {
          data.occassion = capitalize(occasion); // same for all
        }
        array.push(data);
      }
      let res = await ParokshaSevaService.bookings(array, BASE_BANK, true);
      if (res) {
        setCurrentBooking(statesArray);

        history?.push(
          `/${selectedlanguage}/devotee-app/online-booking/paroksha-seva/confirm-details`
        );
        setLoading(false);
      }
    } catch (e) {
      toast.error(e.message);
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      if (e.message === "Session Expired") {
        logout();
        history?.push(
          `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/paroksha-seva`
        );
      }
      setLoading(false);
      return;
    }
  };
  const getData = async () => {
    let data = await ParokshaSevaService.getAll();
    if (data) {
      setParokshaSevaData(data);
      let finder = data.find(
        (item) =>
          item?.name?.replace?.(/\s/g, "-").toLowerCase() === match.params.type
      );
      if (finder?.disabled) {
        history?.push(
          `/${selectedArray}/devotee-app/online-booking/paroksha-seva`
        );
      }

      setSelectedArray([finder]);
      setTotalCost(finder?.price);
    }
    setLoading(false);
  };
  const buttonsArrayFunction = () => {
    return selectedArray.map((item, i) => {
      return (
        <div
          className="button-div"
          style={{display: "flex"}}
          key={i + "buttonArray-ps"}
        >
          <p>
            {item?.name}
            <span className="mr-3">-</span>
            {IndiaTime(item?.start_date).format("hh:mm A") ===
            IndiaTime(item?.end_date).format("hh:mm A") ? (
              <>
                {item?.start_date &&
                  IndiaTime(item?.start_date).format("hh:mm A")}
              </>
            ) : (
              <>
                {item?.start_date &&
                  IndiaTime(item?.start_date).format("hh:mm A")}{" "}
                -{item?.end_date && IndiaTime(item?.end_date).format("hh:mm A")}
              </>
            )}
            <span className="mr-3">-</span> Rs.
            {item?.price}
          </p>
          <h3
            onClick={() => {
              let data = [...selectedArray];

              setTotalCost(totalCost - item?.price);
              var filtered = data.filter(function (el) {
                return el?.name != item?.name;
              });
              setSelectedArray([...filtered]);
            }}
          >
            X
          </h3>
        </div>
      );
    });
  };
  const listFunction = () => {
    let temp = parokshaSevaData.filter((item) => !item?.disabled);
    let array = temp.map((item, i) => {
      if (item?.disabled === true) {
        return;
      } else {
        let finder = null;
        for (let i = 0; i < selectedArray?.length; i++) {
          if (selectedArray[i]?.name === item?.name) {
            finder = true;
          }
        }
        return (
          <div
            key={i + "listFunction-ps"}
            className={finder ? "option-class sd-cyan" : "option-class"}
            onClick={() => {
              if (err.find((e) => e.type === "seva" || e.type === "common")) {
                let data = err.filter((e) => {
                  if (e.type !== "seva" && e.type !== "common") {
                    return e;
                  }
                });
                setErr(data);
              }
              let dup = selectedArray;
              let finder = selectedArray?.find((e) => e.name === item?.name);
              let finder2 = selectedArray.find(
                (e) =>
                  e?.name === "Nandeeshwara Swamy vari Visesharchana" ||
                  e?.name === "Laksha Kumkumarchana" ||
                  e?.name ===
                    "Sri Bayalu Veerabhadra Swamy Vari Visesharchana" ||
                  e?.name === "Sri Ganapathi Pooja"
              );
              let name =
                item?.name === "Nandeeshwara Swamy vari Visesharchana" ||
                item?.name === "Laksha Kumkumarchana" ||
                item?.name ===
                  "Sri Bayalu Veerabhadra Swamy Vari Visesharchana" ||
                item?.name === "Sri Ganapathi Pooja";
              if (finder2 || finder) {
                setShow(false);

                return;
              }
              if (name && selectedArray.length !== 0) {
                setShow(false);

                return;
              }

              setTotalCost(totalCost + item?.price);

              setSelectedArray([...dup, item]);
              setShow(false);
              // setValues(item);
            }}
          >
            {item?.name}
            {window.innerWidth < 600 ? <br /> : <span className="mr-3">-</span>}
            {IndiaTime(item?.start_date).format("hh:mm A") ===
            IndiaTime(item?.end_date).format("hh:mm A") ? (
              <>
                {item?.start_date &&
                  IndiaTime(item?.start_date).format("hh:mm A")}
              </>
            ) : (
              <>
                {item?.start_date &&
                  IndiaTime(item?.start_date).format("hh:mm A")}{" "}
                -{item?.end_date && IndiaTime(item?.end_date).format("hh:mm A")}
              </>
            )}
            <span className="mr-3">-</span> Rs.
            {item?.price}
          </div>
        );
      }
    });
    return array;
  };
  const getNames = () => {
    let a = nameArray.map((item, i) => {
      return (
        <div
          className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-p-0 pl-0"
          style={{position: "initial"}}
          key={i + "name-ps"}
        >
          <label>
            {i + 1}. Devotee Name<span className="sd-imp">*</span>
          </label>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <input
              maxLength="15"
              style={{width: i !== 0 ? "90%" : "100%"}}
              placeholder="Please enter a devotee name."
              type="text"
              autoFocus={false}
              onFocus={() => {
                if (autoFill === `${i}autofill`) {
                  setAutoFill("none");
                } else {
                  setAutoFill(`${i}autofill`);
                }
              }}
              autoComplete="new-password"
              className={
                err?.find((e) => e.type === `name${i}`)
                  ? "form-control auto sd-red"
                  : nameArray[i] !== ""
                  ? "form-control auto sd-voilet"
                  : "form-control auto"
              }
              value={item}
              onChange={(e) => {
                if (
                  /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                  e.target.value === ""
                ) {
                  let data = [...nameArray];
                  data[i] = e.target.value;
                  setNameArray(data);
                  if (
                    err.find(
                      (e) => e.type === `name${i}` || e.type === "common"
                    )
                  ) {
                    let data = err.filter((e) => {
                      if (e.type !== `name${i}` && e.type !== "common") {
                        return e;
                      }
                    });
                    setErr(data);
                  }
                }
              }}
            />
            {i !== 0 && (
              <button
                style={{
                  border: "none",
                  alignSelf: "center",
                  marginLeft: "8px",
                  height: "80%",
                }}
                className="sd-btn-orange"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    err.find(
                      (e) => e.type === `name${i}` || e.type === "common"
                    )
                  ) {
                    let data = err.filter((e) => {
                      if (e.type !== `name${i}` && e.type !== "common") {
                        return e;
                      }
                    });
                    setErr(data);
                  }
                  if (nameArray.length > 1) {
                    let data = [...nameArray];

                    data.splice(i, 1);
                    setNameArray(data);
                  }
                }}
              >
                Remove
              </button>
            )}{" "}
            {autoFill === `${i}autofill` && autoFillFunction(item, i)}
            <p className={`sd-EM ${i !== 0 ? "mr-150" : "mr-5"}`}>
              {err?.find((e) => e.type === `name${i}`)?.msg}
            </p>
          </div>
        </div>
      );
    });
    return a;
  };
  const fnCalculateAge = (userDateinput) => {
    let birthDate = new Date(userDateinput);
    let difference = Date.now() - birthDate.getTime();
    let ageDate = new Date(difference);
    let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
    return calculatedAge;
  };
  const autoFillFunction = (name, i) => {
    let array = [];
    const userOnCLick = () => {
      if (i === 0) {
        userData?.gothram ? setGothram(userData?.gothram) : setGothram("");
        userData?.city ? setCity(userData?.city) : setCity("");
        userData?.country && setCountry(userData?.country);
        userData?.address1 ? setAddress1(userData?.address1) : setAddress1("");
        userData?.address2 ? setAddress2(userData?.address2) : setAddress2("");
        userData?.state ? setState(userData?.state) : setState("");
        userData?.phone
          ? setMobile(userData?.phone?.replace?.("+", ""))
          : setMobile("");
        userData?.pincode ? setZip(userData?.pincode) : setZip("");
      }
      let data = [...nameArray];

      if (userData?.first_name || userData?.last_name) {
        data[i] = `${userData?.first_name ? userData?.first_name : ""} ${
          userData?.last_name ? userData?.last_name : ""
        }`;
      } else {
        data[i] = userData?.display_name;
      }

      if (i === 0) {
        let datas = err.filter((e) => {
          if (
            e.type !== "gothram" &&
            e.type !== "city" &&
            e.type !== "address1" &&
            e.type !== "state" &&
            e.type !== "country" &&
            e.type !== "phone" &&
            e.type !== "zip" &&
            e.type !== "address2" &&
            e.type !== `name${i}`
          ) {
            return e;
          }
        });
        setErr(datas);
      } else {
        let datas = err.filter((e) => {
          if (e.type !== `name${i}`) {
            return e;
          }
        });
        setErr(datas);
      }
      setNameArray(data);
      setAutoFill("none");
    };

    let a = nameArray.filter((e) => {
      if (
        e.toLowerCase() ===
        `${userData?.first_name?.toLowerCase()} ${userData?.last_name?.toLowerCase()}`
      ) {
        return e;
      }
    });

    if (
      userData?.first_name?.toLowerCase()?.includes(name?.toLowerCase()) ||
      userData?.last_name?.toLowerCase()?.includes(name?.toLowerCase())
    ) {
      if (a?.length === 0) {
        array.push(
          <div
            className="sd-autofill-div"
            onClick={() => {
              userOnCLick();
            }}
          >
            <p
              style={{textTransform: "capitalize"}}
              onClick={() => {
                userOnCLick();
              }}
            >
              Name:{" "}
              {userData?.first_name
                ? userData?.first_name
                : userData?.display_name}
              <span style={{marginLeft: "3px"}}>{userData?.last_name}</span>
            </p>
            {userData?.date_of_birth && (
              <p
                onClick={() => {
                  userOnCLick();
                }}
              >
                Age:
                {fnCalculateAge(userData?.date_of_birth)} yrs
              </p>
            )}
            {userData?.gender && (
              <p
                onClick={() => {
                  userOnCLick();
                }}
              >
                Gender: {userData?.gender}
              </p>
            )}
          </div>
        );
      }
    }
    if (devoteeList !== null) {
      devoteeList.forEach((items) => {
        if (
          items?.first_name?.toLowerCase()?.includes(name?.toLowerCase()) ||
          items?.last_name?.toLowerCase()?.includes(name?.toLowerCase())
        ) {
          let a = nameArray.filter((e) => {
            if (e === `${items?.first_name} ${items?.last_name}`) {
              return e;
            }
          });

          if (a?.length === 0) {
            let onClicks = () => {
              let data = [...nameArray];
              data[
                i
              ] = `${items?.first_name?.toLowerCase()} ${items?.last_name?.toLowerCase()}`;
              setNameArray(data);
              let datas = err.filter((e) => {
                if (e.type !== `name${i}`) {
                  return e;
                }
              });
              setErr(datas);
              setAutoFill("none");
            };
            array.push(
              <p
                key={items.id + "autofill-ps"}
                className="sd-autofill-div"
                onClick={() => {
                  onClicks();
                }}
              >
                <p
                  onClick={() => {
                    onClicks();
                  }}
                >
                  Name: {items?.first_name}
                  <span style={{marginLeft: "3px"}}>{items?.last_name}</span>
                </p>
                <p
                  onClick={() => {
                    onClicks();
                  }}
                >
                  Age:
                  {fnCalculateAge(items?.dob)} yrs
                </p>
                <p
                  onClick={() => {
                    onClicks();
                  }}
                >
                  Gender: {items?.gender}
                </p>
              </p>
            );
          }
        }
      });
    }

    if (array.length !== 0) {
      return (
        <div className="sd-autofill bg ani">
          {array.map((item) => {
            return item;
          })}
        </div>
      );
    } else {
      if (name === "" && a?.length === 0) {
        let demo = (
          <p
            className="sd-autofill-div"
            onClick={() => {
              userOnCLick();
            }}
          >
            <p
              style={{textTransform: "capitalize"}}
              onClick={() => {
                userOnCLick();
              }}
            >
              Name:{" "}
              {userData?.first_name
                ? userData?.first_name
                : userData?.display_name}
              <span style={{marginLeft: "3px"}}>{userData?.last_name}</span>
            </p>
            {userData?.date_of_birth && (
              <p
                onClick={() => {
                  userOnCLick();
                }}
              >
                Age:
                {fnCalculateAge(userData?.date_of_birth)} yrs
              </p>
            )}
            {userData?.gender && (
              <p
                onClick={() => {
                  userOnCLick();
                }}
              >
                Gender: {userData?.gender}
              </p>
            )}
          </p>
        );

        let a = [...devoteeList, demo];
        return (
          <div className="sd-autofill bg ani">
            {a.map((item) => {
              return item;
            })}{" "}
          </div>
        );
      } else {
        return "";
      }
    }
  };
  const handleKey = (e) => {
    if (!e.target.className) {
      return;
    }
    if (
      e.target.className !== "sd-autofill-div" &&
      e.target.className !== "sd-autofill"
    ) {
      setAutoFill("none");
    }
  };

  //----------------------------------------------------------------useeeffect------------------------------------------------------------------//

  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      onMouseDown={(e) => {
        handleKey(e);
      }}
    >
      {loading ? (
        <Sd_loading dist="67%" />
      ) : (
        <div className="col-xs-12 col-sm-12 col-md-11 col-lg-10 pl-0 xs-pr-0 sd-profile sd-seva-form">
          <h2 className="sd-side-heading fw400">
            <span className="fw400">
              <span className="fw700" ref={ref}>
                Paroksha
              </span>{" "}
              Seva
            </span>
          </h2>
          <div className="clearfix">
            <form className="sd-form">
              <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 xssm-pr-0 pl-0 pr-0">
                <label>
                  Select Seva’s (You can select multiple sevas)
                  <span className="sd-imp">*</span>
                </label>
                <div className="select-div">
                  <div
                    className={
                      err.find((e) => e.type === "seva")
                        ? "constant-top sd-white sd-red"
                        : selectedArray?.length > 0
                        ? "constant-top sd-white sd-voilet-border"
                        : "constant-top "
                    }
                  >
                    <div
                      className={
                        selectedArray?.length === 0
                          ? "sd-buttonArray h-34"
                          : "sd-buttonArray"
                      }
                      onClick={() => {
                        selectedArray?.length === 0 && setShow(!show);
                      }}
                    >
                      {buttonsArrayFunction()}
                    </div>

                    <div className="sd-input-image">
                      <input
                        className={selectedArray?.length > 0 ? "sd-white" : " "}
                        type="text"
                        onClick={() => {
                          setShow(!show);
                        }}
                        onChange={(e) => {
                          setSelectedValue({value: e.target.value});
                        }}
                      />
                      <img
                        className={show ? "sd-image-invert" : ""}
                        src={SD_Dropdown_Arrow_icon}
                        alt=""
                        onClick={() => {
                          setShow(!show);
                        }}
                      />
                    </div>
                  </div>

                  {show && (
                    <div className="option-div" style={{paddingTop: "1vh"}}>
                      {listFunction()}
                    </div>
                  )}

                  <p className="sd-EM">
                    {
                      err.find((e) => e.type === "seva" || e.type === "common")
                        ?.msg
                    }
                  </p>
                </div>
                {selectedArray.find(
                  (e) =>
                    e?.name ===
                    "Sri Bayalu Veerabhadra Swamy Vari Visesharchana"
                ) && (
                  <p style={{color: "red"}}>
                    Note: Enroll Sri Bayalu Veerabhadra Swamy Visesharchana Seva
                    on 2nd of January 2022.
                  </p>
                )}
                {selectedArray.find(
                  (e) => e?.name === "Laksha Kumkumarchana"
                ) && (
                  <p style={{color: "red"}}>
                    Note: Enroll your Laksha Kumkumarchana Seva to Sri
                    Bhramaramba Devi on 16th of February, 2022.
                  </p>
                )}

                {selectedArray.find(
                  (e) => e?.name === "Nandeeshwara Swamy vari Visesharchana"
                ) && (
                  <p style={{color: "red"}}>
                    Note: Enroll your Special Archana for Nandeeshwara Swamy
                    Vari Visesharchana on 15th and 30th of January, 2021.
                  </p>
                )}
                {selectedArray.find(
                  (e) => e?.name === "Varalakshmi Vratham"
                ) && (
                  <p style={{color: "red"}}>
                    Note : Devotees can enroll the tickets on 2nd and 4th
                    Fridays of Sravana Masam i.e., 3rd Sept respectively.
                  </p>
                )}
                {selectedArray.find(
                  (e) => e?.name === "Sri Ganapathi Pooja"
                ) && (
                  <p style={{color: "red"}}>
                    Note :Enroll to Ganapathi Pooja on the eve of Vinayaka
                    Chavithi on 10th of September, 08:30 am.
                  </p>
                )}
              </div>
              <hr className="clearfix"></hr>
              <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                <label>Total Cost</label>
                <input
                  type="text"
                  className={
                    totalCost !== "" ? "form-control sd-voilet" : "form-control"
                  }
                  value={totalCost}
                  readOnly
                />
              </div>
              <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0 pr-0">
                <label>
                  Select Date<span className="sd-imp">*</span>
                </label>

                <div
                  className={
                    err.find((e) => e.type === "date")
                      ? "sd-dob customDatePickerWidth  sd-red"
                      : startDate !== ""
                      ? "sd-voilet sd-dob customDatePickerWidth"
                      : "sd-dob customDatePickerWidth"
                  }
                >
                  <DatePicker
                    dayPlaceholder="DD"
                    monthPlaceholder="MM"
                    yearPlaceholder="YYYY"
                    style={{
                      width: "90%",
                      border: "none",
                      backgroundColor: "blue",
                    }}
                    format="dd-MM-y"
                    minDate={addDays(new Date(), selectedArray[0]?.min_days)}
                    maxDate={addDays(new Date(), selectedArray[0]?.max_days)}
                    // minDate={new Date()}
                    calendarIcon={<img src={sd_dashboard_dob} alt="" />}
                    clearIcon={null}
                    value={startDate}
                    onChange={(date) => {
                      // console.log(new Date('07/09/2021'));

                      if (
                        err.find(
                          (e) => e.type === "date" || e.type === "common"
                        )
                      ) {
                        let data = err.filter((e) => {
                          if (e.type !== "date" && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      setStartDate(date);
                    }}
                  />
                </div>
                <p className="sd-EM mr-10">
                  {
                    err.find((e) => e.type === "date" || e.type === "common")
                      ?.msg
                  }
                </p>
              </div>
              <h4 className="sd-side-heading-h4 fw400">
                <span className="fw700">Devotees Names </span>
              </h4>
              <div className="clearfix">
                {getNames()}
                {nameArray.length < 4 &&
                  (selectedArray.find(
                    (e) =>
                      e?.name === "Nandeeshwara Swamy vari Visesharchana" ||
                      e?.name === "Laksha Kumkumarchana" ||
                      e?.name ===
                        "Sri Bayalu Veerabhadra Swamy Vari Visesharchana" ||
                      e?.name === "Varalakshmi Vratham" ||
                      e?.name === "Sri Ganapathi Pooja"
                  ) && nameArray.length > 1 ? (
                    ""
                  ) : (
                    <button
                      style={{border: "none"}}
                      className="sd-btn-orange"
                      onClick={(e) => {
                        e.preventDefault();
                        if (nameArray.length < 4) {
                          let data = [...nameArray];
                          data.push("");
                          setNameArray(data);
                        }
                      }}
                    >
                      Add Devotee
                    </button>
                  ))}
              </div>
              <br />
              <hr className="clearfix"></hr>
              <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                <label>
                  Mobile Number<span className="sd-imp">*</span>
                </label>

                <PhoneInput
                  inputExtraProps={{
                    autoFocus: true,
                    enableSearch: true,
                    countryCodeEditable: false,
                  }}
                  onlyCountries={["in"]}
                  // onlyCountries={["in", "au", "ca", "sg", "ch", "gb", "us"]}
                  placeholder="Enter Your 10 digit mobile number"
                  maxLength="15"
                  countryCodeEditable={false}
                  containerClass={
                    err?.find((e) => e.type === "phone")
                      ? "sd-phone-voilet sd-red"
                      : mobile !== ""
                      ? "sd-phone-voilet"
                      : ""
                  }
                  country="in"
                  value={mobile}
                  onChange={(e) => {
                    if (
                      err?.find(
                        (e) => e.type === "phone" || e.type === "common"
                      )
                    ) {
                      let array = err.filter((e) => {
                        if (e.type !== "phone" && e.type !== "common") {
                          return e;
                        }
                      });
                      setErr(array);
                    }
                    setMobile(e);
                  }}
                />
                <p className="sd-EM mr-15">
                  {err.find((e) => e.type === "phone")
                    ? err.find((e) => e.type === "phone")?.msg
                    : ""}
                </p>
              </div>

              <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 xssm-pr-0 ">
                <label>Occasion / Auspicious Day</label>
                <input
                  type="text"
                  maxLength="30"
                  className={
                    occasion !== "" ? "form-control sd-voilet" : "form-control"
                  }
                  placeholder="Please enter the occasion. Eg: Birthday"
                  value={occasion}
                  onChange={(e) => {
                    if (
                      /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                      e.target.value === ""
                    ) {
                      setOccasion(e.target.value);
                    }
                  }}
                />
              </div>

              <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pl-0  xssm-pr-0  pl-0 ">
                <label>
                  Gothram<span className="sd-imp">*</span>
                </label>
                <input
                  type="text"
                  maxLength="30"
                  placeholder="Please enter your gothram."
                  className={
                    err.find((e) => e.type === "gothram" || e.type === "common")
                      ? "form-control sd-red"
                      : gothram !== ""
                      ? "form-control sd-voilet"
                      : "form-control"
                  }
                  value={gothram}
                  onChange={(e) => {
                    if (
                      /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                      e.target.value === ""
                    ) {
                      if (
                        err.find(
                          (e) => e.type === "gothram" || e.type === "common"
                        )
                      ) {
                        let data = err.filter((e) => {
                          if (e.type !== "gothram" && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      setGothram(e.target.value);
                    }
                  }}
                />
                <p className="sd-EM mr-15 b-20">
                  {
                    err.find((e) => e.type === "gothram" || e.type === "common")
                      ?.msg
                  }
                </p>
              </div>
              <hr className="clearfix"></hr>

              <div className="clearfix">
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                  <label>
                    Address Lane 1<span className="sd-imp">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength="50"
                    placeholder="House/building number, street number"
                    className={
                      err.find((e) => e.type === "address1")
                        ? "form-control sd-red"
                        : address1 !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={address1}
                    onChange={(e) => {
                      if (
                        CommonRegex.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        if (
                          err.find(
                            (e) => e.type === "address1" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "address1" && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setAddress1(e.target.value);
                      }
                    }}
                  />
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "address1")
                      ? err.find((e) => e.type === "address1")?.msg
                      : ""}
                  </p>
                </div>

                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0  xssm-pr-0  ">
                  <label>Address Lane 2</label>
                  <input
                    type="text"
                    maxLength="50"
                    className={
                      err.find((e) => e.type === "address2")
                        ? "form-control sd-red"
                        : address2 !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    placeholder="building name, street name, etc"
                    value={address2}
                    onChange={(e) => {
                      if (
                        CommonRegex.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        if (
                          err.find(
                            (e) => e.type === "address2" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "address2" && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setAddress2(e.target.value);
                      }
                    }}
                  />
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "address2")
                      ? err.find((e) => e.type === "address2")?.msg
                      : ""}
                  </p>
                </div>
              </div>
              <div className="clearfix">
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                  <label>
                    City<span className="sd-imp">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength="30"
                    className={
                      err.find((e) => e.type === "city")
                        ? "form-control sd-red"
                        : city !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={city}
                    placeholder="Please enter your city"
                    onChange={(e) => {
                      if (
                        CommonRegex.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        if (
                          err.find(
                            (e) => e.type === "city" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "city" && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setCity(e.target.value);
                      }
                    }}
                  />
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "city")
                      ? err.find((e) => e.type === "city")?.msg
                      : ""}
                  </p>
                </div>

                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 ">
                  <label>
                    Zipcode<span className="sd-imp">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength="12"
                    onWheel={(event) => event.currentTarget.blur()}
                    className={
                      err.find((e) => e.type === "zip")
                        ? "form-control sd-red"
                        : zip !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={zip}
                    placeholder="Enter your zipcode"
                    onChange={(e) => {
                      if (
                        CommonRegex.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        if (
                          err.find(
                            (e) => e.type === "zip" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "zip" && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        if (e.target.value.length < 12) {
                          setZip(e.target.value.toUpperCase());
                        }
                      }
                    }}
                  />
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "zip")
                      ? err.find((e) => e.type === "zip")?.msg
                      : ""}
                  </p>
                </div>
              </div>
              <div className="clearfix">
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0 ">
                  <label>
                    State<span className="sd-imp">*</span>
                  </label>
                  <RegionDropdown
                    country={country}
                    classes={
                      err.find((e) => e.type === "state")
                        ? "sd-red w-100 sd-border-none"
                        : state !== ""
                        ? " sd-voilet w-100 sd-border-none"
                        : "w-100 sd-border-none"
                    }
                    value={state}
                    showDefaultOption={true}
                    blankOptionLabel="Select a State"
                    onChange={(val) => {
                      if (
                        err.find(
                          (e) => e.type === "state" || e.type === "common"
                        )
                      ) {
                        let data = err.filter((e) => {
                          if (e.type !== "state" && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      setState(val);
                    }}
                  />
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "state")
                      ? err.find((e) => e.type === "state")?.msg
                      : ""}
                  </p>
                </div>

                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0  xssm-pr-0 ">
                  <label>
                    Country<span className="sd-imp">*</span>
                  </label>
                  <CountryDropdown
                    value={country}
                    classes={
                      err.find((e) => e.type === "country")
                        ? "sd-red w-100 "
                        : country !== ""
                        ? " sd-voilet w-100 sd-border-none"
                        : "w-100 sd-border-none"
                    }
                    showDefaultOption={true}
                    defaultOptionLabel="Select a Country"
                    onChange={(val) => {
                      if (
                        err.find(
                          (e) => e.type === "country" || e.type === "common"
                        )
                      ) {
                        let data = err.filter((e) => {
                          if (e.type !== "country" && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      setCountry(val);
                    }}
                  />
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "country")
                      ? err.find((e) => e.type === "country")?.msg
                      : ""}
                  </p>
                </div>
              </div>

              <hr className="clearfix"></hr>

              <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pl-0 pr-0">
                <p
                  className="ml--15"
                  style={{
                    color: "#181818",
                    fontWeight: "600",

                    whiteSpace: "pre-wrap",
                  }}
                >
                  Note: Srisaila Devasthanam does not ship Prasadham overseas.
                  NRIs(Non-Resident Indians) and Overseas Citizens who wish to
                  book Paroksha Seva are advised to fill their Indian Address(if
                  any) while booking the Seva to receive the Prasadham via post.
                </p>
                <ol className="sd-terms-list">
                  {selectedArray.find(
                    (item) => item?.live_telecast === false
                  ) ? (
                    <li className="active">
                      <a>
                        Access to live service of{" "}
                        {
                          selectedArray.find(
                            (item) => item?.live_telecast === false
                          )?.name
                        }{" "}
                        is currently not available. However, devotees who have
                        booked the said service may witness their names read out
                        during live Veedasirvachanam.
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  <li>{termsList[0]}</li>
                  {selectedArray.find(
                    (e) =>
                      e?.name === "Nandeeshwara Swamy vari Visesharchana" ||
                      e?.name === "Laksha Kumkumarchana" ||
                      e?.name ===
                        "Sri Bayalu Veerabhadra Swamy Vari Visesharchana" ||
                      e?.name === "Sri Ganapathi Pooja"
                  ) ? (
                    <li>
                      Each seva is individually priced. Paroksha Seva is
                      performed by reciting 2 Gothra Namas per one booking.
                    </li>
                  ) : (
                    <li>
                      Each seva is individually priced. Paroksha Seva is
                      performed by reciting 4 Gothra Namas per one booking.
                    </li>
                  )}

                  {termsList.map((item, i) => {
                    if (i === 0) {
                      return;
                    }
                    return (
                      <li className="active" key={i + "terms-ps"}>
                        <a>{item}</a>
                      </li>
                    );
                  })}
                </ol>

                <div
                  className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 xssm-pr-0 pl-0 sd-terms-checkbox"
                  style={{alignItems: "center"}}
                >
                  <input
                    className="mr-5"
                    type="checkbox"
                    name=""
                    value={checkbox}
                    onChange={() => {
                      if (
                        err.find(
                          (e) => e.type === `checkbox` || e.type === "common"
                        )
                      ) {
                        let data = err.filter((e) => {
                          if (e.type !== `checkbox` && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      setCheckbox(!checkbox);
                    }}
                    checked={checkbox === true ? true : false}
                    style={{position: "relative"}}
                  />
                  <label
                    htmlFor=""
                    style={{marginBottom: "0px", position: "relative"}}
                  >
                    I agree to the{" "}
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedlanguage}/legalities/terms-and-conditions`
                        );
                      }}
                    >
                      Terms & Conditions
                    </a>{" "}
                    and the
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedlanguage}/legalities/privacy-policy`
                        );
                      }}
                    >
                      {" "}
                      Privacy Policy
                    </a>
                  </label>
                  <p className="sd-accept">
                    {err?.find((e) => e.type === `checkbox`)?.msg}
                  </p>
                </div>
              </div>

              <hr className="clearfix"></hr>
              <div className="clearfix mt-50">
                <a
                  className="sd-btn-orange"
                  onClick={() => {
                    if (!loading) {
                      handleSubmit();
                    }
                  }}
                >
                  Continue
                  {loading ? (
                    <img
                      src={sd_btn_loading}
                      alt=".."
                      className="sd-arrow-btn ml-5 h-20"
                    />
                  ) : (
                    <img
                      className="sd-arrow-btn ml-10"
                      src={sd_dashboard_side_arrow}
                    />
                  )}
                </a>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
export default Sd_dashboard_parokshaseva_seva_details;
