import Axios from '../Utils/sd-axios/Axios';

const YoutubeService = {
  getHomePageId: async () => {
    const resp = await Axios.get(`/youtube/homePage/isLive`);
    return resp?.data;
  },
};

export default YoutubeService;
