import Axios from "../Utils/sd-axios/Axios";

const NewsletterSubService = {
  subscribe: async (email) => {
    try {
      const resp = await Axios.post(`sub-newsletter/sub?email=${email}`);
      return resp.data;
    } catch (error) {
      if (error?.response?.status !== 500 && error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
};

export default NewsletterSubService;
