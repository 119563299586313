import React, {useEffect, useContext, useState, useRef} from "react";
import {toast} from "react-toastify";
import {
  sd_btn_loading,
  sd_dashboard_dob,
  SD_Right_Arrow_white,
  sd_script,
  sd_trash,
} from "../../../Assets/imageList";
import {
  DecryptInformation,
  EncryptInformation,
} from "../../../Utils/sd-encrypt/encrypt";

import {SD_Context} from "../../../context/sd-context";
import Sd_DashboardService from "../../../services/sd-dashboard.service";
import "../../../Utils/sd-styles/sd-css/main.css";
import "react-toastify/dist/ReactToastify.css";
import Sd_loading from "../../../Components/sd-common-components/sd-loading";
import DatePicker from "react-date-picker";
import {withRouter} from "react-router";
import moment from "moment";
import ParokshaSevaService from "../../../services/sd-parokshaseva.service";
import Sd_Darshanam_service from "../../../services/sd-darshanam.service";
import {BASE_BANK} from "../../../Utils/sd-axios/sd-api-url";
const Sd_dashboard_online_booking_darshanam = ({history}) => {
  const {
    setActive,
    setActivePath,
    currentBookingDarshananam,
    setcurrentBookingDarshananam,
    devoteeList,
    userData,
    logout,
  } = useContext(SD_Context);
  //-------------------------------------------------------------states--------------------------------------------------------//

  const [darshanamType, setDarshanamType] = useState("");
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [loading, setloading] = useState(true);
  const [noPersons, setNoperons] = useState(1);
  const [selectedLanguage, setSelectedlanguage] = React.useState("en-in");
  const [startDate, setStartDate] = useState("");
  const ref = useRef(null);
  const [slotsData, setSlotsData] = useState([]);
  const [autoFill, setAutoFill] = useState("none");
  const [personStates, setPersonStates] = useState([
    {
      id: 1,
      name: "",
      idProofType: "",
      idProofNumber: "",
      gender: "",
      dob: "",
      // doc_file_url: "",
    },
  ]);
  const [err, setErr] = useState([]);
  const [darshanams, setDarshanams] = useState([]);
  const [slot, setSlot] = useState("");
  const [slotLoading, setSlotLoading] = useState(false);
  const termsList = [
    "Temple entry ticket is not a interstate Permission.",
    "Every devotee must upload Vaccination certificate to book Darshan/Seva/Accommodation tickets.",
    "All the Pilgrims in group ticket have to report together.",
    "At the time of entry, all pilgrims shall produce the same original Photo ID (Aadhar Card) used during booking.",
    `Containment and Red zone people don't come to religious places.`,
    `Below 10 Years and Above 65 Years people shouldnt allow into temple.`,
    "The pilgrims shall wear Traditional Dress only. Male: Dhoti, Shirt / Kurtha, Pyjama. Female: Saree / Half Saree / Chudidar with Dupatta.",
    "Reporting and late reporting of Darshanam (after 1 1/2 hour only on booked slot).",
    "The pilgrims should not carry any luggage/cell phones/electronic gadgets while reporting.",
    "All bookings are FINAL: Postponement/advancement/cancellation/refund is not allowed.",
    "Entry with foot wear is not permitted into the queue lines, mada streets and Temple.",
    "SBMSD reserves the right of cancellation of Darshan under Special Circumstances.",
    "Please contact our 24/7 help desk for queries at 8333901351 /2 /3 /4 /5 /6.",
  ];
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useeffect--------------------------------------------------------//

  useEffect(() => {
    setActive("online-booking-darshanam");
    setActivePath("Onlinebooking-Darshanam");
    let path = new URLSearchParams(history?.location?.search).get("state");
    if (path === "clear") {
      setcurrentBookingDarshananam(null);
    }
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    getData();
  }, []);

  useEffect(() => {
    listFunction();
  }, [noPersons]);
  //-------------------------------------------------------------useeffect--------------------------------------------------------//

  //-------------------------------------------------------------functions--------------------------------------------------------//

  const getData = async () => {
    try {
      let res = await Sd_DashboardService.darshanam_getall();
      setDarshanams(res);
      if (res.filter((e) => !e?.disabled)) {
        history?.push(
          `/${selectedLanguage}/devotee-app/online-booking/darshanam?state=clear`
        );
      }
      if (currentBookingDarshananam) {
        setNoperons(currentBookingDarshananam?.noPersons);
        setStartDate(currentBookingDarshananam?.startDate);
        setDarshanamType(currentBookingDarshananam?.darshanamType);
        setSlot(currentBookingDarshananam?.slot);
        setSlotsData(currentBookingDarshananam?.slotsData);
        setTimeout(() => {
          setPersonStates([...currentBookingDarshananam?.personStates]);
        }, 500);
      }
      setloading(false);
    } catch (e) {
      toast.warning(e.message);
      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/darshanam?state=clear`
        );
      }
      setloading(false);
    }
  };

  const handleSubmit = async () => {
    setloading(true);
    let a = true;
    if (a) {
      let data = [...err];
      if (startDate === "") {
        data.push({type: "date", msg: "Please select a date"});
      }
      if (darshanamType === "") {
        data.push({type: "type", msg: "Please select a darshanam type"});
      }
      if (slot === "") {
        data.push({type: "slot", msg: "Please select a slot"});
      }
      personStates.map((item) => {
        for (var key of Object.keys(item)) {
          if (item[key] === "") {
            if (key === "idProofType") {
              data.push({
                type: `${item["id"]}-${key}`,
                msg: `Select a id Proof`,
              });
            }
            // else if (key === "doc_file_url") {
            //   data.push({
            //     type: `${item["id"]}-${key}`,
            //     msg: `Upload a covid certificate`,
            //   });
            // }
            else if (key === "idProofNumber") {
              data.push({
                type: `${item["id"]}-${key}`,
                msg: `Invalid id number`,
              });
            } else {
              data.push({
                type: `${item["id"]}-${key}`,
                msg: `Invalid ${key}`,
              });
            }
          } else {
            if (key === "name") {
              if (item["name"].length < 3) {
                data.push({
                  type: `${item["id"]}-name`,
                  msg: "Minimun 3 characters",
                });
              }
            }

            if (key === "idProofType") {
              if (item["idProofNumber"] !== "") {
                if (item["idProofType"] === "pan") {
                  let txt = item["idProofNumber"].toUpperCase();
                  let regex =
                    /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
                  let pan = {
                    C: "Company",
                    P: "Personal",
                    H: "Hindu Undivided Family (HUF)",
                    F: "Firm",
                    A: "Association of Persons (AOP)",
                    T: "AOP (Trust)",
                    B: "Body of Individuals (BOI)",
                    L: "Local Authority",
                    J: "Artificial Juridical Person",
                    G: "Govt",
                  };
                  pan = pan[txt[3]];
                  if (regex.test(txt)) {
                    if (pan !== "Personal") {
                      data.push({
                        type: `${item["id"]}-idProofNumber`,
                        msg: "Invalid Pan Number",
                      });
                    }
                  } else
                    data.push({
                      type: `${item["id"]}-idProofNumber`,
                      msg: "Invalid Pan Number",
                    });
                } else if (item["idProofType"] === "ration") {
                  let regex = /^([a-zA-Z0-9]){8,12}\s*$/;
                  if (!regex.test(item["idProofNumber"].toUpperCase())) {
                    data.push({
                      type: `${item["id"]}-idProofNumber`,
                      msg: "Invalid ration number",
                    });
                  }
                } else if (item["idProofType"] === "driving") {
                  let regex =
                    /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
                  if (!regex.test(item["idProofNumber"].toUpperCase())) {
                    // data.push({
                    //   type: `${item["id"]}-idProofNumber`,
                    //   msg: "Invalid driving license number",
                    // });
                  }
                } else if (item["idProofType"] === "voter") {
                  let regex = /^([a-zA-Z]){3}([0-9]){7}?$/;
                  if (!regex.test(item["idProofNumber"].toUpperCase())) {
                    data.push({
                      type: `${item["id"]}-idProofNumber`,
                      msg: "Invalid voter id",
                    });
                  }
                } else if (item["idProofType"] === "aadhar") {
                  let regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
                  if (!regex.test(item["idProofNumber"].toUpperCase())) {
                    data.push({
                      type: `${item["id"]}-idProofNumber`,
                      msg: "Invalid AADHAR number",
                    });
                  }
                }
              }
            }
          }
        }
      });
      setErr(data);
      // console.log(data);
      if (data.length !== 0) {
        setloading(false);
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });

        return;
      }
    }

    let res = await Sd_Darshanam_service.getSlotsAvailability(
      darshanamType?.id,
      moment(startDate).format("YYYY-MM-DD")
    );
    let item = res.find((E) => E.id === slot?.id);
    if (!item) {
      toast.error("Selected slot does not exist");
      setloading(false);
      return;
    }

    if (item?.available_slots >= personStates?.length) {
      try {
        let array = [...personStates];
        let arrayEx = array.map((e) => {
          let data = {
            name: e?.name,
            dob: moment(e.dob).format("YYYY-MM-DD"),
            gender: e.gender,
            idProofType: e.idProofType,
            idProofNumber: EncryptInformation(e.idProofNumber),
            // covid_certificate: e.doc_file_url.path
            //   ? e.doc_file_url.path
            //   : e.doc_file_url,
          };

          return data;
        });

        let schema = [
          {
            darshanamPersonDetails: arrayEx,
            itemType: "darshanam",
            bookingDate: moment(startDate).format("YYYY-MM-DD"),
            itemId: darshanamType?.id,
            slotId: slot?.id,
          },
        ];
        let res = await ParokshaSevaService.bookings(schema, BASE_BANK, true);
        if (res) {
          let data = {
            darshanamType,
            startDate,
            noPersons,
            personStates,
            slot,
            slotsData,
          };
          setcurrentBookingDarshananam(data);
          setloading(false);

          history?.push(
            `/${selectedLanguage}/devotee-app/online-booking/darshanam/confirm`
          );
        }
      } catch (e) {
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });

        toast.error(e.message);
        if (e.message === "Session Expired") {
          logout();

          history?.push(
            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/darshanam`
          );
        }
        setloading(false);
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });

        return;
      }
    } else {
      let data = [...err];
      data.push({type: "slot", msg: "Please select another slot"});
      toast.error(
        "Please select a slot with available slots equal or higher than No of persons"
      );
      setErr(data);
      setloading(false);
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      return;
    }
  };

  const handleFileUpload = async (e) => {
    try {
      if (e.target.files[0].size / 1024 / 1024 > 5) {
        // setUploadFlag(false);
        document.getElementById(`${e.target.id}`).value = "";

        throw new Error(
          "Please make sure the file you are attaching are below 5 mb "
        );
      }
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      let res = await Sd_DashboardService.upload_document(
        formData,
        "darshanam"
      );
      toast.info("File Uploaded");
      return res;
    } catch (e) {
      toast.error(e.message);
      document.getElementById(`${e.target.id}`).value = "";

      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-seva`
        );
      }
      return false;
    }
    // setUploadFlag(false);
  };

  const getSlots = async (id, date) => {
    setSlotLoading(true);

    try {
      setSlot("");
      setSlotsData([]);
      let res = await Sd_Darshanam_service.getSlotsAvailability(id, date);
      // console.log(res);
      setSlotsData(res);
      setSlotLoading(false);
    } catch (e) {
      setSlotLoading(false);
      setSlot("");
      setSlotsData([]);
      toast.error(e.message);
    }
  };

  const getSlotsDetails = () => {
    if (slotsData.length === 0 || !slotsData) {
      return <p>No Slots available</p>;
    }
    return slotsData.map((item, i) => {
      if (noPersons > item?.available_slots) {
        return;
      }
      return (
        <div
          key={i + "slots-d"}
          onClick={() => {
            if (personStates?.length > item?.available_slots) {
              return;
            }
            setSlot(item);
            if (err?.find((e) => e.type === `slot`)) {
              let data = err.filter((e) => e.type !== "slot");
              setErr(data);
            }
          }}
        >
          <div
            className="sd-darshanam-box text-center"
            style={{
              display: "flex",
              alignItems: "center",
              border:
                err.find((e) => e.type === "slot") ||
                personStates?.length > item?.available_slots
                  ? "1px solid red"
                  : "",
            }}
            onClick={() => {
              if (personStates?.length > item?.available_slots) {
                return;
              }
              setSlot(item);
              if (err?.find((e) => e.type === `slot`)) {
                let data = err.filter((e) => e.type !== "slot");
                setErr(data);
              }
            }}
          >
            <p
              className="sd-darshanam-time"
              style={{marginBottom: "0px", maxWidth: "85px"}}
            >
              <span style={{marginRight: "3px", fontWeight: "600"}}>
                {item.available_slots}
              </span>
              <span style={{fontWeight: "400"}}> Available </span>
            </p>
            <div className="sd-side-heading-2 sd-acc">
              {window.innerWidth < 650 ? (
                <span
                  style={{
                    fontWeight: "600",
                    whiteSpace: "nowrap",
                  }}
                >
                  Slot <br /> (
                  {moment(item?.start_time, "HH:mm:ss").format("hh:mm A")} to
                  <span style={{marginLeft: "5px"}}>
                    {moment(item?.end_time, "HH:mm:ss").format("hh:mm A")})
                  </span>
                </span>
              ) : (
                <span
                  style={{
                    fontWeight: "600",
                    whiteSpace: "nowrap",
                  }}
                >
                  Slot - (
                  {moment(item?.start_time, "HH:mm:ss").format("hh:mm A")} -
                  {moment(item?.end_time, "HH:mm:ss").format("hh:mm A")})
                </span>
              )}
            </div>
            <input
              className="sd-darshanam-radio"
              type="radio"
              id={"slot" + 11}
              name=""
              value={slot}
              onClick={() => {
                if (personStates?.length > item?.available_slots) {
                  return;
                }
                setSlot(item);
                if (err?.find((e) => e.type === `slot`)) {
                  let data = err.filter((e) => e.type !== "slot");
                  setErr(data);
                }
              }}
              checked={slot.id === item?.id}
            />
          </div>
        </div>
      );
    });
  };

  const fnCalculateAge = (userDateinput) => {
    let birthDate = new Date(userDateinput);
    let difference = Date.now() - birthDate.getTime();
    let ageDate = new Date(difference);
    let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
    return calculatedAge;
  };

  const handleChange = (index, event, name) => {
    // let data = [...personStates];
    // if (name === "doc_file_url" || name === "dob") {
    if (name === "dob") {
      let data = [...personStates];
      data[index][`${name}`] = event;
      setPersonStates(data);
    } else {
      if (name === "idProofType") {
        let data = [...personStates];

        let value = data[index][`${name}`];
        data[index][`${name}`] = event.target.value;
        if (value !== event.target.value) {
          data[index][`idProofNumber`] = "";
        }
        setPersonStates(data);
      } else if (name === "idProofNumber") {
        let data = [...personStates];
        data[index][`idProofNumber`] = event.target.value
          ?.toUpperCase()
          ?.replaceAll?.(" ", "");
        setPersonStates(data);
      } else {
        let data = [...personStates];
        data[index][`${name}`] = event.target.value;
        setPersonStates(data);
      }
    }
  };

  const listFunction = () => {
    if (noPersons === personStates.length) {
      return;
    }
    if (noPersons < personStates.length) {
      let arr = [...personStates];
      let b = personStates.length - noPersons;
      let total = personStates.length;
      while (b !== 0) {
        let data = err.filter((e) => {
          if (
            e.type !== `${total}-name` &&
            e.type !== `${total}-idProofType` &&
            e.type !== `${total}-idProofNumber` &&
            e.type !== `${total}-gender` &&
            e.type !== `${total}-dob`
            // e.type !== `${total}-doc_file_url`
          ) {
            return e;
          }
        });
        setErr(data);
        arr.pop();
        total--;
        b--;
      }

      setPersonStates([...arr]);
      return;
    }
    if (noPersons > personStates.length) {
      let arr = [...personStates];
      for (let i = personStates.length; i < noPersons; i++) {
        arr.push({
          id: personStates.length + 1,
          name: "",
          idProofType: "",
          idProofNumber: "",
          gender: "",
          dob: "",
          // doc_file_url: "",
        });
      }

      setPersonStates([...arr]);
      return;
    }
  };

  function addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }

  const autoFillFunction = (name, i) => {
    let array = [];
    let userOnClick = () => {
      let data = [...personStates];
      data[i]["name"] =
        userData?.first_name || userData?.last_name
          ? `${userData?.first_name} ${userData?.last_name}`
          : "";

      data[i]["dob"] = userData?.date_of_birth
        ? new Date(userData?.date_of_birth)
        : "";
      data[i]["gender"] = userData?.gender ? userData?.gender : "";
      data[i]["idProofType"] = userData?.id_proof_type
        ? userData?.id_proof_type
        : "";
      data[i]["idProofNumber"] = userData?.id_proof_number
        ? DecryptInformation(userData?.id_proof_number)
        : "";
      setPersonStates(data);
      let datas = err.filter((e) => {
        if (
          e.type !== `${i + 1}-name` &&
          e.type !== `${i + 1}-dob` &&
          e.type !== `${i + 1}-gender` &&
          e.type !== `${i + 1}-idProofNumber` &&
          e.type !== `${i + 1}-idProofType`
        ) {
          return e;
        }
      });
      setErr(datas);
      setAutoFill("none");
    };
    let a = personStates.filter((e) => {
      if (
        e?.name?.toLowerCase() ===
        `${userData?.first_name?.toLowerCase()} ${userData?.last_name?.toLowerCase()}`
      ) {
        return e;
      }
    });

    if (
      userData?.first_name?.toLowerCase()?.includes(name?.toLowerCase()) ||
      userData?.last_name?.toLowerCase()?.includes(name?.toLowerCase())
    ) {
      if (a?.length === 0) {
        array.push(
          <p
            className="sd-autofill-div"
            onClick={() => {
              userOnClick();
            }}
          >
            <p
              style={{textTransform: "capitalize"}}
              onClick={() => {
                userOnClick();
              }}
            >
              Name:{" "}
              {userData?.first_name
                ? userData?.first_name
                : userData?.display_name}
              <span style={{marginLeft: "3px"}}>{userData?.last_name}</span>
            </p>
            {userData?.date_of_birth && (
              <p
                onClick={() => {
                  userOnClick();
                }}
              >
                Age:
                {fnCalculateAge(userData?.date_of_birth)} yrs
              </p>
            )}
            {userData?.gender && <p>Gender: {userData?.gender}</p>}
          </p>
        );
      }
    }
    if (devoteeList !== null) {
      devoteeList.forEach((items) => {
        if (
          items?.first_name?.toLowerCase()?.includes(name?.toLowerCase()) ||
          items?.last_name?.toLowerCase()?.includes(name?.toLowerCase())
        ) {
          let a = personStates.filter((e) => {
            if (e?.name === `${items?.first_name} ${items?.last_name}`) {
              return e;
            }
          });
          if (a?.length === 0) {
            let onCLick = () => {
              let data = [...personStates];
              data[i]["name"] =
                items?.first_name || items?.last_name
                  ? `${items?.first_name} ${items?.last_name}`
                  : "";
              data[i]["dob"] = items?.dob ? new Date(items?.dob) : "";
              data[i]["gender"] = items?.gender
                ? items?.gender?.toLowerCase()
                : "";

              data[i]["idProofType"] = items?.id_proof_type
                ? items?.id_proof_type
                : "";
              data[i]["idProofNumber"] = items?.id_proof_number
                ? DecryptInformation(items?.id_proof_number)
                : "";
              setPersonStates(data);
              let datas = err.filter((e) => {
                if (
                  e.type !== `${i + 1}-name` &&
                  e.type !== `${i + 1}-dob` &&
                  e.type !== `${i + 1}-gender` &&
                  e.type !== `${i + 1}-idProofNumber` &&
                  e.type !== `${i + 1}-idProofType`
                ) {
                  return e;
                }
              });
              setErr(datas);
              setAutoFill("none");
            };
            array.push(
              <p
                key={items.id + "list"}
                className="sd-autofill-div"
                onClick={() => {
                  onCLick();
                }}
              >
                <p
                  onClick={() => {
                    onCLick();
                  }}
                >
                  Name: {items?.first_name}
                  <span style={{marginLeft: "3px"}}>{items?.last_name}</span>
                </p>
                <p
                  onClick={() => {
                    onCLick();
                  }}
                >
                  Age:
                  {fnCalculateAge(items?.dob)} yrs
                </p>
                <p>Gender: {items?.gender}</p>
              </p>
            );
          }
        }
      });
    }

    if (array.length !== 0) {
      return (
        <div className="sd-autofill bg">
          {array.map((item) => {
            return item;
          })}
        </div>
      );
    } else {
      if (name === "" && a?.length === 0) {
        let demo = (
          <p
            className="sd-autofill-div"
            onClick={() => {
              userOnClick();
            }}
          >
            <p
              style={{textTransform: "capitalize"}}
              onClick={() => {
                userOnClick();
              }}
            >
              Name:{" "}
              {userData?.first_name
                ? userData?.first_name
                : userData?.display_name}
              <span style={{marginLeft: "3px"}}>{userData?.last_name}</span>
            </p>
            {userData?.date_of_birth && (
              <p
                onClick={() => {
                  userOnClick();
                }}
              >
                Age:
                {fnCalculateAge(userData?.date_of_birth)} yrs
              </p>
            )}
            {userData?.gender && (
              <p
                onClick={() => {
                  userOnClick();
                }}
              >
                Gender: {userData?.gender}
              </p>
            )}
          </p>
        );

        let a = [...devoteeList, demo];
        return (
          <div className="sd-autofill bg ani">
            {a.map((item) => {
              return item;
            })}{" "}
          </div>
        );
      } else {
        return "";
      }
    }
  };
  //-------------------------------------------------------------functions--------------------------------------------------------//

  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {!loading ? (
        <div className="sd-profile sd-seva-form">
          <h2 className="sd-side-heading fw400">
            <span className="fw700" ref={ref}>
              Darshanam{" "}
            </span>
            Booking
          </h2>

          <div className="clearfix">
            {darshanams.filter((e) => !e?.disabled)?.length === 0 ? (
              <p className="flex-c w-100 border-c pb-30  pt-20 br-4">
                <img
                  src={sd_script}
                  alt="#"
                  height="80px"
                  width="80px"
                  className="mr-5 mb-5 opace"
                />
                We are sorry to say that darshanam is temporarily unavailable
                due to Covid-19. We regret the inconvenience.
              </p>
            ) : (
              <form className="sd-form">
                <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pr-0 pl-0 ">
                  <label>
                    Select Darshanam Type<span className="sd-imp">*</span>
                  </label>
                  <div className="clearfix">
                    {darshanams.map((item, i) => {
                      if (item?.disabled) {
                        return;
                      }
                      return (
                        <div
                          className="col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0"
                          key={i + "darshanamtype"}
                        >
                          <div
                            className={
                              err.find((e) => e.type === "type")
                                ? " sd-red sd-radio-select"
                                : "sd-radio-select"
                            }
                            onClick={() => {
                              if (err?.find((e) => e.type === `type`)) {
                                let data = err.filter((e) => e.type !== "type");
                                setErr(data);
                              }
                              setSlot("");
                              setSlotsData([]);
                              setStartDate("");
                              setDarshanamType(item);
                            }}
                          >
                            <input
                              type="radio"
                              name="optradio1"
                              id={`Annadanam${i}`}
                              checked={
                                darshanamType?.name === item?.name
                                  ? true
                                  : false
                              }
                            />
                            <label
                              htmlFor={`Annadanam${i}`}
                              className="radio-inline"
                              onClick={() => {
                                if (err?.find((e) => e.type === `type`)) {
                                  let data = err.filter(
                                    (e) => e.type !== "type"
                                  );
                                  setErr(data);
                                }
                                setDarshanamType(item);
                              }}
                            >
                              <p>
                                {item?.name}
                                <br></br>
                                <span>Rs. {item.price}.00</span>
                              </p>
                            </label>
                          </div>
                        </div>
                      );
                    })}
                    <p className="sd-EM mr-35" style={{left: "0"}}>
                      {err?.find((e) => e.type === `type`)?.msg}
                    </p>
                  </div>
                </div>
                <hr className="clearfix"></hr>
                <div className="sd-profile">
                  <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3 xssm-pr-0 pl-0">
                    <label>
                      Select Date<span className="sd-imp">*</span>
                    </label>

                    <div
                      className={
                        err?.find((e) => e.type === `date`)
                          ? "sd-dob customDatePickerWidth sd-red"
                          : startDate !== ""
                          ? "sd-date-voilet sd-dob customDatePickerWidth"
                          : "sd-dob customDatePickerWidth"
                      }
                    >
                      <DatePicker
                        dayPlaceholder="DD"
                        monthPlaceholder="MM"
                        yearPlaceholder="YYYY"
                        style={{
                          width: "90%",
                          border: "none",
                          backgroundColor: "blue",
                        }}
                        format="dd-MM-y"
                        minDate={addDays(
                          new Date(),
                          darshanamType?.cut_off_time_hours / 24
                        )}
                        maxDate={addDays(
                          new Date(),
                          darshanamType?.book_within_days
                        )}
                        calendarIcon={<img src={sd_dashboard_dob} alt="" />}
                        clearIcon={null}
                        value={startDate}
                        onChange={(date) => {
                          if (darshanamType === "" || !darshanamType) {
                            toast.error("Please select darshanam type");
                            return;
                          }
                          if (err.find((e) => e.type === "date")) {
                            let data = err.filter((e) => e.type !== "date");
                            setErr(data);
                          }
                          getSlots(
                            darshanamType?.id,
                            moment(date).format("YYYY-MM-DD")
                          );
                          setStartDate(date);
                        }}
                      />
                    </div>
                    <p className="sd-EM mr-15">
                      {err?.find((e) => e.type === `date`)?.msg}
                    </p>
                  </div>

                  <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pl-0 xssm-pr-0">
                    <label>
                      Number of Person(s)<span className="sd-imp">*</span>
                    </label>
                    <select
                      className={
                        noPersons !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      onChange={(e) => {
                        setNoperons(e.target.value);
                        setSlot("");
                      }}
                      value={noPersons}
                      // defaultValue={noPersons}
                    >
                      <option key={"1"} value={"1"}>
                        1
                      </option>
                      <option key={"2"} value={"2"}>
                        2
                      </option>
                      <option key={"3"} value={"3"}>
                        3
                      </option>
                      <option key={"4"} value={"4"}>
                        4
                      </option>
                      <option key={"5"} value={"5"}>
                        5
                      </option>
                    </select>
                    <p style={{color: "#181818", fontWeight: "600"}}>
                      Free for Children under 10 years
                    </p>
                  </div>
                </div>
                {slotsData?.length !== 0 ? (
                  <>
                    <hr className="clearfix"></hr>

                    <div
                      className="clearfix sd-darshanam-content"
                      style={{position: "relative"}}
                    >
                      <br />
                      {getSlotsDetails()}
                      <p
                        style={{
                          position: "absolute",
                          top: "-12px",
                          left: "0px",
                          textAlign: "right",
                          color: "red",
                        }}
                      >
                        {err?.find((e) => e.type === `slot`)?.msg}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    {startDate && !slotLoading ? (
                      <>
                        <hr className="clearfix"></hr>{" "}
                        <div
                          className="clearfix sd-darshanam-content"
                          style={{position: "relative", color: "red"}}
                        >
                          No Slots available on{" "}
                          {moment(startDate).format("DD-MM-YYYY")}
                        </div>
                      </>
                    ) : (
                      startDate && (
                        <>
                          <hr className="clearfix"></hr>{" "}
                          <div
                            className="clearfix sd-darshanam-content"
                            style={{position: "relative"}}
                          >
                            Fetching slots...Please wait{" "}
                          </div>
                        </>
                      )
                    )}
                  </>
                )}
                <div className="clearfix">
                  <hr className="clearfix"></hr>
                </div>
                {personStates.map((item, i) => {
                  return (
                    <>
                      {" "}
                      <div className="clearfix" key={i + "personstates-d"}>
                        <div
                          className="form-group col-xs-12 col-sm-12 col-md-1 col-lg-1  xssm-pr-0 pl-0"
                          style={{width: "2vw"}}
                        >
                          <label>{i + 1}.</label>
                        </div>
                        <div
                          className="form-group col-xs-12 col-sm-12 col-md-11 col-lg-11  xssm-pl-0 pr-0 "
                          style={{marginBottom: "0px"}}
                        >
                          <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4  xssm-pr-0 pl-0">
                            <label>
                              Name<span className="sd-imp">*</span>
                            </label>
                            <input
                              maxLength="30"
                              type="text"
                              autoComplete="new-password"
                              placeholder="Please enter your name."
                              className={
                                err?.find((e) => e.type === `${i + 1}-name`)
                                  ? "form-control sd-red"
                                  : item?.name !== ""
                                  ? "form-control sd-voilet"
                                  : "form-control"
                              }
                              value={item?.name}
                              onFocus={() => {
                                if (autoFill === `${i}autofill`) {
                                  setAutoFill("none");
                                } else {
                                  setAutoFill(`${i}autofill`);
                                }
                              }}
                              onBlur={() => {
                                if (autoFill === `${i}autofill`) {
                                  setTimeout(() => {
                                    setAutoFill("none");
                                  }, 250);
                                }
                              }}
                              onChange={(e) => {
                                if (
                                  /^[a-zA-Z ]*$/.test(
                                    String(e.target.value) ||
                                      e.target.value === ""
                                  )
                                ) {
                                  if (
                                    err.find((e) => e.type === `${i + 1}-name`)
                                  ) {
                                    let data = err.filter(
                                      (e) => e.type !== `${i + 1}-name`
                                    );
                                    setErr(data);
                                  }
                                  handleChange(i, e, "name");
                                }
                              }}
                            />
                            <p className="sd-EM mr-15 ">
                              {err.find((e) => e.type === `${i + 1}-name`)?.msg}
                            </p>
                            {autoFill === `${i}autofill` &&
                              autoFillFunction(item?.name, i)}
                          </div>
                          <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4  xssm-pl-0 xssm-pr-0 ">
                            <label>
                              Date of Birth<span className="sd-imp">*</span>
                            </label>
                            <div
                              className={
                                err?.find((e) => e.type === `${i + 1}-dob`)
                                  ? "sd-dob customDatePickerWidth  sd-red"
                                  : item?.dob !== ""
                                  ? "sd-dob customDatePickerWidth  sd-date-voilet"
                                  : "sd-dob customDatePickerWidth"
                              }
                            >
                              <DatePicker
                                dayPlaceholder="DD"
                                monthPlaceholder="MM"
                                yearPlaceholder="YYYY"
                                style={{
                                  width: "90%",
                                  border: "none",
                                  backgroundColor: "blue",
                                }}
                                format="dd-MM-y"
                                maxDate={addDays(new Date(), -3652)}
                                calendarIcon={
                                  <img src={sd_dashboard_dob} alt="" />
                                }
                                clearIcon={null}
                                value={item?.dob}
                                onChange={(date) => {
                                  if (
                                    err.find((e) => e.type === `${i + 1}-dob`)
                                  ) {
                                    let data = err.filter(
                                      (e) => e.type !== `${i + 1}-dob`
                                    );
                                    setErr(data);
                                  }
                                  handleChange(i, date, "dob");
                                }}
                              />
                            </div>
                            <p className="sd-EM mr-15 ">
                              {err.find((e) => e.type === `${i + 1}-dob`)?.msg}
                            </p>
                          </div>
                          <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4  xssm-pl-0 xssm-pr-0">
                            <label>
                              Gender<span className="sd-imp">*</span>
                            </label>
                            <select
                              className={
                                err?.find((e) => e.type === `${i + 1}-gender`)
                                  ? "form-control sd-red"
                                  : item?.gender !== ""
                                  ? "form-control sd-voilet"
                                  : "form-control"
                              }
                              value={item?.gender}
                              onChange={(e) => {
                                if (
                                  err.find((e) => e.type === `${i + 1}-gender`)
                                ) {
                                  let data = err.filter(
                                    (e) => e.type !== `${i + 1}-gender`
                                  );
                                  setErr(data);
                                }
                                handleChange(i, e, "gender");
                              }}
                            >
                              {" "}
                              <option style={{display: "none"}}>
                                Select a gender
                              </option>
                              <option key="male" value="male">
                                Male
                              </option>
                              <option key="female" value="female">
                                Female
                              </option>
                              <option key="others" value="others">
                                Others
                              </option>
                            </select>
                            <p className="sd-EM mr-15">
                              {
                                err.find((e) => e.type === `${i + 1}-gender`)
                                  ?.msg
                              }
                            </p>
                          </div>

                          <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4  pl-0  xssm-pr-0">
                            <label>
                              Photo ID Proof<span className="sd-imp">*</span>
                            </label>
                            <select
                              placeholder="Select a valid id proof"
                              className={
                                err?.find(
                                  (e) => e.type === `${i + 1}-idProofType`
                                )
                                  ? "form-control sd-red"
                                  : item.idProofType !== ""
                                  ? "form-control sd-voilet"
                                  : "form-control"
                              }
                              value={item?.idProofType}
                              onChange={(e) => {
                                if (
                                  err.find(
                                    (e) => e.type === `${i + 1}-idProofType`
                                  )
                                ) {
                                  let data = err.filter(
                                    (e) => e.type !== `${i + 1}-idProofType`
                                  );
                                  setErr(data);
                                }
                                handleChange(i, e, "idProofType");
                              }}
                            >
                              <option
                                style={{
                                  display: "none",
                                }}
                              >
                                Select a valid id proof
                              </option>

                              <option key="pan" value="pan">
                                PAN
                              </option>
                              <option key="aadhar" value="aadhar">
                                AADHAR
                              </option>
                              <option key="driving" value="driving">
                                Driving License
                              </option>
                              <option key="ration" value="ration">
                                Ration Card
                              </option>
                              <option key="voter" value="voter">
                                Voter ID
                              </option>
                            </select>
                            <p className="sd-EM mr-15">
                              {
                                err.find(
                                  (e) => e.type === `${i + 1}-idProofType`
                                )?.msg
                              }
                            </p>
                          </div>
                          <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4   xssm-pl-0 xssm-pr-0">
                            <label>
                              Photo ID Number<span className="sd-imp">*</span>
                            </label>

                            <input
                              placeholder="Please enter your id number"
                              type="text"
                              maxLength="30"
                              className={
                                err?.find(
                                  (e) => e.type === `${i + 1}-idProofNumber`
                                )
                                  ? "form-control sd-red"
                                  : item?.idProofNumber !== ""
                                  ? "form-control sd-voilet"
                                  : "form-control"
                              }
                              style={{textTransform: "none"}}
                              value={item?.idProofNumber}
                              onChange={(e) => {
                                if (
                                  /^[#.0-9a-zA-Z\s]+$/.test(e.target.value) ||
                                  e.target.value === ""
                                ) {
                                  if (
                                    err.find(
                                      (e) => e.type === `${i + 1}-idProofNumber`
                                    )
                                  ) {
                                    let data = err.filter(
                                      (e) => e.type !== `${i + 1}-idProofNumber`
                                    );
                                    setErr(data);
                                  }
                                  handleChange(i, e, "idProofNumber");
                                }
                              }}
                            />
                            <p className="sd-EM mr-15 ">
                              {
                                err.find(
                                  (e) => e.type === `${i + 1}-idProofNumber`
                                )?.msg
                              }
                            </p>
                          </div>
                          {/* <div
                            className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4   xssm-pl-0 xssm-pr-0"
                            style={{marginBottom: "0px !important"}}
                          >
                            <label>
                              Upload Covid Certificate
                              <span className="sd-imp">*</span>
                            </label>

                            {item?.doc_file_url ? (
                              <div className="upload-button">
                                {item?.doc_file_url?.originalname
                                  ? item?.doc_file_url?.originalname
                                  : "Uploaded"}{" "}
                                <img
                                  src={sd_trash}
                                  alt="delete"
                                  onClick={() => {
                                    let data = [...personStates];
                                    data[i]["doc_file_url"] = "";
                                    setPersonStates(data);
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {item?.doc_file_url === "" ? (
                              <input
                                id={`uploadInput${i}`}
                                name="upload-button"
                                style={{padding: "0px 12px"}}
                                type="file"
                                placeholder="Please attach a valid id proof"
                                className={
                                  err?.find(
                                    (e) => e.type === `${i + 1}-doc_file_url`
                                  )
                                    ? "form-control sd-red"
                                    : "form-control"
                                }
                                accept="image/png, image/jpeg,image/jpg,.pdf"
                                onChange={async (e) => {
                                  if (
                                    err.find(
                                      (e) => e.type === `${i + 1}-doc_file_url`
                                    )
                                  ) {
                                    let data = err.filter(
                                      (e) => e.type !== `${i + 1}-doc_file_url`
                                    );
                                    setErr(data);
                                  }
                                  let res = await handleFileUpload(e);

                                  if (res) {
                                    handleChange(i, res, "doc_file_url");
                                  }
                                }}
                              />
                            ) : (
                              ""
                            )}
                            <p className="sd-EM ">
                              {
                                err.find(
                                  (e) => e.type === `${i + 1}-doc_file_url`
                                )?.msg
                              }
                            </p>
                          </div> */}
                        </div>
                      </div>
                      <hr className="clearfix"></hr>
                    </>
                  );
                })}
                <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pl-0 pr-0">
                  <p
                    className="ml--15"
                    style={{
                      color: "#181818",
                      fontWeight: "600",

                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Note: Pilgrims who book for Darshanam should bring the
                    printed copy of their receipt.{" "}
                  </p>
                  <ol className="sd-terms-list">
                    {termsList.map((item, i) => {
                      return (
                        <li className="active" key={i + "terms-d"}>
                          <a style={{paddingRight: "3px"}}>{item}</a>
                        </li>
                      );
                    })}
                  </ol>
                </div>
                <hr className="clearfix"></hr>

                <div className="clearfix mt-50">
                  <a
                    className="sd-btn-orange"
                    onClick={() => {
                      if (!loading) {
                        handleSubmit();
                      }
                    }}
                  >
                    Confirm Booking{" "}
                    {loading ? (
                      <img
                        src={sd_btn_loading}
                        alt=".."
                        className="sd-arrow-btn ml-5 h-20"
                      />
                    ) : (
                      <img
                        className="sd-arrow-btn ml-5"
                        src={SD_Right_Arrow_white}
                      />
                    )}
                  </a>
                </div>
              </form>
            )}
          </div>
        </div>
      ) : (
        <Sd_loading />
      )}
    </div>
  );
};
export default withRouter(Sd_dashboard_online_booking_darshanam);
