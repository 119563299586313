import moment from "moment";
import React, {useContext, useEffect, useState} from "react";
import {
  sd_move_left,
  sd_move_right,
  SD_Print_Icon,
  sd_script,
  // SD_Right_Arrow_white,
  sd_view,
} from "../../Assets/imageList";
import Sd_loading from "../../Components/sd-common-components/sd-loading";
import {SD_Context} from "../../context/sd-context";
import Sd_DashboardService from "../../services/sd-dashboard.service";
import {EncryptInformation} from "../../Utils/sd-encrypt/encrypt";
import IndiaTime from "../../Utils/sd-moment/IndiaTime";
import "../../Utils/sd-styles/sd-css/main.css";

const Sd_dashboard_booking_history = ({history}) => {
  //-------------------------------------------------------------states----------------------------------------------------------//
  const {setActive, setActivePath, logout} = useContext(SD_Context);
  const [loading, setLoading] = useState();
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [selectedLanguage, setSelectedlanguage] = React.useState("en-in");
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [act, setAct] = useState("");
  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useeffect--------------------------------------------------------//
  useEffect(() => {
    setLoading(true);
    setActive("booking-history");
    setActivePath("Booking History");
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    getData(10, 1);
  }, []);
  //-------------------------------------------------------------useefects--------------------------------------------------------//

  //-------------------------------------------------------------functions--------------------------------------------------------//
  const getData = async (item, page) => {
    try {
      let res = await Sd_DashboardService.booking_history(false, item, page);
      setData(res.items);
      setMeta(res.meta);
      setLoading(false);
      setAct(page);
    } catch (e) {
      if (e.message === "Session Expired") {
        logout();
        history?.push(
          `/${selectedLanguage}/devotee/signin?to=devotee-app/devotee-dashboard`
        );
      }
      setLoading(false);
    }
  };
  const getTotal = () => {
    let array = [];
    for (let i = 1; i <= meta?.totalPages; i++) {
      array.push(
        <li
          className={act ? "sd-background" : ""}
          onClick={() => {
            if (act === i) {
              return;
            }
            getData(10, i);
          }}
        >
          <a>{i}</a>
        </li>
      );
    }
    return array;
  };
  //-------------------------------------------------------------functions--------------------------------------------------------//
  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {loading ? (
        <Sd_loading />
      ) : (
        <div className="sd-confirm-details">
          <h2 className="sd-side-heading fw400">
            <span className="fw400">
              <span className="fw700">Booking </span> History
            </span>
            <span className="float-right">
              <span className="sd-view-all"></span>
            </span>
          </h2>

          {/* {data.length !== 0 ? (
            <> */}
          <div className="clearfix sd-booking-history sd-devotee-details nan-border-none sd-toggle-table">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th width="25%">Ticket ID </th>
                  <th width="25%">service Name / Category</th>
                  <th width="15%">Person(S)</th>
                  <th width="15%">date / Time slot</th>
                  <th width="10%">Amount(₹)</th>
                  <th width="20%">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <span className="fw600">{item?.ticket_id}</span>
                        <br />
                        <span style={{fontWeight: "400"}}>
                          {IndiaTime(item?.createdAt).format("DD - MM - YYYY")}{" "}
                          <span style={{marginLeft: "5px"}}>
                            {" "}
                            {IndiaTime(item?.createdAt).format("hh:mm A")}
                          </span>
                          {/* 6:30 PM */}
                        </span>
                      </td>
                      <td>
                        <span className="fw600 TC">{item?.serviceName}</span>
                        <br></br>
                        <span className="TC fw400">
                          (
                          {item?.serviceCategory === "pratyakshaSeva"
                            ? "Pratyaksha Seva"
                            : item?.serviceCategory === "parokshaSeva"
                            ? "Paroksha Seva"
                            : item?.serviceCategory}
                          )
                        </span>
                      </td>
                      <td>
                        <span className="TC">
                          {item?.personsCount
                            ? item?.personsCount !== 0
                              ? `Persons - ${item?.personsCount}`
                              : "-"
                            : "-"}
                        </span>
                      </td>
                      <td>
                        <span className="fw600">
                          {" "}
                          {IndiaTime(item?.bookingDate).format(
                            "DD - MM - YYYY"
                          )}
                        </span>
                        <br />
                        <span>
                          {item?.serviceCategory === "Darshanam" ||
                          item?.serviceCategory === "pratyakshaSeva" ? (
                            `${moment(item?.timeSlotStart, "HH:mm:ss").format(
                              "hh:mm A"
                            )} - ${moment(item?.timeSlotEnd, "HH:mm:ss").format(
                              "hh:mm A"
                            )}`
                          ) : (
                            <>
                              {moment(item?.timeSlotStart).format("hh:mm A") ===
                                moment(item?.timeSlotEnd).format("hh:mm A") && (
                                <>
                                  {item?.timeSlotStart &&
                                    IndiaTime(item?.timeSlotStart).format(
                                      "hh:mm A"
                                    )}
                                </>
                              )}
                            </>
                          )}
                        </span>
                      </td>
                      <td className="sd-actions-icons-main">
                        <span>{item?.price}/-</span>
                      </td>
                      <td className="sd-actions-icons-main">
                        {/* {item?.serviceCategory?.toLowerCase() !==
                              "donation" && (
                              <> */}
                        <a
                          onClick={() => {
                            history?.push(
                              `/${selectedLanguage}/devotee/print-a-ticket?id=${EncryptInformation(
                                item?.ticket_id
                              )}&display=view`
                            );
                          }}
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          <img src={sd_view} className="sd-view-icon" />
                        </a>
                        <a
                          onClick={() => {
                            history?.push(
                              `/${selectedLanguage}/devotee/print-a-ticket?id=${EncryptInformation(
                                item?.ticket_id
                              )}&display=print`
                            );
                          }}
                          style={{
                            paddingLeft: "10px",
                            borderLeft: "1px solid rgb(156 155 155)",
                          }}
                        >
                          <img
                            src={SD_Print_Icon}
                            className="sd-print-icon"
                            height="15px"
                          />
                        </a>
                        {/* </>
                            )} */}
                      </td>
                    </tr>
                  );
                })}
                {data.length === 0 && (
                  <td
                    colSpan="24"
                    className="pt-20 pb-30"
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <br />
                    <img
                      src={sd_script}
                      alt="#"
                      height="80px"
                      width="80px"
                      style={{marginRight: "5px", opacity: "0.3"}}
                    />
                    <p className="w-100 p-5">
                      Looks like you haven't made a booking yet.
                      <br />
                      <span
                        className="mr-5"
                        style={{color: "#2f3293", fontWeight: "600"}}
                        onClick={() => {
                          history?.push(`/${selectedLanguage}/online-booking`);
                        }}
                      >
                        {" "}
                        Click here
                      </span>
                      to make a booking.
                    </p>
                    <br />
                  </td>
                )}
              </tbody>
            </table>
          </div>
          <div className="sd-table-detoggler">
            {data.map((item, i) => {
              return (
                <div key={i} className="sd-b-d-inner">
                  <div className="sd-booking-details-up">
                    <div className="sd-actions-icons-main">
                      <span style={{fontWeight: 600}}>{item?.ticket_id}</span>
                      <br />
                      <span style={{fontWeight: "500"}}>
                        {IndiaTime(item?.createdAt).format("DD - MM - YYYY")}{" "}
                        <span style={{marginLeft: "5px"}}>
                          {" "}
                          {IndiaTime(item?.createdAt).format("hh:mm A")}
                        </span>
                        {/* 6:30 PM */}
                      </span>
                    </div>
                    <div className="sd-actions-icons-main">
                      <a
                        onClick={() => {
                          history?.push(
                            `/${selectedLanguage}/devotee/print-a-ticket?id=${EncryptInformation(
                              item?.ticket_id
                            )}&display=view`
                          );
                        }}
                        style={{
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        <img src={sd_view} className="sd-view-icon" />
                      </a>
                      <a
                        onClick={() => {
                          history?.push(
                            `/${selectedLanguage}/devotee/print-a-ticket?id=${EncryptInformation(
                              item?.ticket_id
                            )}&display=print`
                          );
                        }}
                        style={{
                          paddingRight: "10px !important",
                          borderLeft: "1px solid rgb(156 155 155)",
                        }}
                      >
                        <img
                          src={SD_Print_Icon}
                          className="sd-print-icon"
                          height="15px"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="sd-booking-details-middle">
                    <label style={{marginBottom: "0pc"}}>
                      SERVICE NAME / CATEGORY
                    </label>
                    <br />
                    <span className="fw600 TC mr-10">{item?.serviceName}</span>

                    <span className="TC fw600">
                      (
                      {item?.serviceCategory === "pratyakshaSeva"
                        ? "Pratyaksha Seva"
                        : item?.serviceCategory === "parokshaSeva"
                        ? "Paroksha Seva"
                        : item?.serviceCategory}
                      )
                    </span>
                  </div>
                  <div className="sd-booking-details-down">
                    <div>
                      <label>PERSONS</label>
                      <span className="TC">
                        {item?.personsCount !== 0
                          ? `${item?.personsCount}`
                          : "-"}
                      </span>
                    </div>
                    <div>
                      <label>DATE/TIME SLOT</label>
                      <span className="fw600">
                        {" "}
                        {IndiaTime(item?.bookingDate).format("DD - MM - YYYY")}
                      </span>

                      <span>
                        {item?.serviceCategory === "Darshanam" ||
                        item?.serviceCategory === "pratyakshaSeva" ? (
                          `${moment(item?.timeSlotStart, "HH:mm:ss").format(
                            "hh:mm A"
                          )} - ${moment(item?.timeSlotEnd, "HH:mm:ss").format(
                            "hh:mm A"
                          )}`
                        ) : (
                          <>
                            {moment(item?.timeSlotStart).format("hh:mm A") ===
                              moment(item?.timeSlotEnd).format("hh:mm A") && (
                              <>
                                {item?.timeSlotStart &&
                                  IndiaTime(item?.timeSlotStart).format(
                                    "hh:mm A"
                                  )}
                              </>
                            )}
                          </>
                        )}
                      </span>
                    </div>
                    <div>
                      <label>AMOUNT</label>
                      <span>{item?.price}/-</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {data.length !== 0 && (
            <div className="clearfix mt-20">
              <p className="float-left">
                Showing {data?.length} of {meta?.itemCount}
              </p>
              <ul className="pagination mt-0 float-right">
                <li>
                  <a>
                    <img src={sd_move_left} alt="<-" />
                  </a>
                </li>
                {getTotal()}

                <li>
                  <a>
                    <img src={sd_move_right} />
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default Sd_dashboard_booking_history;
