/* eslint-disable react/jsx-key */
import React, {useEffect, useContext, useState, useRef} from "react";
import {
  sd_dashboard_edit_ico,
  sd_dashboard_side_arrow,
  sd_success_icon,
  sd_failure_icon,
} from "../../../../Assets/imageList";

import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
import {SD_Context} from "../../../../context/sd-context";
import IndiaTime from "../../../../Utils/sd-moment/IndiaTime";
import "../../../../Utils/sd-styles/sd-css/main.css";
import DisplayRazorpay from "../../../../Utils/sd-razorpay/sd-razorpay-display";
import ParokshaSevaService from "../../../../services/sd-parokshaseva.service";
import {toast} from "react-toastify";
import moment from "moment";
import {withRouter} from "react-router";
import "react-toastify/dist/ReactToastify.css";
import Sd_DashboardService from "../../../../services/sd-dashboard.service";
import {BASE_BANK} from "../../../../Utils/sd-axios/sd-api-url";
toast.configure();

const Sd_dashboard_parokshaseva_booking_confirm_details = ({history}) => {
  //--------------------------------------------------------------states---------------------------------------------------------//

  const {setActive, setActivePath, currentBooking, userData, logout} =
    useContext(SD_Context);
  const [successOrfailureFlag, setSuccessOrfailureFlag] = useState(false);
  const [status, setStatus] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [selectedlanguage, setSelectedlanguage] = useState("en-in");
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [result, setResult] = useState({});
  const [loading, setLoading] = useState(true);
  const [dissmissed, setDissmissed] = useState("");
  const [halfState, setHalfState] = useState(null);
  const [charges, setCharges] = useState("");
  const refContainer = useRef();
  const [timestamp, setTimestamp] = useState("");
  //--------------------------------------------------------------states---------------------------------------------------------//

  //--------------------------------------------------------------useeffetcs---------------------------------------------------------//

  useEffect(() => {
    if (dissmissed === "failed") {
      setErrorMsg("Cancelled by User");
      setSuccessOrfailureFlag(true);
      setStatus("failed");
      setLoading(false);
      setDissmissed("");
    }
  }, [dissmissed]);

  useEffect(async () => {
    setLoading(true);
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    if (!currentBooking) {
      history?.push(
        `/${selectedlanguage}/devotee-app/online-booking/paroksha-seva/booking/ganapathi-homam`
      );
    }
    let charges = await Sd_DashboardService.get_taxes(
      currentBooking?.totalCost,
      userData?.phone.substring(0, 3) === "+91" ? "INR" : "USD"
    );
    setCharges(charges);
    setActive("onlinebooking-paroksha-seva-confirm-details");
    setActivePath("Onlinebooking-Paroksha Seva-Enter Details-Confirm Details");
    setTimestamp("");
    setLoading(false);
    return () => {
      setTimestamp("");
    };
  }, []);

  useEffect(() => {
    refContainer?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [status]);

  //--------------------------------------------------------------useeffects---------------------------------------------------------//

  //--------------------------------------------------------------functions---------------------------------------------------------//

  function capitalize(str) {
    let splitStr = str.toLowerCase().split(" ");
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const getData = () => {
    let array = [];

    currentBooking.selectedArray.map((item, i) => {
      array.push(
        <div style={{marginBottom: "10px"}}>
          {i + 1}.{item?.name}
          <span className="mr-3">-</span>
          {/* {item.start_date && IndiaTime(item.start_date).format("hh:mm A")}
          <span className="mr-3">-</span>
          {item.end_date && IndiaTime(item.end_date).format("hh:mm A")} - */}
          Rs.
          {item?.price}.00 <br></br>{" "}
          <span className="mr-3">(Paroksha Seva)</span>
        </div>
      );
    });
    return array;
  };

  const getDetailsFunction = () => {
    let array = [];
    let i = 0;
    currentBooking.details.map((obj) => {
      if (obj?.name !== "") {
        array.push(
          <tr>
            <td>{i + 1}</td>
            <td>{obj?.name}</td>
            <td>{obj?.gothram}</td>
          </tr>
        );
      }
      i++;
    });
    return array;
  };

  const handleSubmit = async () => {
    let res;
    if (!halfState) {
      try {
        setLoading(true);

        let array = [];

        for (const obj of currentBooking.selectedArray) {
          const temp = [];

          for (let i = 0; i < currentBooking?.details?.length; i++) {
            const element = currentBooking?.details[i];
            if (element?.name !== "") {
              element.name = capitalize(element?.name);
              element.gothram = capitalize(element.gothram);
              temp.push(element);
            }
          }
          let data = {
            sevaPersonDetails: temp,
            itemType: "parokshaSeva",
            bookingDate: moment(currentBooking?.startDate).format("YYYY-MM-DD"), // same for all
            itemId: obj.id, // per entry
            address: capitalize(currentBooking?.address1),
            city: capitalize(currentBooking?.city),
            state: currentBooking?.state,
            country: currentBooking?.country,
            pin_code: currentBooking?.zip,
            contact: `+${currentBooking?.mobile}`,
          };
          if (currentBooking?.address2 !== "") {
            data.address2 = capitalize(currentBooking?.address2);
          }
          if (currentBooking?.occasion) {
            data.occassion = capitalize(currentBooking.occasion); // same for all
          }
          array.push(data);
        }
        // res = await ParokshaSevaService.bookings(array, BASE_BANK, false);

        res = await ParokshaSevaService.bookings(
          array,
          BASE_BANK,
          false,
          userData?.phone.substring(0, 3) === "+91" ? "INR" : "USD"
        );
        setHalfState(res);
        setResult(res);
      } catch (e) {
        // console.log(e);
        toast.error(e.message);
        if (e.message === "Session Expired") {
          logout();
          history?.push(
            `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/paroksha-seva`
          );
        }
        setLoading(false);
        return;
      }
    }

    try {
      if (halfState !== null) {
        if (Math.floor((Date.now() - timestamp) / 1000) > 900) {
          toast.error(
            "Redirecting to Previous Page as buffer time of 15 min for retrying the payment has been exceeded."
          );
          setTimestamp("");
          setTimeout(() => {
            history?.push(
              `/${selectedlanguage}/devotee-app/online-booking/paroksha-seva/booking/ganapathi-homam`
            );
          }, 3000);
          return;
        }
        await DisplayRazorpay(
          halfState?.paymentResp?.razorResp?.id,
          halfState?.paymentResp?.razorResp?.currency,
          halfState?.paymentResp?.razorResp?.amount.toString(),
          halfState?.cart,
          BASE_BANK,
          setDissmissed,
          userData,
          dissmissed
        );
        // console.log(result);
        setHalfState(null);
        setSuccessOrfailureFlag(true);
        setStatus("success");
        setTimestamp("");
        setLoading(false);
      } else {
        setTimestamp(Date.now());
        await DisplayRazorpay(
          res?.paymentResp?.razorResp?.id,
          res?.paymentResp?.razorResp?.currency,
          res?.paymentResp?.razorResp?.amount.toString(),
          res?.cart,
          BASE_BANK,
          setDissmissed,
          userData,
          dissmissed
        );
        // console.log(result);
        setHalfState(null);
        setSuccessOrfailureFlag(true);
        setStatus("success");
        setTimestamp("");
        setLoading(false);
      }
    } catch (e) {
      // console.log(e);
      setErrorMsg(e.message);
      setSuccessOrfailureFlag(true);
      setStatus("failed");
      setLoading(false);
    }
  };

  const getStatusFunction = () => {
    switch (status) {
      case "success":
        return (
          <div className="sd-success" ref={refContainer}>
            <div className="sd-success-top sd-green">
              <img src={sd_success_icon} alt="success" /> <p>Success</p>
            </div>
            <div className="sd-success-bottom">
              <h3>
                Confirmed! Your online booking for{" "}
                {currentBooking.selectedArray.map((item, i) => {
                  return (
                    <>
                      <span className="mr-3"> {item?.name}</span> at{" "}
                      {IndiaTime(item?.start_date).format("hh:mm A") ===
                      IndiaTime(item?.end_date).format("hh:mm A") ? (
                        <p>
                          {item.start_date &&
                            IndiaTime(item.start_date).format("hh:mm A")}
                        </p>
                      ) : (
                        <p>
                          {item.start_date &&
                            IndiaTime(item.start_date).format("hh:mm A")}{" "}
                          <span className="mr-3">-</span>
                          {item.end_date &&
                            IndiaTime(item.end_date).format("hh:mm A")}
                        </p>
                      )}{" "}
                      on{" "}
                      <p>
                        {" "}
                        {moment(currentBooking.startDate).format(
                          "DD-MM-YYYY"
                        )}{" "}
                        {i > 1 && ","}
                      </p>
                    </>
                  );
                })}
                has been made successfully. Looking forward to meeting you on a
                journey towards spirituality.
              </h3>
              <div className="sd-success-bottom-down">
                <p
                  className="sd-border-right"
                  onClick={() => {
                    history?.push(
                      `/${selectedlanguage}/devotee-app/booking-history`
                    );
                  }}
                >
                  Booking History
                </p>{" "}
                <p
                  onClick={() => {
                    history?.push(`/${selectedlanguage}/home`);
                  }}
                >
                  Go to Home
                </p>
              </div>
            </div>
          </div>
        );
      case "failed":
        return (
          <div className="sd-success" ref={refContainer}>
            <div className="sd-success-top sd-redback">
              <img src={sd_failure_icon} alt="success" />{" "}
              <p>Sorry, your booking failed!. {errorMsg} </p>
            </div>
            <div className="sd-success-bottom">
              <h3>
                Booking failed! Your online booking for{" "}
                {currentBooking.selectedArray.map((item, i) => {
                  return (
                    <>
                      {item?.name} at{" "}
                      {IndiaTime(item?.start_date).format("hh:mm A") ===
                      IndiaTime(item?.end_date).format("hh:mm A") ? (
                        <p>
                          {item?.start_date &&
                            IndiaTime(item?.start_date).format("hh:mm A")}
                        </p>
                      ) : (
                        <p>
                          {item?.start_date &&
                            IndiaTime(item?.start_date).format("hh:mm A")}{" "}
                          -
                          {item?.end_date &&
                            IndiaTime(item?.end_date).format("hh:mm A")}
                        </p>
                      )}{" "}
                      on{" "}
                      <p>
                        {" "}
                        {moment(currentBooking?.startDate).format("DD-MM-YYYY")}
                      </p>
                    </>
                  );
                })}
                <span>failed.</span>
                Check your booking history or SMS after 20 mins for
                confirmation. Please note that any funds debited will credit
                back to your account within 7 working days.
              </h3>
              <div className="sd-success-bottom-down">
                <p
                  className="sd-border-right"
                  onClick={() => {
                    setStatus("");
                    setSuccessOrfailureFlag(false);
                  }}
                >
                  Try Again
                </p>
                <p
                  onClick={() => {
                    history?.push(`/${selectedlanguage}/support/contactUs`);
                  }}
                >
                  Contact Us
                </p>
              </div>
            </div>
          </div>
        );
    }
  };

  //--------------------------------------------------------------functions---------------------------------------------------------//

  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {loading ? (
        <Sd_loading />
      ) : successOrfailureFlag ? (
        getStatusFunction()
      ) : (
        <div className="sd-confirm-details">
          <h2 className="sd-side-heading fw400">
            <span className="fw400">
              <span className="fw700">Confirm</span> Details
            </span>
            <a
              onClick={() => {
                history?.push(
                  `/${selectedlanguage}/devotee-app/online-booking/paroksha-seva/booking/${currentBooking.selectedArray[0].name
                    ?.replace?.(/\s/g, "-")
                    .toLowerCase()}`
                );
              }}
              className="sd-confirm-edit-button"
            >
              <img src={sd_dashboard_edit_ico} /> Edit details
            </a>
          </h2>
          <div className="clearfix  sd-booking-details">
            <h4>Booking Details</h4>

            <table className="table table-bordered sd-toggle-table">
              <thead>
                <tr>
                  <th width="60%">Booking for</th>
                  <th width="20%">Date</th>
                  <th width="20%">Total Price</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: "10px !important",
                      border: "none",
                    }}
                  >
                    {getData()}
                  </td>
                  <td>
                    {" "}
                    {moment(currentBooking.startDate).format("DD-MM-YYYY")}
                  </td>
                  <td>
                    {userData?.phone.substring(0, 3) === "+91" ? "Rs." : "$"}
                    {parseFloat(charges.totalAmount).toFixed(2)}
                    <br /> (Handling charges:{" "}
                    {userData?.phone.substring(0, 3) === "+91" ? "Rs." : "$"}
                    {parseFloat(charges?.additionalAmount).toFixed(2)})
                  </td>
                </tr>
              </tbody>
              <thead className="clearfix mt-20">
                <tr>
                  <th width="60%">Address</th>
                  <th width="20%" colSpan="1">
                    Mobile
                  </th>
                  {currentBooking.occasion && (
                    <th width="20%" colSpan="1">
                      Auspicious Day
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{textTransform: "capitalize"}}
                    width="60%"
                    colSpan="1"
                  >
                    {currentBooking?.address1}
                    {currentBooking?.address2 &&
                      `, ${currentBooking?.address2}`}
                    , {currentBooking?.city}, {currentBooking?.state},{" "}
                    {currentBooking?.country}, {currentBooking?.zip}.
                  </td>
                  <td colSpan="1">+{currentBooking?.mobile}</td>
                  <td colSpan="1">{currentBooking?.occasion}</td>
                </tr>
              </tbody>
            </table>

            <div className="sd-table-detoggler">
              <div className="sd-booking-details-up bg">
                <div className="sd-actions-icons-main">
                  {" "}
                  <label style={{color: "grey", fontWeight: 500}}>
                    BOOKING FOR
                  </label>
                  <span className="fw500">{getData()}</span>
                </div>
              </div>
              <div className="sd-booking-details-down ">
                <div>
                  <label>DATE/SLOT</label>
                  <span>
                    {moment(currentBooking.startDate).format("DD-MM-YYYY")}
                  </span>
                </div>
                {/* {currentBooking.occasion && (
                  <div>
                    {" "}
                    <label>OCCASION</label>
                    <span> {currentBooking?.occasion}</span>
                  </div>
                )} */}
                <div style={{marginRight: "0px"}}>
                  {" "}
                  <label>AMOUNT</label>
                  <span>
                    {" "}
                    {userData?.phone.substring(0, 3) === "+91" ? "Rs." : "$"}
                    {charges.totalAmount}.00
                    <br />{" "}
                    <span style={{fontSize: "12px"}}>
                      (Handling charges:{" "}
                      {userData?.phone.substring(0, 3) === "+91" ? "Rs." : "$"}
                      {~~charges?.additionalAmount}.00)
                    </span>
                  </span>
                </div>
              </div>
              {currentBooking.occasion && (
                <div className="sd-booking-details-down ">
                  <div>
                    {" "}
                    <label>OCCASION</label>
                    <span> {currentBooking?.occasion}</span>
                  </div>
                </div>
              )}

              <div className="sd-booking-details-down ">
                <div>
                  <label>ADDRESS</label>
                  <span>
                    {" "}
                    {currentBooking?.address1}
                    {currentBooking?.address2 &&
                      `, ${currentBooking?.address2}`}
                    , {currentBooking?.city}, {currentBooking?.state},{" "}
                    {currentBooking?.country}, {currentBooking?.zip}.
                  </span>
                </div>
                <div>
                  {" "}
                  <label>MOBILE</label>
                  <span>+{currentBooking?.mobile}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="clearfix sd-devotee-details mt-30">
            <h4>Devotee Details</h4>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th width="5%">S.No.</th>
                  <th width="42.5%">Name</th>
                  <th width="42.5%">Gothram</th>
                </tr>
              </thead>
              <tbody>{getDetailsFunction()}</tbody>
            </table>
          </div>

          <div className="clearfix mt-50 sd-form">
            <a
              className="sd-btn-orange"
              onClick={() => {
                if (!loading) {
                  handleSubmit();
                }
              }}
            >
              Proceed to Payment{" "}
              <img
                className="sd-arrow-btn ml-5"
                src={sd_dashboard_side_arrow}
              />
            </a>
          </div>
        </div>
      )}{" "}
    </div>
  );
};
export default withRouter(Sd_dashboard_parokshaseva_booking_confirm_details);
