import React from "react";
import {sd_srisaila_logo} from "../../Assets/imageList";
import "../../Utils/sd-styles/sd-home.scss";
const Sd_loading = ({dist}) => {
  return (
    <div className="sd-loading" style={{width: dist ? "67% !important" : ""}}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          margin: "auto",
          background: "white none repeat scroll 0% 0%",
          display: "block",
          shapeRendering: "auto",
        }}
        width={
          window.innerWidth < 600
            ? "70px"
            : window.innerWidth < 900
            ? "90px"
            : "120px"
        }
        height={
          window.innerWidth < 600
            ? "70px"
            : window.innerWidth < 900
            ? "90px"
            : "120px"
        }
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <image
          overflow="visible"
          width="85"
          xlinkHref={sd_srisaila_logo}
          transform="matrix(0.6242 0 0 0.6242 25 18.1847)"
        ></image>
        <path
          d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50"
          fill="#f58220"
          stroke="none"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            dur="1s"
            repeatCount="indefinite"
            keyTimes="0;1"
            values="0 50 51;360 50 51"
          ></animateTransform>
        </path>
      </svg>
      <br />
    </div>
  );
};
export default Sd_loading;
