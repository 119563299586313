import React from "react";
import {withRouter} from "react-router";
import {
  SD_gallery_01,
  SD_gallery_04,
  SD_gallery_02,
  SD_gallery_03,
  SD_gallery_05,
  SD_gallery_06,
  SD_gallery_07,
  SD_gallery_08,
  SD_Right_Arrow_white,
} from "../../Assets/imageList";
import "../../Utils/sd-styles/sd-main-sup.scss";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const Sd_gallery_semi_view = ({history}) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");
  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useffeccts-----------------------------------------------------------//
  React.useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);
  //-------------------------------------------------------------useffeccts-----------------------------------------------------------//
  return (
    <div className="sd-template-five">
      <h2>Photo Gallery</h2>
      <h4 style={{textAlign: "center"}}>The Beauty of Sacred Srisailam</h4>
      <br></br>
      <div className="sd-photo-grid">
        <div>
          <img src={SD_gallery_01} alt="picture1" />
          <img src={SD_gallery_02} alt="picture2" />
          <img src={SD_gallery_03} alt="picture3" />
          <img src={SD_gallery_04} alt="picture4" />
        </div>
        <div>
          <img src={SD_gallery_05} alt="picture5" />
          <img src={SD_gallery_06} alt="picture6" />
          <img src={SD_gallery_07} alt="picture7" />
          <img src={SD_gallery_08} alt="picture8" />
        </div>
      </div>
      <button
        onClick={() => {
          history?.push(`/${selectedlanguage}/media-room/photo-gallery`);
        }}
      >
        View All <img src={SD_Right_Arrow_white} alt=">" />
      </button>
    </div>
  );
};
export default withRouter(Sd_gallery_semi_view);
