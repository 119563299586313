import React from "react";
import {withRouter} from "react-router";
import {
  SD_arrow_right_orange,
  SD_Main_offering,
  SD_pooja_logo,
  SD_Right_Arrow_Blue,
  SD_Right_Arrow_white,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-main-sup.scss";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const TemplateFour = ({history}) => {
  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");

  React.useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);
  return (
    <div className="sd-template-four">
      <div className="sd-t4-box-left">
        <div className="sd-t4-box-left-one">
          <h4>About</h4>
          <p>Srisailam</p>
        </div>
        <div className="sd-t4-box-left-two">
          <p>
            Srisaila Mahakshetram is considered as Kailasa on Earth. The history
            and tradition have it that the merits earned by visiting Srisailam
            are equal to worshipping all the divine powers the universe holds.
            <br />
            <br />
            <span style={{fontWeight: "400", fontSize: "16px"}}>
              Srisaila Mahakshetram is the living embodiment of ancient and
              spiritual-cultural traditions and values. Visited by all great
              saints and rulers namely, Sri Rama, Adi Shankaracharya and several
              other spiritual personalities; Srisailam is the supreme repository
              of spiritual truth.
            </span>
          </p>
        </div>

        <div className="sd-t4-box-left-four">
          <button
            onClick={() => {
              history?.push(
                `/${selectedlanguage}/about/the-temple-history/about-srisailam`
              );
            }}
          >
            Read More <img src={SD_Right_Arrow_white} alt="readmore" />
          </button>
        </div>
      </div>
      <div className="sd-t4-box-right">
        <div className="sd-t4-box-right-one">
          <img src={SD_pooja_logo} alt="pooja" />
          <h3>Pratyaksha Seva</h3>
          <button
            onClick={() => {
              history?.push(
                `/${selectedlanguage}/sevas-and-darshanam/pratyaksha-seva`
              );
            }}
          >
            View <img src={SD_arrow_right_orange} alt=">" />
          </button>
        </div>
        <div className="sd-t4-box-right-two"></div>
        <div className="sd-t4-box-right-three"></div>
        <div className="sd-t4-box-right-four">
          <img src={SD_Main_offering} alt="last" />
          <h3>Main Offerings</h3>
          <button
            onClick={() => {
              history?.push(`/${selectedlanguage}/donations`);
            }}
          >
            View <img src={SD_Right_Arrow_Blue} alt=">" />
          </button>
        </div>
      </div>
    </div>
  );
};
export default withRouter(TemplateFour);
