import React from "react";
import {sd_srisaila_logo} from "../../Assets/imageList";
import "../../Utils/sd-styles/sd-home.scss";
const Sd_error_page = () => {
  return (
    <div className="sd-inner-error">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          background: "white none repeat scroll 0% 0%",
          display: "block",
          shapeRendering: "auto",
        }}
        width="150px"
        height="150px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <image
          overflow="visible"
          width="85"
          xlinkHref={sd_srisaila_logo}
          transform="matrix(0.6242 0 0 0.6242 25 18.1847)"
        ></image>
        <path
          d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50"
          fill="#f58220"
          stroke="none"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            dur="1s"
            repeatCount="indefinite"
            keyTimes="0;1"
            values="0 50 51;360 50 51"
          ></animateTransform>
        </path>
      </svg>

      <br />
      <span>
        SORRY FOR THE DISRUPTION
        <br></br>
        <br />
        Your current screen resolution is not compatible with our website.
        <br />
        Please try with another device/browsers.
      </span>
    </div>
  );
};
export default Sd_error_page;
