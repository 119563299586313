import Axios from "../Utils/sd-axios/Axios";

const PratyakshaSevaService = {
  getAll: async () => {
    try {
      const resp = await Axios.get("/seva/all?sevaType=pratyakshaSeva");
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
};

export default PratyakshaSevaService;
